<template>
  <div
    class="motif-search-input"
  >
    <div
      class="motif-search-input__wrapper"
    >
      <SearchIcon
        class="motif-search-input__icon motif-search-input__search-icon"
      />
      <input
        ref="input"
        v-model="term"
        class="motif-search-input__input"
        type="text"
        name="query"
        :placeholder="placeholder"
        autocomplete="off"
        @focus="emitFocus"
      >
      <CancelIcon
        class="motif-search-input__icon motif-search-input__remove-icon"
        @click="clearTerm"
      />
    </div>
  </div>
</template>

<script>
import CancelIcon from '@/assets/images/cancel-icon.svg';
import SearchIcon from '@/assets/images/search-icon.svg';

export default {
  name: 'SearchInput',
  components: {
    CancelIcon,
    SearchIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Was suchen Sie?',
    },
    searchTerm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      term: this.searchTerm,
    };
  },
  watch: {
    term(newValue) {
      this.$emit('change', newValue);
    },
    searchTerm: {
      handler(term) {
        if (term === this.term) {
          return;
        }
        this.term = term;
      },
    },
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    emitFocus(e) {
      this.$emit('focus', e);
    },
    clearTerm() {
      this.term = '';
    },
  },
};

</script>

<style lang="scss">
@import "@/assets/styles/style";

.motif-search-input {

  &__wrapper {
    position: relative;
    display: flex;
    border: 2px solid #000000;
    border-radius: 6px;
  }

  &__input {
    display: block;
    width: 100%;
    height: 72px;
    padding: 16px 24px 17px 12px;
    outline: none;
    border: none;
    font-weight: 400;
    @include font-size(30px, 35px);
    color: #000;
    background: transparent;
  }

  &__icon {
    box-sizing: content-box;
    width: 35px;
    height: 35px;
    padding-top: 24px;
  }

  &__search-icon {
    left: 0;
    padding-left: 36px;
  }

  &__remove-icon {
    right: 0;
    padding-right: 14px;
  }
}
</style>
