<template>
  <div class="select-motif-type-page">
    <Typography
      variant="flow-text-title"
      class="select-motif-type-page__heading"
    >
      Sie haben die Wahl
    </Typography>

    <VideoElement
      v-if="isLoaded && videoSrc"
      :src="videoSrc"
      loop
      is-active
      class="select-motif-type-page__video"
    />

    <div class="select-motif-type-page__grid">
      <div
        v-if="hasPartialOrNoneCustomizableOffers"
        class="select-motif-type-page__column"
      >
        <Typography
          variant="title"
          class="select-motif-type-page__title"
        >
          Wählen Sie ganz bequem aus Tausenden Motiven Ihren Favoriten aus.
        </Typography>
        <router-link :to="motifSelectPage">
          <CommonButton>
            Zur Motivauswahl
          </CommonButton>
        </router-link>
      </div>

      <div
        v-if="transferPageUrl"
        class="select-motif-type-page__column"
      >
        <Typography
          variant="title"
          class="select-motif-type-page__title"
        >
          Gestalten Sie komplett frei mit persönlichen Fotos und Texten.
        </Typography>
        <router-link
          :to="transferPageUrl"
        >
          <CommonButton>
            Selbst gestalten
          </CommonButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DeinDesignOffers from '@/mixins/dein-design-offers';
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import Typography from '@/components/typography';
import CommonButton from '@/components/common-button';
import VideoElement from '@/components/video-element';
import fetchOffersForMotifType from '@/lib/goliath/dein-design-offers-for-motif-type';

const CUSTOMIZABLE_NONE = 'NONE';
const CUSTOMIZABLE_FULL = 'FULL';
const CUSTOMIZABLE_PARTIAL = 'PARTIAL';

export default {
  name: 'SelectMotifTypePage',
  components: {
    VideoElement,
    CommonButton,
    Typography,
  },
  mixins: [
    // origin async this.getOffers()
    DeinDesignOffers(fetchOffersForMotifType),
  ],
  data() {
    return {
      fullCustomizableArticleNumber: null,
      manufacturerProductCode: null,
      partialOrNoneCustomizableVariantsCount: 0,
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      getVideoByKey: 'preloadedVideos/getVideoByKey',
    }),
    hasPartialOrNoneCustomizableOffers() {
      return this.partialOrNoneCustomizableVariantsCount > 0;
    },
    motifSelectPage() {
      const queryParams = this.getQueryParams();
      return {
        name: 'dein-design-select-motif-recommendation-page',
        query: {
          ...queryParams,
        },
      };
    },
    transferPageUrl() {
      if (!this.fullCustomizableArticleNumber || !this.manufacturerProductCode) {
        return null;
      }
      return `/dein-design/transfer-page?article-number=${this.fullCustomizableArticleNumber}&manufacturer-product-code=${this.manufacturerProductCode}`;
    },
    videoSrc() {
      return this.getVideoByKey('deinDesignAuswahl')?.url;
    },
  },
  async beforeMount() {
    try {
      const fullCustomizableOffer = await this.getFullCustomizableOffers();
      await this.setFullCustomizableArticleNumber(fullCustomizableOffer);
      await this.setManufacturerProductCode(fullCustomizableOffer);
      const partialCustomizableOffers = await this.getPartialOrNoneCustomizableOffers();
      await this.setPartialOrNoneCustomizableVariantsCount(partialCustomizableOffers);
      if (!this.fullCustomizableArticleNumber) {
        this.$router.replace(this.motifSelectPage);
      }
      this.isLoaded = true;
    } catch (e) {
      console.error({ e });
    }
  },
  methods: {
    async getFullCustomizableOffers() {
      return this.getOffers({
        filter: {
          productCustomizabilities: [CUSTOMIZABLE_FULL],
        },
      });
    },
    async getPartialOrNoneCustomizableOffers() {
      return this.getOffers({
        filter: {
          productAvailable: true,
          productCustomizabilities: [CUSTOMIZABLE_PARTIAL, CUSTOMIZABLE_NONE],
        },
      });
    },
    setFullCustomizableArticleNumber(offers) {
      this.fullCustomizableArticleNumber = get(
        offers, 'entries[0].product.articleNumber', null,
      );
    },
    setManufacturerProductCode(offers) {
      this.manufacturerProductCode = get(
        offers, 'entries[0].product.manufacturerProductCode', null,
      );
    },
    setPartialOrNoneCustomizableVariantsCount(offers) {
      this.partialOrNoneCustomizableVariantsCount = get(
        offers, 'variantsCount', null,
      );
    },
  },
};
</script>

<style lang="scss">
.select-motif-type-page {
  &::before {
    content: '';
    height: 20px;
    display: block;
    background: linear-gradient(to bottom, #E6E6E6, rgba(255,255,255,0));
  }

  &__heading {
    white-space: nowrap;
    margin-right: 48px;
    font-size: 40px;
    line-height: 50px;
    padding: 48px 30px;
  }

  &__title {
    display: block;
    margin-top: 90px;
    margin-bottom: 75px;
  }

  &__grid {
    display: flex;
    justify-content: center;
    padding: 0 30px;
  }

  &__column {
    text-align: center;
    flex: 0 0 50%;
    padding: 0 7px;
  }

  &__video {
    video {
      height: 666px;
    }
  }
}
</style>
