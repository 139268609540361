<template>
  <div
    ref="letterNavigation"
    class="letter-navigation"
  >
    <div
      v-for="(letter, index) in letters"
      :key="`letterNavigationItem-${index}`"
      class="letter-navigation__item"
    >
      <Typography
        variant="title"
        class="letter-navigation__letter"
        :class="containerClass(letter)"
        @click.native="onClick(letter)"
      >
        {{ letter }}
      </Typography>
    </div>
  </div>
</template>

<script>
import Typography from '@/components/typography';

const NON_LETTER_CHARACTER = '#';

const ALPHABET_LETTERS = [
  NON_LETTER_CHARACTER,
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export default {
  name: 'LetterNavigation',
  components: {
    Typography,
  },
  props: {
    availableLetters: {
      type: Array,
      required: true,
    },
    selectedLetters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      letters: ALPHABET_LETTERS,
    };
  },
  computed: {
    hasNonLetterCharacter() {
      return this.availableLetters.some((letter) => !this.isLetter(letter));
    },
  },
  methods: {
    isLetter(c) {
      // https://stackoverflow.com/questions/9862761/how-to-check-if-character-is-a-letter-in-javascript
      // makes it easy to check for non latin characters (numbers and other special characters)
      // eslint-disable-next-line eqeqeq
      return c.toLowerCase() != c.toUpperCase();
    },
    isClickable(clickedLetter) {
      // for any non letter characters
      if (clickedLetter === NON_LETTER_CHARACTER) {
        return this.hasNonLetterCharacter;
      }
      return this.availableLetters.indexOf(clickedLetter) !== -1;
    },
    isSelected(letter) {
      // if brand with first letter Ä selected
      // -> if Ä is in selectedLetters
      // -> letter A is supposed to be shown as selected
      const selectedLettersWithoutAccents = this.selectedLetters.map((l) => {
        const letterWithoutAccent = l.replace(/Ä/g, 'A')
          .replace(/Ö/g, 'O')
          .replace(/Ü/g, 'U');
        return letterWithoutAccent;
      });
      // for any non letter characters
      if (letter === NON_LETTER_CHARACTER) {
        return this.selectedLetters.some((l) => !this.isLetter(l));
      }

      return selectedLettersWithoutAccents.includes(letter);
    },
    containerClass(letter) {
      return {
        'letter-navigation__letter--available': this.isClickable(letter),
        'letter-navigation__letter--selected': this.isSelected(letter),
      };
    },
    onClick(letter) {
      if (!this.isClickable(letter)) return;
      this.$emit('click', letter);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.letter-navigation {
  display: flex;
  margin-bottom: 36px;
  padding: 0 30px;
  &__letter {
    font-size: 30px;
    letter-spacing: 0.52px;
    line-height: 35px;
    margin-right: 12px;
    color: #BBBBBB;
    &--available {
      color: #000000;
      font-weight: bold;
      cursor: pointer;
    }
    &--selected {
      color: color(magenta, 1000);
    }
  }
}
 </style>
