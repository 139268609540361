<template>
  <StatusToolbar
    show-toolbar
    json-url="/appmonitoring/status.json"
    :node-env="nodeEnv"
    :features="features"
  />
</template>

<script>
import features from '@/lib/features';
import StatusToolbar from '@i22/status-toolbar';
import '@i22/status-toolbar/dist/style.css';

export default {
  name: 'StatusToolbarWrapper',
  components: { StatusToolbar },
  data() {
    return {
      features,
    };
  },
  computed: {
    nodeEnv() {
      return process.env.NODE_ENV;
    },
  },
};
</script>

<style lang="scss">
html, body {
  padding-bottom: 38px;
}
</style>
