<template>
  <div class="heatable-jacket-page">
    <Header />
    <div
      ref="container"
      class="heatable-jacket-page__overflow"
    >
      <div class="heatable-jacket-page__container">
        <div class="heatable-jacket-page__container-background" />
        <div
          class="heatable-jacket-page__text-background-future"
          data-rellax-speed="4"
        >
          FUTURE
        </div>
        <div
          class="heatable-jacket-page__text-background-smart"
          data-rellax-speed="4"
        >
          SMART
        </div>
        <Typography
          variant="heading-large"
          class="heatable-jacket-page__heading"
        >
          Heatable Capsule Collection
        </Typography>

        <div
          class="heatable-jacket-page__instruction"
          :class="instructionClass"
        >
          <span class="heatable-jacket-page__instruction-text heatable-jacket-page__instruction-text--black">
            Wische um mehr zu erfahren
          </span>
          <span class="heatable-jacket-page__instruction-text heatable-jacket-page__instruction-text--white">
            Wische um mehr zu erfahren
          </span>
        </div>

        <div class="heatable-jacket-page__start-buttons">
          <RouterLink
            to="/heatable-jacket/collection-men"
            class="heatable-jacket-page__button"
          >
            Herren Kollektion
          </RouterLink>
          <RouterLink
            to="/heatable-jacket/collection-women"
            class="heatable-jacket-page__button"
          >
            Damen Kollektion
          </RouterLink>
        </div>

        <div class="heatable-jacket-page__image-welcome heatable-jacket-page__parallax-image">
          <img
            src="/api/content-images/virtual-shelf/heatable-jacket/2a956b498adbfc22bbe6017e7ae22b5b/heatable_capsule_collection_willkommen.jpg"
            alt=""
            data-rellax-speed="-2"
          >
        </div>
        <div class="heatable-jacket-page__text-welcome">
          <Typography
            variant="heading-large"
          >
            Willkommen in der Zukunft
          </Typography>
          <br>
          <Typography
            variant="title"
          >
            Was wäre, wenn Kleidung mehr wäre, als eine textile Schicht auf unserem Körper? Was wäre, wenn sie mitdenken und uns unterstützen könnte? Was wäre, wenn wir dir sagen, dass wir das möglich gemacht haben?
          </Typography>
        </div>

        <div class="heatable-jacket-page__image-cooperation heatable-jacket-page__parallax-image">
          <img
            src="/api/content-images/virtual-shelf/heatable-jacket/2dac2c66f2e0fce503d2fe0d3f94a7ae/heatable_capsule_collection_kooperation.jpg"
            alt=""
            data-rellax-speed="-2"
          >
        </div>
        <div class="heatable-jacket-page__text-cooperation">
          <Typography
            variant="title"
          >
            Eine Kooperation von:
            <br>
            <div class="heatable-jacket-page__logos">
              <img src="@/assets/images/heatable-jacket/telekom-logo.png">
              <img src="@/assets/images/heatable-jacket/alphatauri-logo.png">
              <img src="@/assets/images/heatable-jacket/schoeller-logo.png">
            </div>
          </Typography>
        </div>

        <div class="heatable-jacket-page__image-vest heatable-jacket-page__parallax-image">
          <img
            src="/api/content-images/virtual-shelf/heatable-jacket/8c84adf430a425ce19a208bfbb4fa7d0/heatable_capsule_collection_weste.jpg"
            alt=""
            data-rellax-speed="-2"
          >
        </div>

        <Typography
          class="heatable-jacket-page__text-new-ara"
          variant="title"
        >
          Wir haben eine Jacke erschaffen, um eine neue Ära einzuläuten. Eine Ära, in der sich Style nicht mehr dem Wetter anpassen muss. In der du bestimmst, was deine Wohlfühltemperatur ist. Keine Gänsehaut, kein Lagenlook. Nur du und deine Heatable Jacket.
        </Typography>

        <div class="heatable-jacket-page__image-new-ara heatable-jacket-page__parallax-image">
          <img
            src="/api/content-images/virtual-shelf/heatable-jacket/df095f69abe977acd93482dbef4d511f/heatable_capsule_collection_neue_aera.jpg"
            alt=""
            data-rellax-speed="-2"
          >
        </div>

        <div class="heatable-jacket-page__image-intelligent">
          <JacketAnimation
            scroll-container=".heatable-jacket-page__overflow"
          />
        </div>
        <Typography
          class="heatable-jacket-page__text-intelligent"
          variant="title"
        >
          Durch die smarte Kombination aus tragbarer Technik und innovativen Textilien kannst du im Alltag wie in der Freizeit dein individuelles Komfortklima einstellen.
        </Typography>

        <div class="heatable-jacket-page__text-app">
          <Typography
            variant="heading-large"
          >
            Intuitiv steuerbar in einer App
          </Typography>
          <br>
          <Typography
            variant="title"
          >
            Dank dem weltweit ersten Stoff mit einem<br>leitfähigen Netzwerk.
          </Typography>
        </div>

        <div class="heatable-jacket-page__image-control heatable-jacket-page__parallax-image">
          <img
            src="/api/content-images/virtual-shelf/heatable-jacket/addd5ca3446bfa1c4d74d1867cfeafa4/heatable_capsule_collection_steuerung.jpg"
            alt=""
            data-rellax-speed="-2"
          >
        </div>

        <div class="heatable-jacket-page__image-app heatable-jacket-page__parallax-image">
          <img
            src="/api/content-images/virtual-shelf/heatable-jacket/ab7d51d8318f3fd44c61b198e7128039/heatable_capsule_collection_app.jpg"
            alt=""
            data-rellax-speed="-2"
          >
        </div>

        <div class="heatable-jacket-page__text-qr-code">
          <Typography
            variant="heading"
          >
            Starte mit uns in die Zukunft und hole dir die App:
          </Typography>
        </div>

        <img
          class="heatable-jacket-page__qr-code-play"
          src="@/assets/images/heatable-jacket/hcc-play-store.png"
          alt="PlayStore"
        >
        <img
          class="heatable-jacket-page__qr-code-app"
          src="@/assets/images/heatable-jacket/hcc-app-store.png"
          alt="AppStore"
        >
      </div>
      <div class="heatable-jacket-page__shelf-container">
        <div
          class="heatable-jacket-page__collection-heading"
        >
          <Typography
            variant="heading"
            class="heatable-jacket-page__collection-heading--men"
          >
            Herren Kollektion
          </Typography>
          <Typography
            variant="heading"
            class="heatable-jacket-page__collection-heading--women"
          >
            Damen Kollektion
          </Typography>
        </div>
        <HeatableJacketOffers
          disable-draggable
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Header from '@/components/header';
import Footer from '@/components/footer';
import Typography from '@/components/typography';
import JacketAnimation from '@/components/heatable-jacket/jacket-animation';
import Rellax from 'rellax';
import HeatableJacketOffers from '@/components/heatable-jacket/heatable-jacket-offers';

export default {
  name: 'HeatableJacketPage',
  components: {
    HeatableJacketOffers,
    Header,
    Footer,
    Typography,
    JacketAnimation,
  },
  data() {
    return {
      qrOptions: { width: 256, margin: 0, errorCorrectionLevel: 'H' },
      rellax: null,
      isScrolled: false,
    };
  },
  computed: {
    scrollContainer() {
      return this.$refs.container;
    },
    instructionClass() {
      return this.isScrolled ? 'heatable-jacket-page__instruction--scrolled' : '';
    },
  },
  mounted() {
    this.rellax = new Rellax('[data-rellax-speed]', {
      center: true,
      wrapper: this.$refs.container,
      vertical: false,
      horizontal: true,
    });

    this.updateBreadcrumb([
      { path: '/start', label: 'Home' },
      { path: '/heatable-jacket', label: 'Heatable Capsule' },
    ]);
    this.scrollContainer.addEventListener('scroll', this.scrollEvent);
  },
  beforeDestroy() {
    this.rellax.destroy();
    this.scrollContainer.removeEventListener('scroll', this.scrollEvent);
  },
  methods: {
    ...mapActions({
      updateBreadcrumb: 'breadcrumb/updateEntries',
    }),
    scrollEvent() {
      this.isScrolled = this.scrollContainer.scrollLeft > 0;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.heatable-jacket-page {

  &__overflow {
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    display: flex;
  }

  &__container {
    position: relative;
    display: inline-grid;
    grid-template-columns: repeat(30, (1080px - 40px) * 0.25);
    grid-template-rows: 1fr 1fr 1fr 1fr;
    height: 1920px - 120px - 220px - 80px; // header + footer + margin
    margin: 80px 20px 0 20px;
  }

  &__container-background {
    background-color: #F7F7F7;
    position: absolute;
    left: 695px;
    top: 55px;
    right: 0;
    bottom: 320px;
    z-index: -1;
  }

  &__parallax-image {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: none;
      flex: 0 0 auto;
    }
  }

  &__text-background-future,
  &__text-background-smart {
    @include font-size(370px, 370px);
    font-weight: 400;
    color: #F3F3F3;
    white-space: nowrap;
  }

  &__text-background-future {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 13;
    margin-top: -160px;
  }

  &__text-background-smart {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 19;
    margin-top: -160px;
  }

  &__heading {
    grid-row-start: 1;
    grid-column-start: 1;
    margin-left: 55px;
  }

  &__instruction {
    display: flex;
    justify-content: center;
    grid-row-start: 4;
    grid-column-start: 1;
    grid-column-end: 5;
    text-align: center;
    margin-top: 50px;
    white-space: nowrap;
    position: relative;
    pointer-events: none;
    transition: opacity .5s;

    &-text {
      @include font-size(46px, 46px);
      font-weight: 400;
      position: absolute;
      margin: 0 auto;
      height: 112px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 56px;

      &--white {
        color: #FFF;
        background-color: #e20074;
        animation: circle 4s infinite;
      }
    }

    &--scrolled {
      opacity: 0;
    }
  }
  @keyframes circle {
    0% { clip-path: circle(56px at 200px); }
    25% { clip-path: circle(56px at 200px); }
    66% { clip-path: circle(56px at 56px); }
    100% { clip-path: circle(56px at 200px); }
  }

  &__start-buttons {
    position: sticky;
    left: 20px;
    grid-row-start: 4;
    grid-column-start: 1;
    grid-column-end: 5;
    margin-top: 220px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__image-welcome {
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 2;
    width: 1047px;
    height: 697px;
  }

  &__text-welcome {
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 6;
    margin-left: 55px;
    width: 585px;

    .typography--heading-large {
      text-transform: none;
    }
  }

  &__image-cooperation,
  &__text-cooperation {
    grid-column-start: 9;
  }

  &__image-cooperation {
    grid-row-start: 1;
    grid-row-end: 3;
    width: 622px;
    height: 766px;
    margin-top: 95px;
    align-items: flex-start;
  }

  &__text-cooperation {
    grid-row-start: 3;
    grid-row-end: 5;
    margin-top: 140px;
  }

  &__logos {
    display: flex;
    margin: 28px -9px;
    > img {
      margin: 0 9px;
      height: 74px;
    }
  }

  &__image-vest {
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 12;
    width: 573px;
    height: 765px;
    margin-top: -15px;
    margin-left: -50px;
    align-items: flex-start;
  }

  &__text-new-ara,
  &__image-new-ara {
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 14;
    margin-left: 112px;
  }

  &__text-new-ara {
    width: 584px;
  }

  &__image-new-ara {
    width: 856px;
    height: 641px;
    margin-top: 250px;
    align-items: flex-start;
  }

  &__image-intelligent,
  &__text-intelligent {
    grid-column-start: 18;
  }
  &__image-intelligent {
    grid-row-start: 1;
    grid-row-end: 3;
    width: 980px;
    height: 740px;
    margin-top: 140px;
    background-color: #143141;
    position: relative;
  }

  &__text-intelligent {
    grid-row-start: 3;
    grid-row-end: 4;
    width: 687px;
    margin-top: 160px;
 }

  &__text-app {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 22;
    width: 800px;
    margin-top: -110px;
    margin-left: 40px;
    .typography--heading-large {
      text-transform: none;
    }
  }

  &__image-control {
    grid-row-start: 1;
    grid-row-end: 5;
    grid-column-start: 23;
    width: 848px;
    height: 488px;
    margin-top: 95px;
  }

  &__image-app {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 25;
    width: 848px;
    height: 540px;
  }

  &__text-qr-code {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 27;
    width: 440px;
    padding: 0 24px;
    .typography--heading {
      text-transform: none;
      @include font-size(52px, 70px);
    }
  }

  &__qr-code-play,
  &__qr-code-app {
    margin-top: 10px;
    height: 210px;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  &__qr-code-play {
    grid-column-start: 28;
    grid-column-end: 29;
    margin-left: 170px;
  }
  &__qr-code-app {
    grid-column-start: 29;
    grid-column-end: 31;
    margin-left: 150px;
  }

  &__collection-heading {
    display: flex;
    margin-bottom: 20px;

    &--men,
    &--women {
      flex: 0 0 50%;
      padding: 0 50px;
    }
  }

  &__shelf-container {
    flex: 0 0 80vw;
    margin: 50px 80px;
  }

  &__button {
    color: color(white);
    text-decoration: none;
    // undo button styles
    cursor: pointer;
    text-transform: uppercase;
    display: inline-block;
    padding: 18px 48px;
    font-size: 30px;
    line-height: 35px;
    text-align: justify;
    border-radius: 6px;
    background-color: #e20074;
    margin: 0 20px;

    &:visited {
      color: color(white);
    }

    &:hover,
    &:active,
    &:focus {
      color: color(white);
    }
  }
}
</style>
