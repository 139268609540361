import { isArray, mergeWith } from 'lodash';

export default function mergeVariables(variables, newVariables) {
  return mergeWith(
    {},
    variables,
    newVariables || {},
    (objValue, srcValue) => {
      if (isArray(objValue)) {
        // This is the main difference to the default implementation of merge.
        // Do not merge arrays!
        return srcValue;
      }
      return undefined;
    },
  );
}
