<template>
  <div
    ref="productTagSelector"
    class="product-tag-selector"
  >
    <SelectableItemGroup
      class="compatible-brand-selector__item-group"
      :list="selectableTagsFiltered"
      :active="isVisible"
      :value="selectedTags"
      @input="selectTag"
    />
  </div>
</template>

<script>
import SelectableItemGroup from '@/components/selectable-item-group';

export default {
  name: 'ProductTagSelector',
  components: {
    SelectableItemGroup,
  },
  props: {
    selectableProductTags: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedProductTagSlug: this.value,
    };
  },
  computed: {
    selectableTagsFiltered() {
      return this.selectableProductTags.map((entry) => ({
        label: entry.tag.name,
        value: entry.tag.slug,
      }));
    },
    selectedTags() {
      return [this.value];
    },
    isVisible() {
      return this.selectableTagsFiltered.length > 0;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedProductTagSlug = value;
      },
    },
  },
  methods: {
    selectTag(slugs) {
      this.$emit('input', slugs[0]);
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss">
.product-tag-selector {
  &__item-group {
    display: flex;
    padding: 0 30px;
  }
}
</style>
