export default {
  namespaced: true,
  state() {
    return {
      slugs: [],
    };
  },
  mutations: {
    ADD_SLUG(state, payload) {
      if (!state.slugs.find((s) => s.slug === payload.slug)) {
        state.slugs.push(payload);
      }
    },
  },
};
