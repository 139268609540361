<template>
  <progress
    :value="currentProgress"
    :max="MAX_PROGRESS"
    class="timer-bar"
  />
</template>

<script>
const REFRESH_RATE = 60;
const MAX_PROGRESS = 100;

export default {
  name: 'TimerBar',
  props: {
    duration: {
      type: Number,
      required: true,
    },
    cooldownDuration: {
      type: Number,
      default: 250,
    },
  },
  data() {
    return {
      MAX_PROGRESS,
      currentProgress: 0,
      endTimestamp: null,
      cooldownTimestamp: null,
      cooldownStarted: false,
    };
  },
  watch: {
    duration() {
      this.updateEndTimestamps();
    },
    cooldownDuration() {
      this.updateEndTimestamps();
    },
  },
  methods: {
    start() {
      this.updateProgress(0);
      this.updateEndTimestamps();
      this.animate();
    },
    async stop() {
      this.updateProgress(MAX_PROGRESS);
      await this.$nextTick();
      this.onEnd();
    },
    updateEndTimestamps() {
      this.endTimestamp = this.getEndTimestamp();
      this.cooldownTimestamp = this.getCooldownTimestamp();
      this.cooldownStarted = false;
    },
    getEndTimestamp() {
      return this.getCurrentTimestamp() + this.duration;
    },
    getCooldownTimestamp() {
      return this.getEndTimestamp() - this.cooldownDuration;
    },
    getCurrentTimestamp() {
      return Date.now();
    },
    getRemainingTime() {
      return this.endTimestamp - this.getCurrentTimestamp();
    },
    getRemainingTimeToCooldown() {
      return this.cooldownTimestamp - this.getCurrentTimestamp();
    },
    calcProgressFromRemainingTime(remainingTime) {
      return MAX_PROGRESS - (remainingTime / this.duration) * MAX_PROGRESS;
    },
    async animate() {
      const remainingTime = this.getRemainingTime();
      const remainingTimeToCooldown = this.getRemainingTimeToCooldown();

      if (!this.cooldownStarted && remainingTimeToCooldown < REFRESH_RATE) {
        this.cooldownStarted = true;
        this.onCooldown();
      }

      if (remainingTime < REFRESH_RATE) {
        await this.stop();
      } else {
        const progress = this.calcProgressFromRemainingTime(remainingTimeToCooldown);
        this.updateProgress(progress);
        window.requestAnimationFrame(this.animate);
      }
    },
    updateProgress(newValue) {
      this.currentProgress = newValue;
    },
    onCooldown() {
      this.$emit('cooldown-started');
    },
    onEnd() {
      this.$emit('time-up');
    },
  },
};
</script>

<style lang="scss">
.timer-bar {
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  display: block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    height: 12px;
  }

  &::-webkit-progress-value {
    background-color: #e20074;
  }

  &::-moz-progress-bar {
    background-color: #e20074;
  }
}
</style>
