export function recordHasUsps(obj) {
  return !!obj.usps && obj.usps.length > 0;
}

export function offerHasOwnUsps(offer) {
  return recordHasUsps(offer);
}

export function offerHasProductUsps(offer) {
  const { product } = offer;
  if (!product) return false;

  if (product.bundledProducts) {
    return product.bundledProducts.some((p) => recordHasUsps(p));
  }

  return recordHasUsps(product);
}

export function offerHasContractUsps(offer) {
  if (!offer.contract) return false;
  return recordHasUsps(offer.contract);
}

export function offerHasUsps(offer) {
  return offerHasOwnUsps(offer)
    || offerHasProductUsps(offer)
    || offerHasContractUsps(offer);
}
