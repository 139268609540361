export default {
  namespaced: true,
  state() {
    return {
      entries: [{
        label: 'Home',
        path: '/start',
      }],
    };
  },
  actions: {
    updateEntries(context, payload) {
      context.commit('UPDATE_ENTRIES', payload);
    },
  },
  mutations: {
    UPDATE_ENTRIES(state, payload) {
      state.entries = payload;
    },
  },
};
