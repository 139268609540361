<template>
  <div
    v-if="isOpened"
    class="modal"
  >
    <div
      v-on-clickaway="onClickaway"
      class="modal__inner"
    >
      <button
        type="button"
        class="modal__button"
        @click="close"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import Dismissable from '@/mixins/dismissable';

export default {
  name: 'Modal',
  mixins: [Dismissable],
};
</script>

<style lang="scss">
  @keyframes modal-open {
    from {
      opacity: 0;
      transform: translateY(-5%) scale(0.75);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

.modal {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(242, 242, 242, 0.7);
  padding: 35px 24px 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__button {
    position: absolute;
    padding-bottom: 40px;
    right: 40px;
    top: 40px;
    width: 40px;
    height: 40px;
    opacity: 1;
    border: none;
    background: transparent;

    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 40px;
      height: 2px;
      background-color: #333;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:focus {
      outline: 0;
    }
  }

  &__inner {
    font-weight: 400;
    font-size: 24px;
    position: relative;
    z-index: 999;
    background: #ffffff;
    box-shadow: 1px 11px 25px 1px rgba(0, 0, 0, 0.31);
    min-width: 300px;
    text-align: left;
    max-width: 750px;
    width: 100%;
    margin: auto;
    padding: 80px 35px 60px;
    animation: {
      name: modal-open;
      duration: 200ms;
      timing-function: ease-out;
      iteration-count: 1;
    }
    transform-origin: top center;
    max-height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
