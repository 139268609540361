<template>
  <router-link
    :to="link"
    class="offer-on-shelf"
  >
    <figure
      v-if="imageSrc"
      ref="offerOnShelfFigure"
      :class="figureClass"
      class="offer-on-shelf__figure"
    >
      <Badge
        v-if="rebateDescription"
        class="offer-on-shelf__badge offer-on-shelf__badge--rebate"
        font-weight="ultra"
      >
        <span v-html="rebateDescription" />
        <button
          v-if="footnoteText"
          ref="footnoteButton"
          class="btn badge__footnote offer-on-shelf__footnote-button"
          @click.prevent="$refs.modal.open"
        >
          *
        </button>
        <Modal
          ref="modal"
        >
          <div v-html="footnoteText" />
        </Modal>
      </Badge>

      <Badge
        v-else-if="prices.was"
        bold
        class="offer-on-shelf__badge"
      >
        %
      </Badge>
      <Badge
        v-else-if="showCustomizableBadge"
        :default-font-size="isBig ? 20 : 14"
        bold
        class="offer-on-shelf__badge offer-on-shelf__badge--customizable"
      >
        Selbst<br>gestalten!
      </Badge>
      <GoliathImage
        :alt="label"
        :path="imageSrc"
        class="offer-on-shelf__image"
        onerror="this.src='/api/content-images/virtual-shelf/ee06ffe64dfe9ca4d20ee50e1e4b241a/missing-image.png'"
      />

      <CounterBadge
        v-if="cartItem"
        :count="cartItem.quantity"
        class="offer-on-shelf__counter-badge"
      />
    </figure>

    <div class="offer-on-shelf__content">
      <Typography
        class="offer-on-shelf__heading"
        variant="title"
        v-html="label"
      />
      <Prices
        :prices-data="prices"
        class="offer-on-shelf__prices"
        variant="small"
      />
      <EnergyLabelContainer
        :product="energyEfficiencyData"
        class="offer-on-shelf__energy"
      />
      <div
        v-if="bundle"
        class="offer-tile__variations"
      >
        <AlternativeOffersCount
          :product-alternatives="productAlternatives"
        />
      </div>
      <div
        v-if="!bundle && colorAlternatives"
        ref="offerOnShelfVariants"
        class="offer-on-shelf__color-variants"
      >
        <VariantColorInfo
          v-for="(colorAlternative, index) in colorAlternatives"
          :key="`color-info-${index}`"
          :class="isWhite(colorAlternative)"
          :color-code="colorAlternative.colorCode"
          :is-colorful="colorAlternative.isColorful"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapState } from 'vuex';
import { get } from 'lodash';
import CounterBadge from '@/components/counter-badge';
import Prices from '@/components/prices';
import VariantColorInfo from '@/components/variant-color-info';
import Typography from '@/components/typography';
import Modal from '@/components/modal';
import AlternativeOffersCount from '@/components/alternative-offers-count';
import GoliathImage from '@/components/goliath-image';

import { Badge } from '@i22-td-smarthome/component-library';
import EnergyLabelContainer from '@/components/energy-label-container';

const CUSTOMIZABLE_PARTIAL = 'PARTIAL';

export default {
  name: 'OfferOnShelf',
  components: {
    EnergyLabelContainer,
    CounterBadge,
    AlternativeOffersCount,
    Typography,
    VariantColorInfo,
    Prices,
    Modal,
    Badge,
    GoliathImage,
  },
  props: {
    articleNumber: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      required: true,
    },
    colorAlternatives: {
      type: Array,
      default: null,
    },
    link: {
      type: String,
      required: true,
    },
    prices: {
      type: Object,
      required: true,
    },
    timesInCart: {
      type: Number,
      default: 0,
    },
    rebate: {
      type: Object,
      default: null,
    },
    productAlternatives: {
      type: Array,
      default: null,
    },
    bundle: {
      type: Boolean,
      default: false,
    },
    customizability: {
      type: String,
      default: 'NONE',
    },
    isBig: {
      type: Boolean,
      default: false,
    },
    isExtraBig: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapState({ cartItems: (state) => state.cart.cart.items }),
    cartItem() {
      return this.cartItems.find(
        (cartItem) => cartItem.articleNumber === this.articleNumber,
      );
    },
    rebateDescription() {
      if (!this.rebate) return null;
      const { description } = this.rebate;
      if (description === 'no description') return null;
      return description;
    },
    footnoteText() {
      const footnote = get(this.rebate, 'footnote');
      if (!footnote) return null;
      return footnote;
    },
    showCustomizableBadge() {
      return this.customizability === CUSTOMIZABLE_PARTIAL;
    },
    figureClass() {
      return {
        'offer-on-shelf__figure--big': this.isBig,
        'offer-on-shelf__figure--extra-big': this.isExtraBig,
      };
    },
    energyEfficiencyData() {
      if (this.product) {
        return this.product;
      }
      return undefined;
    },
  },
  methods: {
    isWhite(colorAlternative) {
      return colorAlternative.colorName === 'Weiß'
      || colorAlternative.colorCode === '#FFFFFF'
        ? 'offer-on-shelf__color-variants--white' : null;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.offer-on-shelf {
  align-items: center;
  display: flex;
  height: 350px;
  margin-right: 60px;

  &:last-of-type {
    margin-right: 0;
  }

  &__figure {
    flex: 0 0 208px;
    height: 220px;
    position: relative;
    width: 208px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      border-radius: 50%;
      bottom: 0;
      box-shadow: 0 35px 13px 1px rgba(0, 0, 0, 0.14);
      content: " ";
      height: 9px;
      left: 0;
      position: absolute;
      right: 0;
      transform: translateX(20%);
      width: 70%;
      z-index: 1;
    }

    &--big {
      height: 390px;
      flex: 0 0 390px;
      width: 390px;
    }

    &--extra-big {
      height: 482px;
      flex: 0 0 482px;
      width: 482px;
    }
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
  }

  &__heading {
    margin-bottom: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    max-height: 208px;
    width: 300px;
    justify-content: center;
  }

  &__heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    margin-bottom: 10px;
  }

  &__energy,
  &__prices {
    font-size: 30px;
    line-height: 25px;
  }

  &__prices {
    color: #E20074;
    height: 25px;
    margin-bottom: 10px;
  }

  &__color-variants {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;

    &--white {
      border: 1px solid #C9C9C9;
    }
  }

  &__badge {
    top: 48px;
    left: -6px;
    transform: translate(-50%, -50%);
    @include font-size(24px, 30px);

    &--rebate {
      @include font-size(22px, 25px);

      strong,
      b {
        font-weight: 400;
      }
    }

    &--customizable {
      text-transform: uppercase;
    }
  }

  &__counter-badge {
    position: absolute;
    left: -43px;
    bottom: 40px;
  }
}
</style>
