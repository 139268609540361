<template>
  <div class="shelf-board" />
</template>

<script>
export default {
  name: 'ShelfBoard',
};
</script>

<style lang="scss">
.shelf-board {
  background-clip: padding-box, content-box;
  background-color: transparent;
  background-image: url('../assets/images/shelf-beginning.png'),
                    url('../assets/images/shelf-main.png');
  background-position: left top, left top;
  background-repeat: no-repeat, repeat-x;
  background-size: 106px 100%, auto 100%;
  height: 180px;
  padding: 0 106px;
  position: relative;
  top: -48px;
  z-index: -1;

  &::after {
    background-color: transparent;
    background-image: url('../assets/images/shelf-beginning.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: " ";
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(-1);
    width: 106px;
  }
}
</style>
