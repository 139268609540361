<template>
  <div
    ref="colorSelector"
    class="color-selector"
  >
    <SelectableItem
      v-if="selectableColors"
      ref="selectableItemAll"
      class="color-selector__selectable-item"
      :value="allSelected"
      @input="onInputAllSelector"
    >
      <div
        class="color-selector__color color-selector__color--all"
      />
      <span class="color-selector__color-name">
        Alle
      </span>
    </SelectableItem>
    <SelectableItem
      v-for="(color, index) in selectableColors"
      ref="selectableItem"
      :key="`selectable-item-color-${index}`"
      :value="isSelected(color)"
      class="color-selector__selectable-item"
      :class="getColorModifierClass(color.slug)"
      @input="onInput($event, color)"
    >
      <div
        class="color-selector__color"
        :style="{ backgroundColor: color.code }"
      />
      <span class="color-selector__color-name">
        {{ color.name }}
      </span>
    </SelectableItem>
  </div>
</template>

<script>
import { pull } from 'lodash';
import SelectableItem from '@/components/selectable-item';
import DraggableMixin from '@/mixins/draggable-mixin';

export default {
  name: 'ColorSelector',
  components: {
    SelectableItem,
  },
  mixins: [
    DraggableMixin('colorSelector'),
  ],
  props: {
    aggregations: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedColorSlugs: [...this.value],
    };
  },
  computed: {
    selectableColors() {
      return this.aggregations.map((item) => item.color);
    },
    allSelected() {
      return !this.selectedColorSlugs.length;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedColorSlugs = [...value];
      },
    },
    active: {
      handler(becomesActive) {
        if (!becomesActive) return;
        if (this.initialAnimationDone) return;
        this.initIntroSlide();
        this.initialAnimationDone = true;
      },
    },
  },
  methods: {
    isSelected(color) {
      return this.selectedColorSlugs.includes(color.slug);
    },
    emitInput(selectedColorSlugs) {
      this.$emit('input', selectedColorSlugs);
      this.$emit('change');
    },
    onInputAllSelector() {
      // empty selectedColorSlugs
      this.emitInput([]);
    },
    onInput(val, color) {
      const selectedColorSlugs = [...this.selectedColorSlugs];
      if (this.isSelected(color)) {
        pull(selectedColorSlugs, color.slug);
      } else {
        selectedColorSlugs.push(color.slug);
      }
      this.emitInput(selectedColorSlugs);
    },
    getColorModifierClass(colorSlug) {
      return `color-selector__selectable-item--${colorSlug}`;
    },
  },
};
</script>

<style lang="scss">
.color-selector {
  display: flex;
  flex-direction: row;
  padding: 0px 30px;

  &__selectable-item {
    height: 165px;
    width: 190px;
    &--weiss {
      .color-selector__color {
        border: 1px solid #D7D7D7;
      }
    }
  }

  &__color {
    height: 60px;
    display: flex;
    margin-bottom: 24px;

    &--all {
      &::before {
        display: block;
        content: '';
        background-image: linear-gradient(to bottom, #F3F3F3 50%, #F8E71C 50%);
        width: 50%;
        height: 100%;
      }
      &::after {
        display: block;
        content: '';
        background-image: linear-gradient(to bottom, #50A1FF 50%, #E20074 50%);
        width: 50%;
        height: 100%;
      }
    }
  }

  &__color-name {
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    display: block;
    margin-top: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
 </style>
