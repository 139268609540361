<template>
  <CommonButton
    class="add-to-cart-button"
    :disabled="disabled"
    @click="addItemToCart"
  >
    <div :class="containerClass">
      <CheckmarkIcon
        v-if="isInStateAdded"
        class="add-to-cart-button__icon"
      />
      <CartIcon
        v-if="isInStateToCart"
        class="add-to-cart-button__icon"
      />
      {{ buttonLabel }}
    </div>
  </CommonButton>
</template>

<script>
import actions from '@/store/actions';
import CartIcon from '@/assets/images/cart-icon.svg';
import CheckmarkIcon from '@/assets/images/checkmark.svg';
import CommonButton from '@/components/common-button';

export default {
  name: 'AddToCartButton',
  components: {
    CommonButton,
    CartIcon,
    CheckmarkIcon,
  },
  props: {
    articleNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      state: 'initial',
      disabled: false,
      labels: {
        initial: 'In den Warenkorb',
        item_added: 'Hinzugefügt!',
        to_cart: 'Zum Warenkorb',
      },
    };
  },
  computed: {
    containerClass() {
      const classes = ['add-to-cart-button__wrapper'];
      if (this.isInStateAdded) classes.push('add-to-cart-button__wrapper--success');
      return classes;
    },
    isInStateAdded() {
      return this.state === 'item_added';
    },
    isInStateToCart() {
      return this.state === 'to_cart';
    },
    buttonLabel() {
      return this.labels[this.state];
    },
  },
  methods: {
    async addItemToCart() {
      if (this.isInStateToCart) {
        this.$router.push({ name: 'cart' });
        return;
      }
      const response = await this.$store.dispatch(actions.ADD_TO_CART, {
        articleNumber: this.articleNumber,
      });
      if (response.type === 'warning' || response.type === 'error') return;
      this.setButtonClicked();
    },
    setButtonClicked() {
      this.disabled = true;
      this.state = 'item_added';
      // make the button clickable after a delay of 1500 ms
      setTimeout(() => {
        this.$emit('item-added', this.articleNumber);
        this.state = 'to_cart';
        this.disabled = false;
      }, 1500);
    },
    resetButton() {
      this.state = 'initial';
    },
  },
};
</script>

<style lang="scss">
.add-to-cart-button {
  width: 325px;
  height: 72px;
  padding: 0px;
  display: flex;
  justify-content: center;
  background-color: #e20074;
  border-radius: 6px;
  color: #fff;
  font-size: 30px;
  text-align: center;
  border: none;

  &:focus {
    outline: 0;
  }

  &__wrapper {
    height: 72px;
    line-height: 72px;

    &--success {
      animation-name: cart-button-content;
      animation-duration: 1.5s;
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
    }
  }

  &__icon {
    margin-bottom: -2px;
    display: inline-block;
    fill: white;
  }

  @keyframes cart-button-content {
    0% {transform: scale(0.5); opacity: 0.5}
    20%, 90% {transform: scale(1); opacity: 1}
    100% {transform: scale(0.5); opacity: 0.5}
  }

  @keyframes cart-button-error {
    0% {transform: scale(0.5); opacity: 0.5}
    20% {transform: scale(1); opacity: 1}
    100% {transform: scale(1); opacity: 1}
  }
}
</style>
