<template>
  <div class="static-shelf">
    <div
      v-if="showIntro"
      class="static-shelf__intro"
    >
      <Typography
        v-if="title"
        :variant="typographyVariant"
        class="static-shelf__headline"
      >
        {{ title }}
      </Typography>
      <Typography
        v-if="copy"
        variant="title"
      >
        {{ copy }}
      </Typography>
    </div>
    <Shelf
      v-if="items"
      :items="items"
      direction="inRows"
      :animate="true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Typography from '@/components/typography';
import Shelf from '@/components/shelf';

export default {
  name: 'StaticShelf',
  components: {
    Shelf,
    Typography,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
    }),
    showIntro() {
      return this.title || this.copy;
    },
    config() {
      return this.$store.getters['shelves/byName'](this.name);
    },
    title() {
      return this.config.title;
    },
    copy() {
      return this.config.copy;
    },
    items() {
      if (!this.config.items) return undefined;
      const { items } = this.config;
      return items.filter((item) => !['Neuheiten'].includes(item.label));
    },
    typographyVariant() {
      if (this.title.length > 25) return 'pre-heading';
      if (this.title.length > 15) return 'heading';
      return 'heading-large';
    },
  },
};
</script>

<style lang="scss">
.static-shelf {
  &__intro {
    padding: 0 30px;
    height: 240px;
  }

  &__headline {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
