import { mapMutations } from 'vuex';
import actions from '@/store/actions';

export default {
  mounted() {
    this.$store.dispatch(actions.UPDATE_BREADCRUMB, [
      { path: '/start', label: 'Home' },
      { path: '/dein-design/dein-design-start-page', label: 'DeinDesign' },
    ]);
  },
  methods: {
    ...mapMutations({
      updateBreadcrumb: actions.UPDATE_BREADCRUMB,
    }),
  },
};
