import Parser from './parser';
import Serializer from './serializer';

export function parseFilterParams(route) {
  const parser = new Parser(route);
  return parser.getVars();
}

export function serializeFilterParams(vars) {
  const serializer = new Serializer(vars);
  return serializer.getRoute();
}

export default {
  parse: parseFilterParams,
  serialize: serializeFilterParams,
};
