import { GoliathClient } from './goliath-client';
import deinDesignOffersForMotif from './queries/dein-design-offers-for-motif.graphql';

export default async function fetchOffersForMotif(params = {}) {
  const response = await GoliathClient.query({
    query: deinDesignOffersForMotif,
    variables: { ...params },
  });

  return response.data.offers;
}
