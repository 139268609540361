function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export default function highlightSubterms(terms, highlightingTag) {
  const escapedTerms = terms.map((term) => escapeRegExp(term));
  const termsToMatchRegex = new RegExp(escapedTerms.join('|'), 'gi');
  const replacementTerm = `<${highlightingTag}>$&</${highlightingTag}>`;

  return function highlighter(stringToHighlight) {
    const highlightedText = stringToHighlight.replace(
      termsToMatchRegex,
      replacementTerm,
    );
    return highlightedText;
  };
}
