import _ from 'lodash';

const PRICE_KEYS = ['amount', 'currency', 'paymentFrequency'];
export function getSimplifiedPrices(rawPrices) {
  const prices = {};
  rawPrices.forEach((rawPrice) => {
    if (rawPrice.type === 'DEFAULT') {
      prices.default = _.pick(rawPrice, PRICE_KEYS);
    }
    if (rawPrice.type === 'WAS') {
      prices.was = _.pick(rawPrice, PRICE_KEYS);
    }
  });
  return prices;
}

function getColorAlternatives(offerAlternatives) {
  const colorVariants = offerAlternatives[0].properties.find(
    (alternatives) => alternatives.key === 'COLOR',
  );
  return colorVariants.alternatives.filter(
    (alternative) => alternative.offer.scopedProduct.color !== null,
  ).map((alternative) => {
    const colorData = alternative.offer.scopedProduct.color;
    const colorName = colorData.name;
    const colorCode = colorData.code || 'transparent';
    const isColorful = colorData.colorful;
    return { colorName, colorCode, isColorful };
  });
}

export function productHasAlternatives(offerAlternatives) {
  return offerAlternatives
    && offerAlternatives.reduce((hasAlternatives, productAlternative) => {
      if (hasAlternatives) {
        return true;
      }
      return productAlternative.properties.reduce((propHasAlts, property) => {
        if (propHasAlts) {
          return true;
        }
        return property && property.alternatives.length > 1;
      }, false);
    }, false);
}

export function getSimplifiedColorAlternatives(offerAlternatives) {
  if (productHasAlternatives(offerAlternatives)) return getColorAlternatives(offerAlternatives);
  return null;
}

const IMAGE_KEYS = ['name', 'remote', 'path', 'thumbImagePath'];

export function getSimplifiedImages(rawMedia) {
  const typeIsImage = (medium) => medium.type === 'IMAGE';
  const onlyImageKeys = (medium) => _.pick(medium, IMAGE_KEYS);
  return rawMedia.filter(typeIsImage).map(onlyImageKeys);
}

function simplifyOffer(rawOffer) {
  const offer = {
    productName: rawOffer.product.name,
    brand: rawOffer.product.name,
    articleNumber: rawOffer.product.articleNumber,
    stock: rawOffer.product.stock.amount,
    prices: getSimplifiedPrices(rawOffer.prices),
    images: getSimplifiedImages(rawOffer.media),
  };
  return offer;
}

export default simplifyOffer;
