<template>
  <div class="variant-item">
    <div
      class="variant-item__btn"
      :class="classes"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VariantItem',
  props: {
    isActive: { type: Boolean },
    isDisabled: { type: Boolean },
  },
  computed: {
    classes() {
      return {
        'variant-item__btn--active': this.isActive,
        'variant-item__btn--disabled': this.isDisabled,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.variant-item {
  display: flex;
  justify-content: stretch;
  align-items: center;

  &__btn {
    position: relative;
    width: 100%;
    height: 100%;
    color: #0f0e0e;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.29);
    font-size: 25px;
    letter-spacing: 0.69px;
    line-height: 30px;
    padding: 12px 18px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;

    &:visited {
      color: inherit;
    }

    &--disabled {
      opacity: .5;
    }

    &--active {
      box-shadow: inset 1px 2px 10px 0 rgba(0, 0, 0, 0.62);

      &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: -15px;
        display: block;
        height: 4px;
        width: 100%;
        background: #E20074;
      }
    }
  }
}
</style>
