import ShopApi from '@/lib/shop-api';
import { getActiveDowntimeBySystem } from '@/lib/downtimes-helper';

export default {
  namespaced: true,
  state() {
    return {
      downtimes: [],
      lastUpdated: null,
    };
  },
  actions: {
    async fetchDowntimes(context) {
      try {
        const { data } = await ShopApi.downtimes();
        context.commit('SET_DOWNTIMES', data?.downtimes || []);
      } catch (e) {
        console.error(e);
      } finally {
        // do not attempt to load downtimes on every click, when loading failed.
        context.commit('SET_LAST_UPDATED', Date.now());
      }
    },
  },
  getters: {
    downtimes(state) {
      return state.downtimes;
    },
    tvppDowntime(state) {
      return getActiveDowntimeBySystem(state.downtimes, 'tvpp');
    },
    qiviconDowntime(state) {
      return getActiveDowntimeBySystem(state.downtimes, 'qivicon');
    },
    maintenanceDowntime(state) {
      return getActiveDowntimeBySystem(state.downtimes, 'maintenance');
    },

  },
  mutations: {
    SET_DOWNTIMES(state, downtimes) {
      state.downtimes = downtimes;
    },
    SET_LAST_UPDATED(state, timestamp) {
      state.lastUpdated = timestamp;
    },
  },
};
