export default {
  namespaced: true,
  state() {
    return {
      deeplink: false,
    };
  },
  mutations: {
    SET_DEEPLINK(state, payload) {
      state.deeplink = payload;
    },
  },
};
