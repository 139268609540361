<template>
  <div class="search-input">
    <div class="search-input__wrapper">
      <input
        v-model="term"
        class="search-input__input"
        type="text"
        name="query"
        placeholder="Was suchen Sie?"
        @focus="emitFocus"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  data() {
    return {
      term: '',
    };
  },
  watch: {
    term(newValue) {
      this.$emit('change', newValue);
    },
  },
  methods: {
    emitFocus(e) {
      this.$emit('focus', e);
    },
  },
};

</script>

<style lang="scss">
@import "@/assets/styles/style";

.search-input {
  padding: 40px 0;

  &__wrapper {
    border: 10px solid #fff;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    position: relative;
  }

  &__input {
    @include font-size(30px, 60px);
    background-image: linear-gradient(0deg, #f4f4f4 0%, #eee 60%, #ddd 100%);
    border-bottom-color: #e7e7e7;
    border-left-color: #d6d6d6;
    border-right-color: #dadada;
    border-style: solid;
    border-top-color: #cfcfcf;
    border-width: 10px;
    color: #000;
    display: block;
    font-weight: 400;
    height: 117px;
    outline: none;
    padding: 16px 26px;
    width: 100%;
  }
}
</style>
