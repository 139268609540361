<template>
  <div
    ref="list"
    class="cart-list"
    :class="{'cart-list--fade':isFadeVisible}"
    @scroll="calcFadeVisibility"
  >
    <div
      v-for="product in products"
      :key="product.name"
      class="cart-list__entry--bordered"
    >
      <CartListBundle
        v-if="product.bundledProducts"
        :product="product"
      />
      <CartListItem
        v-else
        v-bind="product"
      />
    </div>
  </div>
</template>

<script>
import CartListItem from '@/components/cart-list-item';
import CartListBundle from '@/components/cart-list-bundle';

const REMOVE_FADE_THRESHOLD = 0; // in px
const MIN_ITEMS_FOR_FADE = 4;

export default {
  name: 'CartList',
  components: { CartListBundle, CartListItem },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isFadeVisible: false,
    };
  },
  computed: {
    positionsInCart() {
      return this.products.map((product) => (this.countBundleProducts(product) + 1)).reduce((a, b) => a + b, 0);
    },
  },
  watch: {
    products(newVal, oldVal) {
      const hasProductCountChanged = newVal.length !== oldVal.length;
      if (hasProductCountChanged) this.calcFadeVisibility();
    },
  },
  mounted() {
    this.calcFadeVisibility();
  },
  methods: {
    calcFadeVisibility() {
      if (this.positionsInCart < MIN_ITEMS_FOR_FADE) this.isFadeVisible = false;
      const { scrollTop, scrollHeight: listHeight, clientHeight: containerHeight } = this.$refs.list;
      const initialHiddenHeight = listHeight - containerHeight;
      const currentHiddenHeight = initialHiddenHeight - scrollTop;
      this.isFadeVisible = currentHiddenHeight > REMOVE_FADE_THRESHOLD;
    },
    countBundleProducts(product) {
      return product.bundledProducts ? product.bundledProducts.length : 0;
    },
  },
};
</script>

<style lang="scss">
.cart-list {
  position: relative;
  max-height: 590px;
  display: grid;
  grid-row-gap: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  box-sizing: content-box;
  margin-right: -30px;
  padding-right: 30px;

  &::after {
    transition: opacity 500ms;
    opacity: 0;
    content: "";
    position: sticky;
    z-index: 1;
    bottom: 0;
    left: 0;
    height: 50px;
    margin-top: -70px; // grid gap + elem height
    width: 100%;
    pointer-events: none;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 80%,
      #ffffff 100%
    );
  }

  &--fade::after {
    opacity: 1;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #d0d0d0;
  }

  &::-webkit-scrollbar-thumb {
    background: #e10074;
  }

  &__entry {
    &--bordered:not(:last-child) {
      border-bottom: 2px solid #d8d8d8;
      padding-bottom: 20px;
    }
  }
}
</style>
