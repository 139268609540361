import { GoliathClient } from './goliath-client';
import deinDesignProductFamilies from './queries/dein-design-product-families.graphql';
import deinDesignBrands from './queries/dein-design-brands.graphql';

export async function fetchDeinDesignProductFamilies(params = {}) {
  const response = await GoliathClient.query({
    query: deinDesignProductFamilies,
    variables: { ...params },
  });

  return response.data.offers;
}

export async function fetchDeinDesignBrands(params = {}) {
  const response = await GoliathClient.query({
    query: deinDesignBrands,
    variables: { ...params },
  });

  return response.data.offers;
}
