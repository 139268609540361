<template>
  <Modal
    ref="modal"
    class="dein-design-privacy"
  >
    <Typography
      ref="heading"
      variant="title-bold"
      class="dein-design-privacy__heading"
    >
      Datenschutzhinweis der Telekom Deutschland GmbH („Telekom“)
      für DeinDesign
    </Typography>
    <Typography
      variant="flow-text"
    >
      <p>Für die Verarbeitung Ihrer persönlichen Daten gilt der</p>
      <div class="dein-design-privacy__link">
        <VueQrcode
          value="https://www.telekom.de/datenschutzhinweise/download/000.pdf"
          :options="qrOptions"
        />
        <em>Allgemeine Datenschutzhinweis der Telekom Deutschland GmbH.</em>
      </div>
      <p>Zudem gelten die Datenschutzhinweise für den</p>

      <div class="dein-design-privacy__link">
        <VueQrcode
          value="https://www.telekom.de/datenschutzhinweise/download/001.pdf"
          :options="qrOptions"
        />
        <em>Abschluss eines Festnetz- oder Mobilfunkvertrags.</em>
      </div>

      <p>
        Bei der Nutzung dieser Produkte gelten ergänzend
        die nachfolgenden Hinweise.
      </p>
      <p><strong>Wir verwenden folgende Daten:</strong></p>
      <p>
        Wer verwenden im Rahmen des Auswahlprozesses für DeinDesign
        folgende Daten:
      </p>
      <ul>
        <li>
          Coverauswahl, einen Städtenamen für die Zuordnung sowie eine
          Rücksprunginformation, um den Warenkorb im Telekom Shop
          weiter bearbeiten zu können.
        </li>
      </ul>
      <p>
        Im Rahmen des Abwicklungsprozesses werden darüber hinaus folgende
        Daten verwendet:
      </p>
      <ul>
        <li>
          Versanddaten und die durch den Kunden bei DeinDesign zur
          Erstellung des persönlichen Covers hinterlegten Bilddaten.
          Diese Daten werden jedoch nur von der Firma DeinDesign zur
          Ausführung des Auftrages gespeichert. In dem Zusammenhang
          gelten die Datenschutzrichtlinien der Firma DeinDesign, zu denen
          Sie im weiteren Bestellprozess eine Zustimmung erteilen müssen.
        </li>
      </ul>
      <p><strong>Wir verwenden die Daten für folgende Zwecke:</strong></p>
      <p>
        Die Daten werde erhoben, um zusammen mit der Firma DeinDesign das
        persönliche Cover für den Kunden zu erstellen und zu versenden.
      </p>
      <p>Rechtsgrundlage ist Art. 6 Abs. 1b Datenschutz Grundverordnung (DSGVO).</p>
      <p><strong>Wir löschen die Daten:</strong></p>
      <p>
        Die Daten, von nicht abgeschlossenen Bestellungen werden immer um
        0:00 Uhr des Folgetages gelöscht. Angefangene Warenkörbe, die nicht
        bis zur Dateneingabe weitergegeben werden, werden innerhalb von
        60sec ohne Nutzung gelöscht.
      </p>
      <p>
        Für die Auftragsabwicklung auf Seiten DeinDesign gelten die
        Regelungen der Firma DeinDesign.
      </p>
      <p><strong>Wo werden meine Daten verarbeitet?</strong></p>
      <p>Ihre Daten werden in Deutschland und im europäischen Ausland verarbeitet.</p>
    </Typography>
  </Modal>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import Modal from '@/components/modal';
import Typography from '@/components/typography';

export default {
  name: 'DeinDesignPrivacy',
  components: {
    Typography,
    Modal,
    VueQrcode,
  },
  data() {
    return {
      qrOptions: { width: 120, margin: 0, errorCorrectionLevel: 'H' },
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
  },
};
</script>

<style lang="scss">
.dein-design-privacy {
  &__heading,
  p,
  ul {
    margin-bottom: 20px;
  }
  ul {
    margin-left: 30px;
  }

  &__link {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    canvas {
      margin-right: 20px;
    }
  }
}
</style>
