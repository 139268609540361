import ms from 'ms';

const fullCycleTime = ms('2.5 mins');

export default {
  namespaced: true,
  state() {
    return {
      fullCycleTime,
      timeLeft: fullCycleTime,
      lastTouchedAt: Date.now(),
    };
  },
  mutations: {
    SET_TIME_LEFT(state, time) {
      state.timeLeft = time;
    },
    SET_FULL_CYCLE_TIME(state, time) {
      state.fullCycleTime = time;
    },
    SET_LAST_TOUCHED_AT(state, time) {
      state.lastTouchedAt = time;
    },
  },
};
