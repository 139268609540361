<template>
  <div
    v-show="selectableProductFamilies"
    ref="productFamilySelector"
    class="product-family-selector"
  >
    <LetterNavigation
      ref="letterNavigation"
      :available-letters="uniqueLetters"
      :selected-letters="selectedUniqueLetters"
      @click="animateToLetter"
    />
    <div
      ref="productFamilySelectorItemGroup"
      class="product-family-selector__item-group"
    >
      <SelectableItem
        v-if="allSelector"
        ref="selectableItemAll"
        class="product-family-selector__selectable-item"
        :value="allSelected"
        @input="onInputAllSelector"
      >
        <span class="product-family-selector__family-name">
          Alle
        </span>
      </SelectableItem>
      <SelectableItem
        v-for="(entry, index) in selectableProductFamilies"
        ref="selectableItem"
        :key="`selectable-item-productFamily-${index}`"
        :value="isSelected(entry.productFamily.slug)"
        class="product-family-selector__selectable-item"
        @input="onInput($event, entry.productFamily.slug)"
      >
        <span class="product-family-selector__family-name">
          {{ entry.productFamily.model }}
        </span>
      </SelectableItem>
    </div>
  </div>
</template>

<script>
import {
  uniq, pull, max, isEmpty,
} from 'lodash';
import { gsap } from 'gsap';
import SelectableItem from '@/components/selectable-item';
import LetterNavigation from '@/components/letter-navigation';
import DraggableMixin from '@/mixins/draggable-mixin';

const getSelectableProductFamilies = (productFamilies, selectedProductFamilies = []) => {
  // get selectedStatus from internal selectedProductFamilies
  const selectableProductFamilies = productFamilies.map((productFamily) => ({
    ...productFamily,
    selected: selectedProductFamilies.includes(productFamily.productFamily.slug),
  }));

  // sort selectableProductFamilies
  const sortedSelectableProductFamilies = selectableProductFamilies
    .sort((entryA, entryB) => {
      const modelNameA = entryA.productFamily.model.toUpperCase();
      const modelNameB = entryB.productFamily.model.toUpperCase();
      if (modelNameA < modelNameB) { return -1; }
      return 1;
    });
  return sortedSelectableProductFamilies;
};

export default {
  name: 'ProductFamilySelector',
  components: {
    SelectableItem,
    LetterNavigation,
  },
  mixins: [
    DraggableMixin('productFamilySelectorItemGroup'),
  ],
  props: {
    allSelector: {
      type: Boolean,
      default: true,
    },
    aggregations: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedProductFamilies: [...this.value],
    };
  },
  computed: {
    selectableProductFamilies() {
      return getSelectableProductFamilies(this.aggregations, this.selectedProductFamilies);
    },
    allSelected() {
      return !this.selectedProductFamilies.length;
    },
    selectedUniqueLetters() {
      const selectedLetters = this.selectableProductFamilies
        .filter((entry) => this.selectedProductFamilies
          .includes(entry.productFamily.slug))
        .map((entry) => this.getFirstUpperCaseLetter(entry.productFamily.model));
      return uniq(selectedLetters);
    },
    letters() {
      return this.selectableProductFamilies
        .map((entry) => this.getFirstUpperCaseLetter(entry.productFamily.model));
    },
    uniqueLetters() {
      return uniq(this.letters);
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedProductFamilies = [...value];
      },
    },
    active: {
      handler(becomesActive) {
        if (!becomesActive) return;
        if (this.initialAnimationDone) return;
        this.initIntroSlide();
        this.initialAnimationDone = true;
      },
    },
  },
  methods: {
    getFirstUpperCaseLetter(string) {
      return string.charAt(0).toUpperCase();
    },
    isSelected(slug) {
      return this.selectedProductFamilies.includes(slug);
    },
    emitInput(selectedProductFamilies) {
      this.$emit('input', selectedProductFamilies);
      this.$emit('change');
      if (isEmpty(selectedProductFamilies)) this.$emit('reset');
    },
    onInputAllSelector() {
      this.emitInput([]);
    },
    onInput(val, slug) {
      const selectedProductFamilies = [...this.selectedProductFamilies];
      if (selectedProductFamilies.includes(slug)) {
        pull(selectedProductFamilies, slug);
      } else {
        selectedProductFamilies.push(slug);
      }
      this.emitInput(selectedProductFamilies);
    },
    getItemGroupWidth() {
      return this.$refs.productFamilySelectorItemGroup
        .getBoundingClientRect()
        .left;
    },
    getLetterOffsetLeft(letter) {
      if (letter === '#') {
        return this.$refs.selectableItem[0]
          .$el
          .getBoundingClientRect()
          .left;
      }

      return this.$refs.selectableItem[
        this.letters.indexOf(letter)
      ].$el
        .getBoundingClientRect()
        .left;
    },
    getLetterTransformXValue(letter) {
      const margin = 30;
      const xVal = this.getItemGroupWidth()
        - this.getLetterOffsetLeft(letter) + margin;

      const xValMax = this.$refs.productFamilySelectorItemGroup.scrollWidth * -1
          + window.outerWidth - margin;

      return max([xVal, xValMax]);
    },
    animateToX(xVal) {
      const tl = gsap.timeline();
      tl.to(this.$refs.productFamilySelectorItemGroup, {
        x: xVal,
        duration: 2,
      });
    },
    animateToLetter(letter) {
      const xVal = this.getLetterTransformXValue(letter);
      this.animateToX(xVal);
    },
  },
};
</script>

<style lang="scss">
.product-family-selector {
  &__item-group {
    display: flex;
    padding: 0px 30px;
  }

  &__selectable-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }

  &__family-name {
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    display: block;
  }
}
 </style>
