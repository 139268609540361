<template>
  <li
    class="dropdown-item"
    :class="containerClasses"
    :style="`--left: ${left}; --transition-delay: ${transitionDelay};`"
  >
    <div class="dropdown-item__wrapper">
      <div
        class="dropdown-item__label-wrapper"
        :class="wrapperClasses"
        @click="onClick"
      >
        <div class="dropdown-item__label">
          <slot :value="value" />
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'DropdownItem',
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    value: {
      type: null,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    allVisible: {
      type: Boolean,
      default: false,
    },
    left: {
      type: String,
      default: '0px',
    },
    transitionDelay: {
      type: String,
      default: '0s',
    },
  },
  computed: {
    containerClasses() {
      const classes = [];
      if (this.allVisible) {
        classes.push('dropdown-item--all-visible');
      } else if (this.isVisible) {
        classes.push('dropdown-item--visible');
      }
      return classes;
    },
    wrapperClasses() {
      const classes = [];
      if (this.selected) classes.push('dropdown-item__label-wrapper--active');
      return classes;
    },
  },
  methods: {
    onClick() {
      this.$emit('item-selected', this.value);
    },
  },
};
</script>

<style lang="scss">
.dropdown-item {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  transition: left 0s linear 0.5s;
  z-index: 0;

  &--all-visible {
    opacity: 1;
    left: var(--left);
    transition: left 0.3s linear, opacity 0.15s linear calc(0.3s + var(--transition-delay));
  }

  &--visible {
    opacity: 1;
    z-index: 1;
    left: 0;
    transition: left 0.3s linear, opacity 0.15s linear 0.3s;
  }

  &__wrapper {
    padding-left: 4px;
  }

  &__label-wrapper {
    &--active {
      border-bottom: 2px solid black;
    }
  }

  &__label {
    font-size: 25px;
    letter-spacing: 0.42px;
    line-height: 30px;
    padding-bottom: 2px;
    white-space: nowrap;
  }
}
</style>
