<template>
  <div class="loading-spinner">
    <div class="loading-spinner__cube">
      <div class="loading-spinner__cube-wrapper">
        <div class="loading-spinner__cube-wall loading-spinner__cube-wall--front" />
        <div class="loading-spinner__cube-wall loading-spinner__cube-wall--back" />
        <div class="loading-spinner__cube-wall loading-spinner__cube-wall--left" />
        <div class="loading-spinner__cube-wall loading-spinner__cube-wall--right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
};
</script>

<style lang="scss">
.loading-spinner {
  display: flex;
  width: 580px;
  height: 580px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  &__cube {
    perspective: 800px;
    width: 370px;
    height: 370px;
  }

  &__cube-wrapper {
    transform-style: preserve-3d;
    animation: cube__element 4s linear infinite;
  }

  &__cube-wall {
    background: #e20074;
    height: 370px;
    left: 0;
    position: absolute;
    top: 0;
    width: 370px;

    &--front {
      transform: rotateY(0deg) translateZ(185px);
    }

    &--back {
      transform: rotateY(180deg) translateZ(185px);
    }

    &--left {
      background: #d0006b;
      transform: rotateY(-90deg) translateZ(185px);
    }

    &--right {
      background: #d0006b;
      transform: rotateY(90deg) translateZ(185px);
    }
  }
}

@keyframes cube__element {
  100% { transform: rotateY(-360deg); }
}
</style>
