<template>
  <div
    class="typography"
    :class="variantClass"
  >
    <slot />
  </div>
</template>

<script>
const VALID_VARIANTS = [
  'timeout-content',
  'timeout-counter',
  'heading-gigantic-magenta',
  'heading-large-magenta',
  'pre-heading',
  'heading-large',
  'heading',
  'subheading',
  'title',
  'title-magenta',
  'title-bold',
  'flow-text',
  'flow-text-title',
  'order-number',
  'order-code',
  'display-magenta',
  'hint',
  'answer',
  'filter-selected',
];

export default {
  name: 'Typography',
  props: {
    variant: {
      type: String,
      required: true,
      validator: (value) => VALID_VARIANTS.includes(value),
    },
  },
  computed: {
    variantClass() {
      return `typography--${this.variant}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.typography {
  // display: block variants
  &--heading-gigantic-magenta {
    @include font-size(170px, 110px);
    font-weight: 700;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    color: #e20074;
  }

  &--heading-large-magenta {
    @include font-size(100px, 110px);
    font-weight: 700;
    color: #e20074;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &--timeout-content {
    @include font-size(45px, 73px);
    font-weight: 400;
    color: #3a3a3a;
    letter-spacing: 0.45px;
  }

  &--timeout-counter {
    @include font-size(460px, 110px);
    font-weight: 700;
    opacity: 0.46;
    color: #e20074;
    letter-spacing: 5.75px;
  }

  &--heading-large {
    @include font-size(100px, 110px);
    font-weight: 700;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }

  &--heading {
    @include font-size(60px, 70px);
    font-weight: 700;
    letter-spacing: 0.75px;
    text-transform: uppercase;
  }

  &--flow-text {
    @include font-size(40px, 56px);
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  &--flow-text-title {
    @include font-size(40px, 50px);
    font-weight: 700;
    text-transform: uppercase;
  }

  &--subheading {
    @include font-size(40px, 50px);
    font-weight: 400;
  }

  &--title-bold {
    @include font-size(30px, 40px);
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
  }

  &--pre-heading {
    @include font-size(40px, 40px);
    font-weight: 700;
    text-transform: uppercase;
  }

  &--hint {
    @include font-size(15px, 20px);
    font-weight: 400;
    color: #6c6c6c;
  }

  &--answer {
    @include font-size(30px, 40px);
    color: #000;
    display: block;
    font-weight: 400;
  }

  // display: inline variants
  &--order-code {
    @include font-size(60px, 70px);
    font-weight: 700;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    display: inline;
  }

  &--order-number {
    @include font-size(40px, 50px);
    font-weight: 700;
    color: #e20074;
    display: inline;
  }

  &--display-magenta {
    @include font-size(40px, 40px);
    font-weight: 400;
    color: #e20074;
    display: inline;
  }

  &--title {
    @include font-size(30px, 35px);
    font-weight: 400;
    color: #000;
    display: inline;
  }

  &--title-magenta {
    @include font-size(30px, 35px);
    font-weight: 400;
    color: #e20074;
    display: inline;
  }

  &--filter-selected {
    @include font-size(25px, 28px);
    font-weight: 400;
    color: #7D7D7D;
    display: inline;
  }
}
</style>
