<template>
  <div
    class="select-motif-default-page"
  >
    <RouterTab
      :tabs="tabs"
    />

    <router-view />
  </div>
</template>

<script>
import SearchIcon from '@/assets/images/search-icon.svg';
import RouterTab from '@/components/router-tab';
import DeinDesignQueryParams from '@/mixins/dein-design-query-params';

export default {
  name: 'SelectMotifDefaultPage',
  components: {
    RouterTab,
  },
  mixins: [
    DeinDesignQueryParams,
  ],
  computed: {
    tabs() {
      const queryParams = this.getQueryParams();
      return [
        {
          title: 'Beliebteste',
          path: {
            name: 'dein-design-select-motif-recommendation-page',
            query: {
              ...queryParams,
            },
          },
        },
        {
          title: 'Kategorien',
          path: {
            name: 'dein-design-select-motif-category-page',
            query: {
              ...queryParams,
            },
          },
        },
        {
          title: 'Suche',
          icon: SearchIcon,
          path: {
            name: 'dein-design-select-motif-search-page',
            query: {
              ...queryParams,
            },
          },
        },
      ];
    },
  },
};
</script>
