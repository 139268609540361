export default {
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    isClosed() {
      return !this.isOpened;
    },
  },
  methods: {
    onOpen() {},

    onClose() {},

    async open(params) {
      if (this.isOpened) return;
      this.isOpened = true;
      await this.onOpen(params);
      this.$emit('open', params);
    },

    async close() {
      if (this.isClosed) return;
      this.isOpened = false;
      await this.onClose();
      this.$emit('close');
    },

    async toggle() {
      if (this.isOpened) {
        await this.close();
        this.$emit('toggle', false);
      } else {
        await this.open();
        this.$emit('toggle', true);
      }
    },
  },
};
