<template>
  <div
    ref="slider"
    class="range-slider"
    :class="containerClass"
    @touchstart="setActive"
    @mousedown="setActive"
    @touchend="setInactive"
    @mouseup="setInactive"
  >
    <div class="range-slider__arrow range-slider__arrow--left" />
    <div class="range-slider__arrow range-slider__arrow--right" />
  </div>
</template>

<script>
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

export default {
  name: 'RangeSlider',
  props: {
    value: {
      type: Number,
      default: null,
    },
    boundMin: {
      type: Number,
      default: null,
    },
    boundMax: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      active: false,
      draggableInstance: null,
    };
  },
  computed: {
    hasDraggableInstance() {
      return this.draggableInstance && this.draggableInstance[0];
    },
    containerClass() {
      if (!this.active) return '';
      return 'range-slider--active';
    },
    internalValue() {
      if (!this.hasDraggableInstance) return undefined;
      return this.draggableInstance[0].x;
    },
  },
  watch: {
    // pixel boundaries
    boundMin() {
      this.setDragBounds();
    },
    boundMax() {
      this.setDragBounds();
    },
  },
  mounted() {
    this.initSlider();
    this.setDragBounds();
  },
  beforeDestroy() {
    if (this.hasDraggableInstance) {
      this.draggableInstance[0].kill();
    }
  },
  methods: {
    updateSlider() {
      if (!this.hasDraggableInstance) return;
      this.draggableInstance[0].update();
    },
    onDraggableEvent() {
      this.setDragBounds();
      this.emitInput();
    },
    initSlider() {
      this.draggableInstance = Draggable.create(this.$refs.slider, {
        type: 'x',
        onPress: this.onDraggableEvent,
        onDrag: this.onDraggableEvent,
        bounds: {
          minX: this.boundMin,
          maxX: this.boundMax,
        },
      });
    },
    emitInput() {
      this.$emit('input', this.internalValue);
    },
    setDragBounds() {
      if (!this.hasDraggableInstance) return;
      this.draggableInstance[0].applyBounds({
        minX: this.boundMin,
        maxX: this.boundMax,
      });
    },
    setActive() {
      this.active = true;
    },
    setInactive() {
      this.active = false;
    },
  },
};
</script>

<style lang="scss">
.range-slider {
  position: relative;
  z-index: 2;
  height: 50px;
  width: 120px;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 1px 2px 8px 2px rgba(0,0,0,0.29);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--active {
    background-color: #FBFBFB;
    box-shadow: inset 1px 2px 10px 0 rgba(0,0,0,0.4);
  }

  &__arrow {
    @mixin arrow-line {
      content: '';
      display: block;
      width: 14px;
      height: 3px;
      transform-origin: left;
      background-color: #D3D3D3;
      border-radius: 20px;
    }
    &--left {
      margin-left: 20px;
      &::before {
        @include arrow-line;
        transform: rotate(45deg);
        position: relative;
        top: 1px;
      }
      &::after {
        @include arrow-line;
        transform: rotate(-45deg);
      }
    }
    &--right {
      margin-right: 20px;
      &::before {
        @include arrow-line;
        transform-origin: right;
        transform: rotate(-45deg);
        position: relative;
        top: 1px;
      }
      &::after {
        @include arrow-line;
        transform-origin: right;
        transform: rotate(45deg);
      }
    }
  }
}
</style>
