<template>
  <div
    class="progress-bar-step"
    :class="{
      'progress-bar-step--animation' : hasAnimation,
      'progress-bar-step--active' : isActive,
      'progress-bar-step--complete' : isCompleted,
      'progress-bar-step--first' : isFirstStep
    }"
  >
    <button
      class="progress-bar-step__link"
      @click="$emit('switch-step')"
    >
      <svg
        class="progress-bar-step__circle"
        height="92"
        width="92"
        viewBox="0 0 86 86"
      >
        <circle
          cx="43"
          cy="43"
          r="40"
          fill="transparent"
          stroke-width="5"
        />
      </svg>
      <div class="progress-bar-step__icon">
        <component :is="icon" />
      </div>
    </button>
    <div class="progress-bar-step__bar" />
    <div
      class="progress-bar-step__active-bar"
    />
    <span
      class="progress-bar-step__title"
    >{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: 'ProgressBarStep',
  props: {
    icon: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: '',
    },
    step: {
      type: Number,
      required: true,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      stepDiff: 0,
    };
  },
  computed: {
    isActive() {
      return this.current === this.step;
    },
    isCompleted() {
      return this.current > this.step;
    },
    isFirstStep() {
      return this.step === 0;
    },
    hasAnimation() {
      return this.isActive && this.stepDiff === 1;
    },
  },
  watch: {
    current(newValue, oldValue) {
      this.stepDiff = newValue - oldValue;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";
$circleDiameter: 86px;
$strokeWith: 5px;
$colorGrey: #d7d7d7;

@keyframes circleShine {
  0% {
    box-shadow: 0 0 0 0 color(magenta, 1000);
  }
  50% {
    box-shadow: 0 0 10px 5px color(magenta, 1000);
  }
  100% {
    box-shadow: 0 0 0 0 color(magenta, 1000);
  }
}

.progress-bar-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  button {
    cursor: pointer;
  }

  &__link {
    position: relative;
    width: $circleDiameter;
    height: $circleDiameter;
    border-radius: 50%;
    border: 1.4px solid $colorGrey;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: inherit;

    .progress-bar-step--animation & {
      animation: circleShine .75s cubic-bezier(.4, 0, .2, 1) forwards .8s;
    }
  }

  &__circle {
    position: absolute;
    transform: rotateZ(180deg);
    stroke: $colorGrey;
    visibility: hidden;

    circle {
      stroke-dasharray: 250, 250;
      stroke-dashoffset: 250;

      .progress-bar-step--animation & {
        transition: all .75s cubic-bezier(.1, .3, .2, 1) .3s;
      }
    }

    .progress-bar-step--complete &,
    .progress-bar-step--active & {
      stroke: color(magenta, 1000);
      visibility: visible;

      circle {
        stroke-dasharray: 250, 0;
        stroke-dashoffset: 125;
      }
    }
  }

  &__icon {
    width: 75%;
  }

  &__title {
    color: color(black);
    font-weight: 700;
    text-align: center;
    word-break: break-word;

    .progress-bar-step--complete & {
      color: color(magenta, 1000);
    }
  }

  &__bar,
  &__active-bar {
    position: absolute;
    top: ($circleDiameter * 0.5) - 1px;
    z-index: -1;
  }
  &__bar {
    left: 0;
    right: 0;
    height: 2px;
    background-color: $colorGrey;
  }

  &__active-bar {
    left: calc(-1 * (100% - #{$circleDiameter}) * 0.5);
    right: calc((100% - #{$circleDiameter}) * 0.5 + #{$circleDiameter});
    height: $strokeWith;
    background-color: color(magenta, 1000);
    transform-origin: left center;
    transform: scaleX(0);

    .progress-bar-step--animation & {
      transition: transform .3s cubic-bezier(.4, 0, .9, .7);
    }

    .progress-bar-step--active &,
    .progress-bar-step--complete & {
      transform: scaleX(1);
    }

    .progress-bar-step--first & {
      left: 0;
    }
  }
}
</style>
