<template>
  <div class="cart-page">
    <Header
      :is-cart-active="true"
      :show-breadcrumb="false"
      class="cart-page__header"
    />
    <div class="page-grid">
      <div class="cart-page__cart-section">
        <Typography
          class="heading cart-page__heading"
          variant="heading"
        >
          {{ cart.items.length }} Artikel im Warenkorb
        </Typography>
        <CartList
          :products="itemsInCart"
          class="cart-page__item-list"
        />
        <CartSummary
          :is-checkout-allowed="!isCartEmpty"
          :total="cart.total"
          class="cart-page__summary"
        />
      </div>
      <div
        v-if="hasRecommendations"
        class="cart-page__recommendations"
      >
        <Typography
          variant="title-bold"
        >
          Empfehlungen zu ihrem Einkauf
        </Typography>
        <CartRecommendations :recommendations="simplifiedRecommendations" />
      </div>
      <Footer class="cart-page__footer" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isEmpty } from 'lodash';
import CartList from '@/components/cart-list';
import Footer from '@/components/footer';
import Header from '@/components/header';
import Typography from '@/components/typography';
import CartSummary from '@/components/cart-summary';
import CartRecommendations from '@/components/cart-recommendations';
import fetchAccessoriesAndRecommendations from '@/lib/goliath/offers-accessories-and-recommendations';
import { generateRecommendationsOnShelfFromGoliath } from '@/lib/shelves-helper';

export default {
  name: 'CartPage',
  components: {
    CartList,
    Header,
    Footer,
    Typography,
    CartSummary,
    CartRecommendations,
  },
  data() {
    return {
      offersRecommendations: null,
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart.cart,
      bundlesByArticleNumber: (state) => state.cart.bundlesByArticleNumber,
    }),
    simplifiedRecommendations() {
      if (!this.recommendations) return [];

      const offersRecommendations = generateRecommendationsOnShelfFromGoliath(
        this.recommendations,
      );

      return this.flattenRecommendations(offersRecommendations);
    },
    articleNumbers() {
      if (!this.cart) return null;
      return this.cart.items.map((offer) => offer.articleNumber);
    },
    isCartEmpty() {
      const items = this.cart && this.cart.items;
      return isEmpty(items);
    },
    recommendations() {
      if (!this.offersRecommendations || this.offersRecommendations?.length === 0) return null;
      const result = [];
      this.offersRecommendations.entries.forEach((entry) => {
        if (entry.recommendations.length > 0) {
          result.push(entry.recommendations);
        }
        if (entry.accessories.length > 0) result.push(entry.accessories);
      });
      return result;
    },
    hasRecommendations() {
      if (!this.recommendations) return false;
      return this.recommendations.length > 0;
    },
    // Items + Hardware Bundles: https://jira.i22.de/browse/LT-8
    itemsInCart() {
      return this.cart.items.map((cartItem) => {
        if (!Object.keys(this.bundlesByArticleNumber).includes(cartItem.articleNumber)) {
          return cartItem;
        }
        const bundledProducts = this.bundlesByArticleNumber[cartItem.articleNumber];
        return {
          ...cartItem,
          bundledProducts,
        };
      });
    },
  },
  mounted() {
    this.loadCartRecommendations();
  },
  methods: {
    async loadCartRecommendations() {
      try {
        this.offersRecommendations = await fetchAccessoriesAndRecommendations({
          articleNumbers: this.articleNumbers,
        });
      } catch (error) {
        this.offersRecommendations = null;
        throw error;
      }
    },
    flattenRecommendations(offersRecommendations) {
      const result = [];
      const offersRecommendationsCount = 4;
      const maxRecommendations = 20;
      const limitItems = offersRecommendations.length > offersRecommendationsCount;
      const itemLimit = limitItems
        ? offersRecommendationsCount + 1
        : maxRecommendations;

      offersRecommendations.map((offerRecommendations) => offerRecommendations.map((offerRecommendation) => result.push(offerRecommendation)));

      return result.slice(0, itemLimit);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.cart-page {
  &__heading {
    margin-top: 26px;
    grid-column: 1/-1;
  }

  &__cart-section {
    width: 100%;
    grid-column: 1 / span 8;
    height: 1025px;
  }

  &__item-list {
    margin-top: 53px;
  }

  &__summary {
    padding-top: 20px;
  }

  &__recommendations {
    padding-top: 40px;
    grid-column: 1/-1;
  }
}
</style>
