import { GoliathClient } from './goliath-client';
import deinDesignOffersForCase from './queries/dein-design-offers-for-case.graphql';

export default async function fetchOffersForCases(params = {}) {
  const response = await GoliathClient.query({
    query: deinDesignOffersForCase,
    variables: { ...params },
  });

  return response.data.offers;
}
