<template>
  <div>
    <CartListItem
      v-bind="product"
      :is-bundle-product="true"
    />
    <CartListItem
      v-for="bundleItem in bundleProducts"
      :key="bundleItem.name"
      v-bind="mapBundleItem(bundleItem)"
      :is-bundle-item="true"
    />
  </div>
</template>

<script>
import CartListItem from '@/components/cart-list-item';

export default {
  name: 'CartListBundle',
  components: {
    CartListItem,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bundleProducts() {
      return this.product.bundledProducts;
    },
  },
  methods: {
    mapBundleItem(item) {
      return {
        img: item.teaserImage.thumbImagePath,
        name: item.name,
        quantity: item.count * this.product.quantity,
        articleNumber: item.articleNumber,
        id: item.id,
        slug: item.slug,
      };
    },
  },
};
</script>
