<template>
  <div
    ref="brandSelector"
    class="brand-selector"
  >
    <LetterNavigation
      :available-letters="uniqueLetters"
      :selected-letters="selectedUniqueLetters"
      @click="animateToLetter"
    />
    <div
      ref="brandSelectorItemGroup"
      class="brand-selector__item-group"
    >
      <SelectableItem
        v-if="selectableBrands"
        ref="selectableItemAll"
        class="brand-selector__selectable-item"
        :value="allSelected"
        @input="onInputAllSelector"
      >
        <span class="brand-selector__brand-name">
          Alle
        </span>
      </SelectableItem>
      <SelectableItem
        v-for="(entry, index) in selectableBrands"
        ref="selectableItem"
        :key="`selectable-item-brand-${index}`"
        :value="isSelected(entry.brand.slug)"
        class="brand-selector__selectable-item"
        @input="onInput($event, entry.brand.slug)"
      >
        <span class="brand-selector__brand-name">
          {{ entry.brand.name }}
        </span>
      </SelectableItem>
    </div>
  </div>
</template>

<script>
import {
  uniq, pull, max, isEmpty,
} from 'lodash';
import { gsap } from 'gsap';
import SelectableItem from '@/components/selectable-item';
import LetterNavigation from '@/components/letter-navigation';
import DraggableMixin from '@/mixins/draggable-mixin';

const getSelectableBrands = (brands, selectedBrandSlugs = []) => {
  const selectableBrands = brands.map((brand) => ({
    ...brand,
    selected: selectedBrandSlugs.includes(brand.brand.slug),
  }));
  return selectableBrands;
};

export default {
  name: 'BrandSelector',
  components: {
    SelectableItem,
    LetterNavigation,
  },
  mixins: [
    DraggableMixin('brandSelectorItemGroup'),
  ],
  props: {
    aggregations: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedBrandSlugs: [...this.value],
    };
  },
  computed: {
    selectableBrands() {
      return getSelectableBrands(this.aggregations, this.selectedBrandSlugs);
    },
    allSelected() {
      return !this.selectedBrandSlugs.length;
    },
    selectedUniqueLetters() {
      const selectedLetters = this.selectedBrandSlugs
        .map((brand) => this.getFirstUpperCaseLetter(brand));

      return uniq(selectedLetters);
    },
    letters() {
      return this.selectableBrands
        .map((entry) => this.getFirstUpperCaseLetter(entry.brand.slug));
    },
    uniqueLetters() {
      return uniq(this.letters);
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedBrandSlugs = [...value];
      },
    },
    active: {
      handler(becomesActive) {
        if (!becomesActive) return;
        if (this.initialAnimationDone) return;
        this.initIntroSlide();
        this.initialAnimationDone = true;
      },
    },
  },
  methods: {
    getFirstUpperCaseLetter(string) {
      return string.charAt(0).toUpperCase();
    },
    isSelected(slug) {
      return this.selectedBrandSlugs.includes(slug);
    },
    emitInput(selectedBrandSlugs) {
      if (isEmpty(selectedBrandSlugs)) {
        this.$emit('reset');
        return;
      }
      this.$emit('input', selectedBrandSlugs);
      this.$emit('change');
    },
    onInputAllSelector() {
      // empty selectedBrandSlugs
      this.emitInput([]);
    },
    onInput(val, slug) {
      const selectedBrandSlugs = [...this.selectedBrandSlugs];
      if (selectedBrandSlugs.includes(slug)) {
        pull(selectedBrandSlugs, slug);
      } else {
        selectedBrandSlugs.push(slug);
      }
      this.emitInput(selectedBrandSlugs);
    },
    getItemGroupWidth() {
      return this.$refs.brandSelectorItemGroup
        .getBoundingClientRect()
        .left;
    },
    getLetterOffsetLeft(letter) {
      return this.$refs.selectableItem[
        this.letters.indexOf(letter)
      ].$el
        .getBoundingClientRect()
        .left;
    },
    getLetterTransformXValue(letter) {
      const margin = 30;
      const xVal = this.getItemGroupWidth()
        - this.getLetterOffsetLeft(letter) + margin;

      const xValMax = this.$refs.brandSelectorItemGroup.scrollWidth * -1
          + window.outerWidth - margin;

      return max([xVal, xValMax]);
    },
    animateToX(xVal) {
      const tl = gsap.timeline();
      tl.to(this.$refs.brandSelectorItemGroup, {
        x: xVal,
        duration: 2,
      });
    },
    animateToLetter(letter) {
      const xVal = this.getLetterTransformXValue(letter);
      this.animateToX(xVal);
    },
  },
};
</script>

<style lang="scss">
.brand-selector {
  &__item-group {
    display: flex;
    padding: 0px 30px;
  }

  &__selectable-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }

  &__brand {
    height: 60px;
    display: flex;
    margin-bottom: 24px;

    &--all {
      &::before {
        display: block;
        content: '';
        background-image: linear-gradient(to bottom, #F3F3F3 50%, #F8E71C 50%);
        width: 50%;
        height: 100%;
      }
      &::after {
        display: block;
        content: '';
        background-image: linear-gradient(to bottom, #50A1FF 50%, #E20074 50%);
        width: 50%;
        height: 100%;
      }
    }
  }

  &__brand-name {
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    display: block;
  }
}
 </style>
