<template>
  <div class="category-subcategories-page">
    <Header />
    <StaticShelf :name="category" />
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/components/header';
import Footer from '@/components/footer';
import StaticShelf from '@/components/static-shelf';
import actions from '@/store/actions';

export default {
  name: 'CategorySubcategoriesPage',
  components: {
    Header,
    Footer,
    StaticShelf,
  },
  computed: {
    ...mapState({ categoryHierarchy: (state) => state.shelves.categoryHierarchy }),
    category() {
      return this.$route.params.topcategory;
    },
    breadcrumbItems() {
      const items = [{ label: 'Home', path: '/start' }];
      if (!this.categoryHierarchy) return [];
      const item = this.categoryHierarchy.find((mainCategory) => mainCategory.link === this.$route.fullPath);
      items.push({ label: item.name, path: item.link });

      return items;
    },
  },
  mounted() {
    if (this.breadcrumbItems.length > 0) {
      this.$store.dispatch(actions.UPDATE_BREADCRUMB, this.breadcrumbItems);
    }
  },
};
</script>
