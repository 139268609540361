import axios from 'axios';
import { generateItemsOnShelfFromJson } from '@/lib/shelves-helper';
import fetchCategoryOffers from '@/lib/goliath/categories';

function generateCategoryNames(json) {
  const names = {};
  json.start.items.forEach((topItem) => {
    if (!topItem.type === 'category') return;
    names[topItem.slug] = topItem.label;
  });
  Object.keys(json).forEach((topCategoryName) => {
    const topCategory = json[topCategoryName];
    const { slugPrefix } = topCategory;
    if (!topCategory.items) return;
    topCategory.items.forEach((secondLevelCategory) => {
      names[`${slugPrefix}${secondLevelCategory.slug}`] = secondLevelCategory.label;
    });
  });
  return names;
}

/* TODO rename and maybe move to category story? */
function generateCategoryHierarchy(namedShelves) {
  function pullChildrenFrom(topLevelCategory) {
    const slug = topLevelCategory.link.replace(/.*\//, '');
    const secondLevelCategory = namedShelves[slug];
    if (!secondLevelCategory) return undefined;
    return secondLevelCategory.items.map((item) => ({ name: item.label, link: item.link, slug }));
  }
  return namedShelves.start.items
    .filter((i) => i.component === 'ClayOnShelf' && i.link)
    .map((topLevelCategory) => {
      const children = pullChildrenFrom(topLevelCategory);
      const slug = topLevelCategory.link.replace(/.*\//, '');
      return {
        name: topLevelCategory.label,
        link: topLevelCategory.link,
        slug,
        children,
      };
    });
}

async function getGoliathChildCategories() {
  const goliathOffersData = await fetchCategoryOffers({
    filter: {
      withAvailableProducts: true,
    },
  });
  return goliathOffersData.entries.map((entry) => ({
    name: entry.productCategory.name,
    path: entry.productCategory.path,
    slug: entry.productCategory.slug,
    children: entry.children.map(
      (child) => child.productCategory.slug,
    ),
  }));
}

function getShelfContentTree(shelfContents, goliathCategories) {
  const shelfContentsWithGoliathChildCategories = {};
  Object.keys(shelfContents.data).forEach((key) => {
    if (key === '$schema' || key === 'start') return;
    const goliathCategory = goliathCategories.find((category) => category.slug === key);
    if (!goliathCategory) return;
    const shelfContentCategoriesExistingInGoliath = shelfContents.data[key].items
      .filter((item) => goliathCategory.children.includes(item.slug));

    shelfContentsWithGoliathChildCategories[key] = {
      ...shelfContents.data[key],
      items: [...shelfContentCategoriesExistingInGoliath],
    };
  });
  return {
    ...shelfContents.data,
    ...shelfContentsWithGoliathChildCategories,
  };
}

export default {
  namespaced: true,
  state() {
    return {
      initialized: false,
      rawJson: undefined,
      categoryNames: undefined,
      namedShelves: undefined,
      categoryHierarchy: undefined,
      offersItem: undefined,
      noveltyItem: undefined,
    };
  },
  actions: {
    async loadFromBackend({ commit }) {
      try {
        const response = await axios.get('/api/content-json/virtual-shelf/shelf-contents');
        const goliathChildCategories = await getGoliathChildCategories();
        const shelfContentTree = getShelfContentTree(response, goliathChildCategories);
        commit('SET_RAW_JSON', shelfContentTree);
      } catch (exception) {
        console.error('FAILED TO LOAD SHELF CONTENT', exception);
        throw exception;
      }
    },
  },
  mutations: {
    SET_RAW_JSON(state, rawJson) {
      state.rawJson = rawJson;
      state.categoryNames = generateCategoryNames(rawJson);
      state.namedShelves = generateItemsOnShelfFromJson(rawJson);
      state.offersItem = state.namedShelves.start.items.find((item) => item.label === 'Angebote');
      state.noveltyItem = state.namedShelves.start.items.find((item) => item.label === 'Neuheiten');
      state.categoryHierarchy = generateCategoryHierarchy(state.namedShelves);
      state.initialized = true;
    },
  },
  getters: {
    byName(state) {
      return (shelfName) => {
        if (!state.initialized) return [];
        return (state.namedShelves[shelfName]);
      };
    },
  },
};
