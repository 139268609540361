<template>
  <ul
    slot="content"
    class="bullet-point-list"
    :class="{ 'bullet-point-list--bundle': isBundle }"
  >
    <li
      v-for="(item, index) in list.map(createProcessedData)"
      :key="index"
      class="bullet-point-list__item"
      :style="item.styles"
    >
      <slot :item="item">
        {{ item.text }}
      </slot>
    </li>
  </ul>
</template>

<script>
const MAX_CHARACTERS_PER_COLUMN = 300;
const COLUMN_INNERWIDTH = 420;
const COLUMN_GAP = 30;
const COLUMN_TOTAL_WIDTH = COLUMN_INNERWIDTH + COLUMN_GAP;

export default {
  name: 'BulletPointList',
  props: {
    list: {
      type: Array,
      required: true,
    },
    isBundle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    createProcessedData(item) {
      const columnCount = Math.ceil(item.text.length / MAX_CHARACTERS_PER_COLUMN);
      let styles = {};

      if (columnCount > 1) {
        styles = {
          columnCount,
          width: `${columnCount * COLUMN_TOTAL_WIDTH}px`,
          maxWidth: `${columnCount * COLUMN_TOTAL_WIDTH}px`,
          columnGap: `${COLUMN_GAP}px`,
          columnWidth: `${COLUMN_INNERWIDTH}px`,
        };
      }
      return {
        ...item,
        styles,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/shelf.scss";
@import "@/assets/styles/_font-sizes.scss";

.bullet-point-list {
  writing-mode: vertical-lr;
  height: $shelf-content-height;
  margin-top: $shelf-margin-v;
  margin-left: 20px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  &--bundle {
    margin-left: -30px;
    height: $shelf-content-height - paragraph-height(1);
  }

  &__item {
    @include font-size($shelf-font-size, $shelf-line-height);
    letter-spacing: 0.5px;
    margin-bottom: $shelf-margin-v;
    margin-left: 30px;
    max-width: 450px;
    padding-left: 30px;
    position: relative;
    writing-mode: horizontal-tb;

    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 15px;
      width: 15px;
      height: 1px;
      background-color: #000000;
    }
  }
}
</style>
