<template>
  <div class="screensaver-category-tile">
    <DeinDesignLogo
      v-if="isDeinDesign"
      class="screensaver-category-tile__dein-design-logo"
      fill="#fff"
    />
    <Typography
      v-else
      class="screensaver-category-tile__label screensaver-category-tile__label--top"
      variant="hint"
    >
      {{ label }}
    </Typography>

    <GoliathImage
      :alt="label"
      :path="imageSrc"
      class="screensaver-category-tile__image"
    />
    <router-link
      :to="path"
      class="screensaver-category-tile__link"
    />
  </div>
</template>

<script>
import GoliathImage from '@/components/goliath-image';
import Typography from '@/components/typography';
import DeinDesignLogo from '@/assets/images/deindesign-logo.svg';

export default {
  name: 'ScreensaverCategoryTile',
  components: {
    DeinDesignLogo,
    GoliathImage,
    Typography,
  },
  props: {
    // `/offer/${this.slug}`
    path: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    isDeinDesign: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.screensaver-category-tile {
  position: relative;

  a, a:visited {
    text-decoration: none;
    color: transparent;
  }

  &__dein-design-logo {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 174px;
    height: 29px;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    a, a:visited {
      text-decoration: none;
      color: transparent;
    }
  }

  &__image {
    width: 460px;
  }

  &__label {
    font-weight: 700;
    color: #FFFFFF;
    font-size: 31px;
    font-weight: 900;
    letter-spacing: 0.31px;
    line-height: 36px;

    &--top {
      position: absolute;
      top: 16px;
      left: 16px;
      text-transform: uppercase;
    }
  }
}
</style>
