<template>
  <div
    class="checkbox"
    :class="containerClass"
  />
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  computed: {
    containerClass() {
      return { 'checkbox--checked': this.internalValue };
    },
  },
  watch: {
    value: {
      handler(value) {
        this.internalValue = value;
      },
    },
  },
};
</script>

<style lang="scss">
  .checkbox {
    height: 45px;
    width: 45px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.36);
    margin-right: 24px;

    &--checked {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "";
        transform: translate(3px, 3px) rotate(45deg);
        display: block;
        width: 12px;
        height: 4px;
        background-color: #e20074;
      }

      &::after {
        content: "";
        transform: translateX(-3px) rotate(-45deg);
        display: block;
        width: 24px;
        height: 4px;
        background-color: #e20074;
      }
    }
  }
</style>
