<template>
  <div class="video-preloader">
    <!-- not used -->
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex';

export default {
  name: 'VideoPreloader',
  props: {
    preloadableVideos: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.preloadVideos();
  },
  methods: {
    ...mapMutations({
      addVideo: 'preloadedVideos/ADD_VIDEO',
    }),
    preloadVideos() {
      Object
        .keys(this.preloadableVideos)
        .forEach((videoKey) => {
          let blobUrl;
          axios({
            url: this.preloadableVideos[videoKey],
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            blobUrl = window.URL.createObjectURL(new Blob([response.data]));
            this.addVideo({
              key: videoKey,
              url: blobUrl,
            });
          });
        });
    },
  },
};
</script>
