export default {
  separators: {
    path: '/',
    item: '+',
    filter: '_',
  },
  prefixes: {
    filter: '_',
    page: 'seite-',
  },
  filter: {
    positions: ['brandSlugs', 'colorSlugs', 'compatibleBrandSlugs', 'compatibleProductFamilySlugs', 'productTagSlugs'],
    placeholders: {
      brandSlugs: 'alle-hersteller',
      colorSlugs: 'alle-farben',
      compatibleBrandSlugs: 'alle-hersteller',
      compatibleProductFamilySlugs: 'alle-geraete',
      productTagSlugs: 'alle-produkte',
    },
    prefixes: {
      compatibleBrandSlugs: 'fuer-',
      compatibleProductFamilySlugs: 'fuer-',
    },
  },
  sortMap: {
    recommendation: { field: 'RECOMMENDATION', direction: 'ASC' },
    relevance: { field: 'RELEVANCE', direction: 'ASC' },
    priceAsc: { field: 'ONETIME_PRICE', direction: 'ASC' },
    priceDesc: { field: 'ONETIME_PRICE', direction: 'DESC' },
    new: { field: 'INSERTED_AT', direction: 'DESC' },
  },
  defaultSort: 'recommendation',
  defaultPageSize: 30,
};
