import { offerHasUsps } from '@/lib/goliath/offer-usps';
import { transformOfferAlternatives } from '@/lib/transform-offer-alternatives';
import features from '@/lib/features';

function containedProducts(offer) {
  if (offer.product.bundle) {
    return offer.product.bundledProducts;
  }
  return [offer.product];
}

function hasDeliveredItems(offer) {
  return containedProducts(offer).some(
    (p) => p.deliveredItems && p.deliveredItems.length,
  );
}

function hasCompatibilities(offer) {
  return containedProducts(offer).some(
    (p) => p.family
      && p.family.compatibleFamilies
      && p.family.compatibleFamilies.length,
  );
}

function hasFeatures(offer) {
  return containedProducts(offer).some((p) => p.features && p.features.length);
}

export default function generateShelfentries(offer) {
  const productProperties = [];
  const offerAlternatives = transformOfferAlternatives(offer);
  if (offerAlternatives.length > 0) {
    if (offer.product.bundle && features.newBundles.enabled) {
      productProperties.push({
        title: 'Bestandteile',
        key: 'bundle',
        component: 'VariantBundleContent',
        item: {
          bundleItems: offerAlternatives,
          slug: offer.product.slug,
        },
      });
    } else {
      offerAlternatives[0].properties.forEach((property, index) => {
        // Show Variant Selection only when more than one option.
        if (property.variants.length <= 1) {
          return;
        }
        productProperties.push({
          title: property.displayName || '',
          key: `bundle-${index}`,
          component: 'SingleItemConfigurator',
          item: {
            variants: property.variants,
            type: property.type,
          },
        });
      });
    }
  }

  if (offerHasUsps(offer)) {
    productProperties.push({
      title: 'Highlights',
      key: 'highlights',
      component: 'OfferHighlights',
    });
  }
  if (hasDeliveredItems(offer)) {
    productProperties.push({
      title: 'Lieferumfang',
      key: 'delivery',
      component: 'OfferDelivery',
    });
  }
  if (hasCompatibilities(offer)) {
    productProperties.push({
      title: 'Kompatibilität',
      key: 'compatibility',
      component: 'OfferCompatibility',
    });
  }
  if (hasFeatures(offer)) {
    productProperties.push({
      title: 'Technische Details',
      key: 'technicalDetails',
      component: 'OfferTechnicalDetails',
    });
  }
  return productProperties;
}
