<template>
  <div class="transfer-page">
    <div
      class="transfer-page__logo"
    >
      <DeinDesignLogo
        width="451"
        height="75"
      />
    </div>
    <div
      v-if="!isInitialized"
      class="page-grid"
    >
      <Typography
        variant="flow-text"
        class="transfer-page__loading"
      >
        {{ loadingText }}
      </Typography>
    </div>
    <div
      v-if="isInitialized && !isCartTransmitted"
      class="page-grid"
    >
      <Typography
        variant="heading-large"
        class="transfer-page__heading"
      >
        So geht's weiter
      </Typography>
      <div class="transfer-page__orderstep">
        <div class="transfer-page__orderstep-number">
          <Typography variant="order-number">
            01
          </Typography>
        </div>
        <div class="transfer-page__orderstep-description">
          <Typography
            variant="flow-text"
          >
            QR-Code mit dem Smartphone scannen
          </Typography>
        </div>
      </div>
      <div class="transfer-page__orderstep">
        <div class="transfer-page__orderstep-number">
          <Typography variant="order-number">
            02
          </Typography>
        </div>
        <div class="transfer-page__orderstep-description">
          <Typography
            variant="flow-text"
          >
            Im Konfigurator die Hülle gestalten
          </Typography>
        </div>
      </div>
      <div class="transfer-page__orderstep">
        <div class="transfer-page__orderstep-number">
          <Typography variant="order-number">
            03
          </Typography>
        </div>
        <div class="transfer-page__orderstep-description">
          <Typography
            variant="flow-text"
          >
            Bequem hier im Shop bezahlen
          </Typography>
        </div>
      </div>

      <div class="transfer-page__orderstep">
        <div
          class="transfer-page__orderstep-visual"
        >
          <div
            v-if="transferUrl"
            class="transfer-page__qr-code-container"
          >
            <VueQrcode
              :value="transferUrl"
              :options="qrOptions"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isCartTransmitted"
      class="page-grid"
    >
      <Typography
        variant="heading-large"
        class="transfer-page__heading transfer-page__heading--transferred"
      >
        Viel Freude<br>beim Gestalten!
      </Typography>
      <div
        class="transfer-page__transferred"
      >
        <img
          class="transfer-page__transferred-image"
          src="/api/content-images/virtual-shelf/3f90e8d15d0c2aefc015922590ff9fd0/qr-code-transferred.jpg"
        >
        <br>
        <router-link
          to="/screensaver"
        >
          <CommonButton
            class="transfer-page__button"
          >
            Zum Startscreen
          </CommonButton>
        </router-link>
      </div>
    </div>

    <Footer
      v-if="!isCartTransmitted"
      :has-meta-links="false"
    />
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import Footer from '@/components/footer';
import Typography from '@/components/typography';
import { get } from 'lodash';
import ShopApi from '@/lib/shop-api';
import axios from 'axios';
import actions from '@/store/actions';
import CommonButton from '@/components/common-button';
import DeinDesignLogo from '@/assets/images/deindesign-logo.svg';

export default {
  name: 'TransferPage',
  components: {
    CommonButton,
    Typography,
    Footer,
    VueQrcode,
    DeinDesignLogo,
  },
  data() {
    return {
      isCartTransmitted: false,
      isInitialized: false,
      qrOptions: { width: 369, margin: 0, errorCorrectionLevel: 'H' },
      orderKey: null,
      loadingText: 'Bitte warten Sie, wir erzeugen Ihren QR-Code.',
      timeout: null,
    };
  },
  computed: {
    manufacturerProductCode() {
      return get(this.$route, 'query.manufacturer-product-code') || null;
    },
    articleNumber() {
      return get(this.$route, 'query.article-number') || null;
    },
    transferUrl() {
      if (!(this.articleNumber && this.manufacturerProductCode && this.orderKey)) {
        return null;
      }
      const params = [
        `product_code=${this.manufacturerProductCode}`,
        `order_key=${this.orderKey}`,
      ];

      return `${window.location.origin}/deindesign_redirect?${params.join('&')}`;
    },
  },
  async beforeMount() {
    if (!this.manufacturerProductCode || this.manufacturerProductCode === 'null' || !this.articleNumber || this.articleNumber === 'null') {
      this.loadingText = 'Hoppla! Da ist etwas schiefgelaufen. Bitte versuchen Sie es noch einmal.';
      return;
    }
    const { data } = await ShopApi.prepareTransit({ quantity: 1, articleNumber: this.articleNumber });
    if (data.order.orderKey) {
      this.orderKey = data.order.orderKey;
      this.isInitialized = true;

      this.timeout = setTimeout(this.pollCart, 3000);
    } else {
      this.loadingText = 'Hoppla! Da ist etwas schiefgelaufen. Bitte versuchen Sie es noch einmal.';
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  methods: {
    async pollCart() {
      try {
        const { data } = await axios.get('/api/shop/order');
        if (data.order.orderKey === this.orderKey) {
          this.timeout = setTimeout(this.pollCart, 3000);
          return;
        }

        this.handleCodeScan();
      } catch (error) {
        if (error?.response?.status === 405) {
          this.handleCodeScan();
          return;
        }
        console.error(error);
      }
    },
    handleCodeScan() {
      this.isCartTransmitted = true;
      this.$store.dispatch(actions.RESET_CART);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";
.transfer-page {
  &__logo {
    margin: 180px 30px 0;
  }

  &__orderstep {
    grid-column: 1 / span 8;
    grid-column-gap: 20px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    margin-bottom: 31px;
  }

  &__heading {
    grid-column: 1/span 8;
    margin: 21px 0 90px;
    color: #000;
  }

  &__orderstep-number {
    display: flex;
    height: 50px;
    align-items: baseline;
    padding-top: 3px;

    &::before {
      content: " ";
      width: 60px;
      border-bottom: 4px solid #e20074;
      margin-right: 10px;
    }
  }

  &__orderstep-description {
    grid-column: 2 / span 7;
  }

  &__orderstep-visual {
    grid-column: 1 / span 8;
    text-align: center;
    margin-top: 116px;
    margin-bottom: 74px;
  }

  &__qr-code-container {
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    padding: 47px;
    border: 2px solid #000;
    z-index: 0;

    &::before {
      position: absolute;
      content: " ";
      top: -5px;
      left: calc(50%);
      transform: translate(-50%, 0);
      height: calc(100% + 10px);
      width: calc(100% - 110px);
      background-color: #fff;
      z-index: -1;
    }

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: -5px;
      transform: translate(0%, -50%);
      height: calc(100% - 110px);
      width: calc(100% + 10px);
      background-color: #fff;
      z-index: -1;
    }
  }

  &__loading {
    grid-column: 2/span 6;
    margin-top: 75px;
  }

  &__transferred {
    grid-column: 2/span 6;
    text-align: center;
    margin-bottom: 127px;
  }

  &__transferred-image {
    width: 100%;
  }

  &__button {
    margin-top: 50px;
  }
}
</style>
