<template>
  <div
    class="counter-badge"
  >
    <span class="counter-badge__info">
      {{ count }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CounterBadge',
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";
$magenta: #E20074;

.counter-badge {
  flex-shrink: 0;
  margin-left: 10px;
  background-color: $magenta;
  border: 1px solid $magenta;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__info {
    color: #FFFFFF;
    @include font-size(30px, 34px);
    font-weight: 700;
  }
}
</style>
