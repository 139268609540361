<template>
  <div
    class="variant-color-info"
  >
    <span
      v-if="!isColorful"
      class="variant-color-info__color"
      :style="colorStyle"
    />
    <span
      v-else
      class="variant-color-info__color variant-color-info__color--colorful"
    />
  </div>
</template>

<script>

export default {
  name: 'VariantColorInfo',
  props: {
    colorCode: {
      type: String,
      required: true,
    },
    isColorful: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorStyle() {
      return { backgroundColor: this.colorCode };
    },
  },
};
</script>

<style lang="scss">

.variant-color-info {
  border-radius: 1.5px;
  margin-right: 12px;
  margin-bottom: 20px;
  overflow: auto;

  &__color {
    display: block;
    width: 38px;
    height: 9px;

    &--colorful {
      background-image: linear-gradient(90deg, #FF8900 0%, #E20074 42.62%, #C31D8B 60.79%, #5484DE 100%);
    }
  }
}
</style>
