<template>
  <div
    class="loading-spinner-inline"
    :class="{ 'loading-spinner-inline--active': showSpinner }"
  >
    <span class="loading-spinner-inline__icon" />
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showSpinner() {
      return this.isLoading;
    },
  },
};

</script>

<style lang="scss">
@import "@/assets/styles/style";

.loading-spinner-inline {
  $spinner-width: 50px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  overflow: hidden;
  transition: opacity .1s ease-in-out;
  z-index: 1;
  opacity: 0;
  pointer-events: none;

  &--active {
    opacity: 1;
    pointer-events: auto;
  }

  &__icon {
    display: block;
    position: absolute;
    height: $spinner-width;
    width: $spinner-width;
    top: calc(50% - #{$spinner-width} / 2);
    left: calc(50% - #{$spinner-width} / 2);

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: $spinner-width;
      height: $spinner-width;
      border-radius: 50%;
      border: solid 6px transparent;
    }

    &:before {
      border-color: #d7d7d7;
    }
    &:after {
      border-top-color: color(magenta, 1000);
      animation: loading 1.2s infinite linear;
    }
  }

  @keyframes loading {
    100% {
      transform: rotate(360deg) translateZ(0);
    }
  }
}
</style>
