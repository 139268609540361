<template>
  <div
    ref="animation"
    class="jacket-animation"
  />
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import lottie from 'lottie-web';
import Animation from './jacket-animation-data.json';

export default {
  name: 'JacketAnimation',
  props: {
    scrollContainer: {
      type: String,
      default: '',
    },
  },
  computed: {
    animationElement() {
      return this.$refs.animation;
    },
  },
  mounted() {
    this.createAnimation();
  },
  methods: {
    createAnimation() {
      const anim = lottie.loadAnimation({
        container: this.animationElement,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: Animation,
      });
      gsap.registerPlugin(ScrollTrigger);
      ScrollTrigger.create({
        trigger: this.animationElement,
        scroller: this.scrollContainer ? this.scrollContainer : document,
        horizontal: true,
        scrub: true,
        start: 'center right+=10%',
        end: 'center left-=10%',
        onUpdate: (self) => {
          anim.goToAndStop(self.progress * (anim.totalFrames - 1), true);
        },
      });
    },
  },
};
</script>

<style lang="scss">
.jacket-animation {
  height: 650px;
  margin-top: 50px;
}
</style>
