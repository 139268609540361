import { GoliathClient } from './goliath-client';
import categories from './queries/categories.graphql';

export default async function fetchOffers(params = {}) {
  const response = await GoliathClient.query({
    query: categories,
    variables: { ...params },
  });

  return response.data.productCategories;
}
