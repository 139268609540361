import { render, staticRenderFns } from "./router-tab.vue?vue&type=template&id=5e434568&"
import script from "./router-tab.vue?vue&type=script&lang=js&"
export * from "./router-tab.vue?vue&type=script&lang=js&"
import style0 from "./router-tab.vue?vue&type=style&index=0&id=5e434568&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports