import Vue from 'vue';
import Router from 'vue-router';

import { get } from 'lodash';
import CartPage from './views/cart-page';
import CartPaypalPage from './views/cart-paypal-page';
import CartSelectPaymentPage from './views/cart-select-payment-page';
import CartTransitPage from './views/cart-transit-page';
import CategoryOfferListPage from './views/category-offer-list-page';
import CategorySubcategoriesPage from './views/category-subcategories-page';
import CollectionMen from './views/heatable-jacket/collection-men';
import CollectionWomen from './views/heatable-jacket/collection-women';
import DeinDesignStartPage from './views/dein-design/dein-design-start-page';
import Error404Page from './views/error-404-page';
import ErrorPage from './views/error-page';
import HeatableJacketPage from './views/heatable-jacket/heatable-jacket-page';
import HelpPage from './views/help-page';
import SearchPage from './views/search-page';
import SearchOffersPage from './views/search-offers-page';
import OfferPage from './views/offer-page';
import OfferConfiguratorPage from './views/offer-configurator-page';
import ScreensaverPage from './views/screensaver-page';
import SpaBootingPage from './views/spa-booting-page';
import StartPage from './views/start-page';
import SelectDevicePage from './views/dein-design/select-device-page';
import SelectCasePage from './views/dein-design/select-case-page';
import SelectDefaultPage from './views/dein-design/select-default-page';
import SelectMotifDefaultPage from './views/dein-design/select-motif-default-page';
import SelectMotifTypePage from './views/dein-design/select-motif-type-page';
import SelectMotifRecommendationPage from './views/dein-design/select-motif-recommendation-page';
import SelectMotifCategoryPage from './views/dein-design/select-motif-category-page';
import SelectMotifSearchPage from './views/dein-design/select-motif-search-page';
import TransferPage from './views/dein-design/transfer-page';
import { exitIfIsDeeplink } from './lib/exit-if-is-deeplink';
import { resetCart } from './lib/reset-cart';

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      // This page will be display during startup, for example
      // while the SPA caches the 3d images
      path: '/',
      name: 'spa-booting',
      component: SpaBootingPage,
    },
    {
      // activated per timeout, serves as attractor that draws
      // the customer to the virtual shelf.
      path: '/screensaver',
      name: 'screensaver',
      component: ScreensaverPage,
      beforeEnter: (to, from, next) => {
        resetCart();
        exitIfIsDeeplink(next);
        // close open modal - not nice, but it works through `clickaway` mixin.
        document.querySelector('body').click();
        next();
      },
    },
    {
      // the start page of the VR, shows the main categories
      // and the list of rebated offers
      path: '/start',
      name: 'start',
      component: StartPage,
    },
    {
      // some top level categories have sub categories,
      // for these this url shows the them on a shelf
      path: '/sub-categories/:topcategory',
      name: 'subcategories',
      component: CategorySubcategoriesPage,
    },
    {
      // shows the filtered offers of this category as
      // products on a shelf
      // @config+ for serializer/parser filterparams
      path: '/categories/:config+',
      name: 'category-offers',
      component: CategoryOfferListPage,
    },
    {
      // shows a single offer (-> product) in configure view (only for bundles)
      path: '/offer/:slug/configure',
      name: 'variants',
      component: OfferConfiguratorPage,
    },
    {
      // shows a single offer (-> product)
      path: '/offer/:slug',
      name: 'offer',
      component: OfferPage,
    },
    {
      // shows the list of offers that the customer has put
      // into the cart
      path: '/cart',
      name: 'cart',
      component: CartPage,
    },
    {
      // since the customer cant pay directly on the shelf
      // she has to select wether to pay on the local cashier
      // or via paypal
      path: '/cart/select-payment',
      name: 'cart-select-payment',
      component: CartSelectPaymentPage,
    },
    {
      // The customer choose to pay at the local cashier
      // so the cart is transitet to the hardwaretool
      // where a local clerk can pick it up using a code word
      path: '/cart/transit',
      name: 'cart-transit',
      component: CartTransitPage,
    },
    {
      // the customer choose paypal to pay her cqrt and
      // is shown a QR code that opens Paypal on her mobile
      path: '/cart/paypal',
      name: 'cart-paypal',
      component: CartPaypalPage,
    },
    {
      // shows a search and the list of categories
      path: '/search',
      name: 'search',
      component: SearchPage,
    },
    {
      // shows the result of a search.
      // Possible parameters:
      //   * term , sets searchType to query
      //   * searchType, either query or named
      //   # :config*, 0-n query parameter for filter options
      path: '/search/offers/:config*',
      name: 'search-offers',
      component: SearchOffersPage,
    },
    {
      // shows a some FAQs
      path: '/help',
      name: 'help',
      component: HelpPage,
    },
    {
      // DeinDesign startpage
      path: '/dein-design/dein-design-start-page',
      name: 'dein-design-dein-design-start-page',
      component: DeinDesignStartPage,
    },
    {
      path: '/this-page-should-not-be-accessed',
      component: SelectDefaultPage,
      children: [
        {
          path: '/dein-design/select-device-page',
          name: 'dein-design-select-device-page',
          component: SelectDevicePage,
        },
        {
          path: '/dein-design/select-case-page',
          name: 'dein-design-select-case-page',
          component: SelectCasePage,
        },
        {
          path: '/dein-design/select-motif-type-page',
          name: 'dein-design-select-motif-type-page',
          component: SelectMotifTypePage,
        },
        {
          path: '',
          name: 'dein-design-select-motif-default-page',
          component: SelectMotifDefaultPage,
          children: [
            {
              path: '/dein-design/select-motif-recommendation-page',
              name: 'dein-design-select-motif-recommendation-page',
              component: SelectMotifRecommendationPage,
            },
            {
              path: '/dein-design/select-motif-category-page',
              name: 'dein-design-select-motif-category-page',
              component: SelectMotifCategoryPage,
            },
            {
              path: '/dein-design/select-motif-search-page',
              name: 'dein-design-select-motif-search-page',
              component: SelectMotifSearchPage,
            },
          ],
        },
      ],
    },
    {
      path: '/dein-design/transfer-page',
      name: 'dein-design-transfer-page',
      component: TransferPage,
    },
    {
      path: '/heatable-jacket',
      name: 'heatable-jacket-page',
      component: HeatableJacketPage,
    },
    {
      path: '/heatable-jacket/collection-men',
      name: 'heatable-jacket-collection-men',
      component: CollectionMen,
    },
    {
      path: '/heatable-jacket/collection-women',
      name: 'heatable-jacket-collection-women',
      component: CollectionWomen,
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage,
    },
    {
      // all other paths are invalid
      path: '*',
      component: Error404Page,
    },
  ],
});

const hasPathAuthentication = (path) => get(path, 'query.vp');
const isSessionAuthenitcated = () => window.localStorage.getItem('vp');

export const isAuthenticated = (path) => isSessionAuthenitcated() || hasPathAuthentication(path);

router.beforeEach((to, from, next) => {
  const isNavigationToErrorPage = to.path === '/error';

  if (isNavigationToErrorPage || isAuthenticated(to)) {
    next();
  } else if (from.path !== '/error') { // if we are already on the error page, do not execute navigation.
    next('/error');
  }
});

export default router;
