<template>
  <div
    v-if="product.bundle"
    class="product-content-details product-content-details--bundle"
  >
    <div
      v-for="(bundledProduct, key) in groupedBundledProducts"
      :key="key"
      ref="sections"
      class="product-content-details__section"
    >
      <h5 class="product-content-details__headline">
        {{ bundledFamilyNameWithCount(bundledProduct.family.name) }}
      </h5>
      <slot :product="bundledProduct" />
    </div>
  </div>
  <div
    v-else-if="visibleProduct"
    class="product-content-details product-content-details--standard"
  >
    <slot :product="visibleProduct" />
  </div>
</template>

<script>
import { countBy, uniqBy } from 'lodash';

export default {
  name: 'ProductContentDetails',
  props: {
    product: {
      type: Object,
      required: true,
    },
    renderItemIf: {
      type: Function,
      default: () => () => true,
    },
  },
  computed: {
    visibleProduct() {
      if (this.product.bundle) return null;
      if (!this.renderItemIf(this.product)) return null;
      return this.product;
    },
    visibleBundledProducts() {
      if (!this.product.bundle || !this.product.bundledProducts) return [];
      return this.product.bundledProducts.filter((p) => this.renderItemIf(p));
    },
    groupedBundledProducts() {
      return uniqBy(this.visibleBundledProducts, (p) => p.family.name);
    },
    bundledProductFamilyCounts() {
      return countBy(this.visibleBundledProducts, (p) => p.family.name);
    },
  },
  methods: {
    bundledFamilyNameWithCount(familyName) {
      const count = this.bundledProductFamilyCounts[familyName] || 0;
      if (count <= 1) return familyName;
      return `${count} × ${familyName}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/shelf.scss";
@import "@/assets/styles/_font-sizes.scss";

.product-content-details {
  &--bundle {
    margin-top: $shelf-margin-v;
    margin-left: 0;
    display: flex;
    flex-wrap: nowrap;
  }

  &__section {
    padding-left: 50px;
  }

  &__headline {
    @include font-size($shelf-font-size, $shelf-line-height);
    margin: 0 0 $shelf-margin-v;
    white-space: nowrap;
  }
}
</style>
