import _ from 'lodash';

export const MESSAGES = {
  LOADING_PRODUCTS: 'Wir laden Ihre Produkte ...',
  LOADING_FILTERS: 'Wir laden Ihre Filter ...',
};

export default {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      visibleMessage: undefined,
      messages: [],
    };
  },
  mutations: {
    PUSH_MESSAGE(state, newMessage) {
      state.messages = _.uniq([...state.messages, newMessage]);
      state.isLoading = true;
      state.visibleMessage = state.messages[0]; // eslint-disable-line prefer-destructuring
    },
    REMOVE_MESSAGE(state, oldMessage) {
      state.messages = state.messages.filter((message) => message !== oldMessage);
      state.isLoading = state.messages.length > 0;
      state.visibleMessage = state.isLoading ? state.messages[0] : undefined;
    },
    CLEAR_ALL_MESSAGES(state) {
      state.messages = [];
      state.isLoading = false;
      state.visibleMessage = undefined;
    },
  },
  actions: {
    showMessage(context, message) {
      context.commit('PUSH_MESSAGE', message);
    },
    removeMessage(context, message) {
      context.commit('REMOVE_MESSAGE', message);
    },
  },
};
