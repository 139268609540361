import DeinDesignQueryParams from '@/mixins/dein-design-query-params';
import mergeVariables from '@/lib/filter-params/merge-variables';

function DeinDesignOffers(fetchDeinDesignOffers) {
  return {
    mixins: [
      DeinDesignQueryParams,
    ],
    methods: {
      getFilterParams() {
        const filterParams = {};
        if (this.productStyleSlug) {
          filterParams.productStyleSlugs = [this.productStyleSlug];
        }
        if (this.productTagSlug) {
          filterParams.productTagSlugs = [this.productTagSlug];
        }
        if (this.compatibleBrandSlug) {
          filterParams.compatibleBrandSlugs = [this.compatibleBrandSlug];
        }
        if (this.compatibleProductFamilySlug) {
          filterParams.compatibleProductFamilySlugs = [this.compatibleProductFamilySlug];
        }
        return filterParams;
      },
      getOfferQueryParams(params) {
        return mergeVariables(
          {
            filter: {
              productCategoryPaths: ['deindesign'],
              listed: true,
              productAvailable: true,
              types: [
                'TANGIBLE',
              ],
              ...this.getFilterParams(),
            },
          },
          params,
        );
      },
      async getOffers(params, query) {
        const currentQuery = query || fetchDeinDesignOffers;
        const response = await currentQuery(
          this.getOfferQueryParams(params),
        );
        return response;
      },
    },
  };
}

export default DeinDesignOffers;
