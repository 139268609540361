import { get } from 'lodash';
import { replacePath } from '@/lib/attachment-route';
import { GoliathClient } from './goliath-client';
import OfferBySlugs from './queries/offer-by-slugs.graphql';

function maybeSetProxiedImageUrl(rawResponse) {
  const response = rawResponse;
  const labelImageUrl = get(response, 'data.offerBySlugs.product.energyEfficiency.labelImage.url');
  if (labelImageUrl) {
    response.data.offerBySlugs.product.energyEfficiency.labelImage.url = replacePath(labelImageUrl);
  }
  return response;
}

async function fetchOfferBySlugs({ productSlug, contractSlug }) {
  const rawResponse = await GoliathClient.query({
    query: OfferBySlugs,
    variables: {
      productSlug,
      contractSlug,
    },
  });

  const response = maybeSetProxiedImageUrl(rawResponse);

  return response.data.offerBySlugs;
}

export {
  fetchOfferBySlugs,
};
