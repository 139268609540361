import { get } from 'lodash';

export default {
  computed: {
    productStyleSlug() {
      return get(this.$route, 'query.productStyleSlug');
    },
    productTagSlug() {
      return get(this.$route, 'query.productTagSlug');
    },
    compatibleBrandSlug() {
      return get(this.$route, 'query.compatibleBrandSlug');
    },
    compatibleProductFamilySlug() {
      return get(this.$route, 'query.compatibleProductFamilySlug');
    },
  },
  methods: {
    getQueryParams() {
      return {
        productStyleSlug: this.productStyleSlug,
        productTagSlug: this.productTagSlug,
        compatibleBrandSlug: this.compatibleBrandSlug,
        compatibleProductFamilySlug: this.compatibleProductFamilySlug,
      };
    },
  },
};
