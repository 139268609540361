<template>
  <div />
</template>

<script>
export default {
  name: 'SpaBootingPage',
  mounted() {
    this.$router.push({ name: 'start', query: this.$route.query });
  },
  methods: {
  },
};
</script>
