import { get } from 'lodash';

export default {
  methods: {
    async getAsync(item, waitFor = 2000) {
      if (get(this, item) !== undefined) return get(this, item);

      let unwatch;
      let timeout;
      const promise = new Promise((resolve, reject) => {
        unwatch = this.$watch(item, (value) => {
          if (undefined !== value) resolve(value);
        });

        if (waitFor > 0) {
          timeout = setTimeout(() => reject(new Error(`Timeout error while waiting for "${item}"`)), waitFor);
        }
      });

      promise.finally(unwatch);
      if (waitFor > 0) promise.then(() => clearTimeout(timeout));

      return promise;
    },
  },
};
