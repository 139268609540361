import { gsap } from 'gsap';
import { Draggable } from 'gsap/all';

gsap.registerPlugin(Draggable);

const PADDING = 30;
const MINIMUM_DRAG_MOVEMENT = 10;

function DraggableMixin(draggableItemRefKey) {
  return {
    data() {
      return {
        draggableInstance: null,
        initialAnimationDone: false,
        boundsPadding: PADDING,
        minimumDragMovement: MINIMUM_DRAG_MOVEMENT,
      };
    },
    props: {
      active: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      this.initFilter();
    },
    beforeDestroy() {
      if (this.draggableInstance && this.draggableInstance.length) {
        this.draggableInstance[0].kill();
      }
    },
    methods: {
      updateDraggableContent() {
        if (!this.draggableInstance || this.draggableInstance.length === 0) return;
        const tl = gsap.timeline();
        tl.to(this.$refs[draggableItemRefKey], {
          x: 0,
          duration: 0,
        });
        setTimeout(() => {
          this.setBounds();
        }, 100);
      },
      setBounds() {
        const draggableItem = this.$refs[draggableItemRefKey];
        let maxX = (draggableItem.scrollWidth * -1)
        + window.outerWidth;
        if (draggableItem.scrollWidth > window.outerWidth) {
          maxX -= this.boundsPadding;
        }
        this.draggableInstance[0].applyBounds({
          minX: 0,
          maxX,
        });
      },
      initFilter() {
        const draggableItem = this.$refs[draggableItemRefKey];
        if (!draggableItem) return;
        this.draggableInstance = Draggable
          .create(draggableItem, {
            type: 'x',
            edgeResistance: 0.7,
            onPress: this.setBounds,
            bounds: {
              minX: 0,
              maxX: (draggableItem.scrollWidth * -1)
              + window.outerWidth - this.boundsPadding,
            },
            inertia: true,
            zIndexBoost: false,
            minimumMovement: this.minimumDragMovement,
          });
      },
      initIntroSlide() {
        const tl = gsap.timeline();
        const draggableItem = this.$refs[draggableItemRefKey];
        tl.from(draggableItem, {
          x: draggableItem.clientWidth,
          opacity: 0,
          duration: 2,
        });
      },
    },
  };
}

export default DraggableMixin;
