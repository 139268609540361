<template>
  <router-link
    class="unknown-on-shelf"
    to="link"
  >
    <div
      ref="unknownOnShelfImage"
      class="unknown-on-shelf__image"
      :style="imageStyle"
    />
    <Typography
      class="unknown-on-shelf__label"
      variant="title-magenta"
      v-html="label"
    />
  </router-link>
</template>

<script>
import Typography from './typography';

export default {
  name: 'UnknownOnShelf',
  components: {
    Typography,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    animate: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: `url(${this.imageSrc})`,
      };
    },
  },
};
</script>

<style lang="scss">
.unknown-on-shelf {
  height: 100%;
  align-items: center;
  display: flex;

  &__image {
    background-position: center center;
    background-size: auto 100%;
    display: block;
    height: 208px;
    width: 208px;
  }

  &__label {
    max-width: 300px;
    margin-left: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
