import Vue from 'vue';
import Vuex from 'vuex';
import breadcrumb from './breadcrumb';
import cart from './cart';
import promotions from './promotions';
import shelves from './shelves';
import settings from './settings';
import loader from './loader';
import cancelOverlaySwitch from './cancel-overlay-switch';
import timeout from './timeout';
import session from './session';
import preloadedVideos from './preloaded-videos';
import progressBar from './progress-bar';
import downtimes from './downtimes';

Vue.use(Vuex);
export function storeModules() {
  return {
    breadcrumb,
    cart,
    loader,
    promotions,
    shelves,
    settings,
    cancelOverlaySwitch,
    timeout,
    session,
    preloadedVideos,
    progressBar,
    downtimes,
  };
}

let store;

export default function getStore() {
  if (!store) {
    store = new Vuex.Store({
      modules: storeModules(),
    });
  }
  return store;
}
