<template>
  <div
    :class="selectableItemClass"
    class="selectable-item"
    @click="selectItem"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SelectableItem',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  computed: {
    selectableItemClass() {
      return {
        'selectable-item--selected': this.internalValue,
      };
    },
  },
  watch: {
    value(value) {
      this.internalValue = value;
    },
  },
  methods: {
    selectItem() {
      this.$emit('input', !this.internalValue);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.selectable-item {
  position: relative;
  flex-shrink: 0;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.29);
  background-color: #ffffff;
  margin-right: 36px;
  padding: 12px 24px;

  &.selectable-item--selected {
    font-weight: 700;
    color: #e20074;
    background-color: #FBFBFB;
    box-shadow: inset 0 0 13px 0 rgba(0, 0, 0, 0.72);

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background-color: #e20074;
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }
}
</style>
