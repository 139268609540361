<template>
  <div
    ref="productDesignTagSelector"
    class="product-design-tag-selector"
  >
    <SelectableItemGroup
      class="product-design-tag-selector__item-group"
      :list="selectableTagsFiltered"
      :active="isVisible"
      :value="selectedTags"
      @input="selectTag"
    >
      <template #default="scope">
        <GoliathImage
          v-if="scope.entry.image"
          class="product-design-tag-selector__image"
          :path="scope.entry.image.path"
          :alt="scope.entry.label"
        />
        <span class="product-design-tag-selector__product-design-tag-name">
          {{ scope.entry.label }}
        </span>
      </template>
    </SelectableItemGroup>
  </div>
</template>

<script>
import GoliathImage from '@/components/goliath-image';
import SelectableItemGroup from '@/components/selectable-item-group';

export default {
  name: 'ProductDesignTagSelector',
  components: {
    SelectableItemGroup,
    GoliathImage,
  },
  props: {
    selectableProductDesignTags: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedProductDesignTagSlug: this.value,
    };
  },
  computed: {
    selectableTagsFiltered() {
      return this.selectableProductDesignTags.map((entry) => ({
        label: entry.tag.name,
        value: entry.tag.slug,
        image: entry.tag.image,
      }));
    },
    selectedTags() {
      return [this.value];
    },
    isVisible() {
      return this.selectableTagsFiltered.length > 0;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedProductDesignTagSlug = value;
      },
    },
  },
  methods: {
    selectTag(slugs) {
      this.$emit('input', slugs[0]);
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.product-design-tag-selector {
  .selectable-item-group__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 230px;
    width: 190px;
    padding: 20px;
  }

  &__image {
    flex-grow: 0;
  }

  &__product-design-tag-name {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    text-transform: uppercase;
    display: block;
    margin-top: auto;
    font-size: 20px;
    line-height: 23px;
  }
}
 </style>
