<template>
  <div
    class="please-wait-modal"
    :class="containerClasses"
  >
    <div class="please-wait-modal__container">
      <LoadingSpinner class="please-wait-modal__spinner" />
      <Typography
        variant="title-bold"
        class="please-wait-modal__text"
      >
        {{ message }}
      </Typography>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Typography from '@/components/typography';
import LoadingSpinner from '@/components/loading-spinner';

const MINIMAL_DISPLAY_DURATION = 1.2 * 1000;

export default {
  name: 'PleaseWaitModal',
  components: {
    LoadingSpinner,
    Typography,
  },
  data() {
    return {
      visible: false,
      message: '',
      showingSince: undefined,
      dontHideBefore: undefined,
    };
  },
  computed: {
    containerClasses() {
      return {
        'please-wait-modal--visible': !!this.visible,
      };
    },
    isLoading() {
      return this.$store.state.loader.isLoading;
    },
    ...mapState('loader', ['visibleMessage']),
  },
  watch: {
    isLoading(newState) {
      if (newState) {
        this.showingSince = Date.now();
        this.dontHideBefore = this.showingSince + MINIMAL_DISPLAY_DURATION;
        this.visible = true;
      } else {
        const durationLeft = this.dontHideBefore - Date.now();
        // Hide Animation after 1200 ms or when it was not shown for 2 ms.
        if (durationLeft <= 0 || (Date.now() - this.showingSince) <= 2) {
          this.visible = false;
        } else {
          setTimeout(() => {
            this.visible = false;
          }, durationLeft);
        }
      }
    },
    visibleMessage(newMessage) {
      if (!newMessage) return;
      this.message = newMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.please-wait-modal {
  position: absolute;
  left: 0;
  top: 0;
  height: 1920px;
  width: 1080px;
  z-index: 1000;
  overflow: hidden;
  background-color: transparent;
  transform: translateX(100%);
  transition: background-color 0.5s ease-out 2ms;
  will-change: background-color;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    display: grid;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 2ms ease-out 2ms;
    will-change: opacity;
  }

  &__spinner,
  &__text {
    grid-row: 1;
    grid-column: 1;
  }

  &__text {
    z-index: 1;
    text-align: center;
    color: #fff;
  }

  &--visible {
    background-color: rgba(255, 255, 255, 0.8);
    transform: translateX(0);

    .please-wait-modal {
      &__container {
        opacity: 1;
      }
    }
  }
}
</style>
