<template>
  <RouterLink
    class="navigation-cart-button"
    to="/cart"
  >
    <span
      :class="getWrapperClass()"
    >
      <CartIcon class="navigation-cart-button__icon" />
      <span class="navigation-cart-button__count">
        {{ itemCount }}
      </span>
    </span>
  </RouterLink>
</template>

<script>
import CartIcon from '@/assets/images/cart-icon.svg';

export default {
  name: 'NavigationCartButton',
  components: { CartIcon },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
  },
  computed: {
    itemCount() {
      return this.$store.getters['cart/totalCount'];
    },
  },
  methods: {
    getWrapperClass() {
      const baseClass = 'navigation-cart-button__wrapper';
      const activeClass = this.isActive ? `${baseClass}--active` : null;
      const variantClass = this.variant
        ? `${baseClass}--${this.variant}-variant`
        : null;
      const wrapperClasses = [baseClass, variantClass, activeClass];
      return wrapperClasses.filter((elem) => elem !== null).join(' ');
    },
  },
};
</script>

<style lang="scss">
.navigation-cart-button {
  color: #000;
  height: 120px;
  padding: 39px 30px 45px;

  &:active,
  &:hover,
  &:focus,
  &:visited {
    color: #000;
  }

  &__wrapper {
    --borderWidth: 2px;
    display: flex;
    border-color: currentColor;
    border-style: solid;
    border-width: 0 0 var(--borderWidth) 0;
    color: #000;
    fill: currentColor;
    width: 80px;
    height: 36px;

    &--footer-variant {
      border-width: var(--borderWidth) 0 0 0;
      padding-top: 14px;
      margin-top: -2px;
    }

    &--active {
      --borderWidth: 4px; // overwrites the var in __wrapper
      color: #e20074;
      padding-bottom: 2px;
    }
  }

  &__icon {
    width: 30px;
  }

  &__count {
    height: 20px;
    width: 50px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: right;
  }
}
</style>
