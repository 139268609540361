import SettingsStore from '@/store/settings';

/**
 * Guard for usage in `route.beforeEnter`
 *
 * Prevents navigation if virtual shelf is in deeplink mode
 *
 * @export
 * @param {function} next `next` param of `route.beforeEnter` hook
 * @returns {boolean} true if `next` hook was used
 */
export function exitIfIsDeeplink(next) {
  const isDeeplink = SettingsStore.state.deeplink;
  if (isDeeplink) {
    window.parent.postMessage('closeVirtualShelf', '*'); // should close Iframe if implemented in parent frame
    next(false);
    return true;
  }
  return false;
}
