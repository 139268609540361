<template>
  <div class="dropdown">
    <ul class="dropdown__list">
      <DropdownItem
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
        :left="option.left"
        :transition-delay="option.transitionDelay"
        :selected="isSelected(option.value)"
        :all-visible="showAllOptions"
        :is-visible="isVisible(option.value)"
        @item-selected="handleSelect"
      >
        {{ option.label }}
      </DropdownItem>
    </ul>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import DropdownItem from '@/components/dropdown-item';

export default {
  name: 'Dropdown',
  components: {
    DropdownItem,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => {},
    },
    showAllOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: this.value,
    };
  },
  computed: {
    selection() {
      return this.options.find((option) => this.isSelected(option.value));
    },
  },
  methods: {
    handleSelect(value) {
      if (this.selectedValue !== value) {
        this.updateSelection(value);
      } else {
        this.$emit('nochange');
      }
    },
    updateSelection(value) {
      this.selectedValue = value;
      this.$emit('change', this.selection);
    },
    isSelected(itemValue) {
      if (isEqual(this.selectedValue, {})) return false;
      return isEqual(this.selectedValue, itemValue);
    },
    isVisible(itemValue) {
      return this.isSelected(itemValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  display: block;
  position: relative;

  &__list {
    left: 0;
    list-style: none;
    position: absolute;
    top: 0;
    user-select: none;
  }
}
</style>
