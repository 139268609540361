<template>
  <div class="price-range">
    <span
      v-if="!equal"
      class="price-range__prefix"
    >ab</span>
    <price
      :amount="amount"
      currency="EUR"
      class="price-range__price"
    />
  </div>
</template>

<script>
export default {
  name: 'PriceRange',
  components: {
    Price: () => import('@/components/price'),
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    equal: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.price-range {
  color: #e20074;
  @include font-size(30px, 25px);
  font-weight: 400;
}
</style>
