// @ts-check
import { groupBy } from 'lodash';
import { attachmentThumbUrl } from './attachment-route';
import PROPERTY_KEY_MAP from './property-key-map';

const DETAIL_VIEW_PATH = '/offer/';

const mapName = (productAlternative, type) => {
  const { scopedProduct } = productAlternative.offer;
  const fallbackName = scopedProduct.variantName || scopedProduct.name || '';
  switch (type) {
    case 'SIZE':
      return scopedProduct.size || fallbackName;
    case 'COLOR':
      return scopedProduct.color?.name || fallbackName;
    case 'STORAGE':
      return scopedProduct.storage
        ? `${scopedProduct.storage.value} ${scopedProduct.storage.unit}`
        : fallbackName;
    default:
      return scopedProduct.variantName;
  }
};

/**
 * maps a `productAlternative` from offer request to needed values
 * @param {import('./goliath-offer-query.product-alternatives').ProductAlternative} productAlternative
 * @param {string} type PROPERTY_KEY_MAP
 */
const mapProductAlternative = (productAlternative, type) => ({
  ...productAlternative,
  name: mapName(productAlternative, type),
  active: productAlternative.active,
  path: DETAIL_VIEW_PATH + productAlternative.offer.product.slug,
  color: productAlternative.offer.scopedProduct.color,
});

const sortVariants = (variants, type) => {
  if (type === 'SIZE') {
    const sortingArray = ['size xs', 'size s', 'size m', 'size l', 'size xl', 'size xxl'];
    variants.sort((a, b) => sortingArray.indexOf(a.name?.toLowerCase()) - sortingArray.indexOf(b.name?.toLowerCase()));
    return variants;
  }
  return variants;
};

/**
 * maps a `ProductAlternativeSelectorGroup` from offer request to needed values
 * @param {import('./goliath-offer-query.product-alternatives').ProductAlternativeSelectorGroup} selectorGroup
 */
const mapSelectorGroup = (selectorGroup) => ({
  type: selectorGroup.key,
  displayName: PROPERTY_KEY_MAP[selectorGroup.key],
  variants: sortVariants(
    selectorGroup.alternatives.map((alternative) => mapProductAlternative(alternative, selectorGroup.key)),
    selectorGroup.key,
  ),
});

// eslint-disable-next-line no-unused-vars
const createAdditionalGroupForTesting = (selectorGroup) => ({
  type: 'STORAGE',
  displayName: PROPERTY_KEY_MAP.STORAGE,
  variants: selectorGroup.alternatives.map((alternative) => mapProductAlternative(alternative, 'STORAGE')),
});

/**
 * determine if a bundleItem is configurable by looking for non active alternatives
 * @param {import('./goliath-offer-query.product-alternatives').ProductAlternativeSelectorGroup[]} properties
 */
const isConfigurable = (properties) => properties
  .flatMap((p) => p.alternatives)
  .filter((a) => !a.active).length > 0;

/**
 * returns the name and the cdn-ified URL to the thumbImage of the a active variant
 *
 * @param {import('./goliath-offer-query.product-alternatives').ProductAlternativeSelectorGroup[]} properties
 */
const getThumbImage = (properties) => {
  if (properties.length === 0) return {};
  const imgObj = properties.flatMap((p) => p.alternatives).find((a) => a.active).offer.scopedProduct
    .teaserImage;
  return {
    name: imgObj.name,
    thumbImageUrl: attachmentThumbUrl(imgObj),
  };
};

/**
 * @param {import('./transform-offer-alternatives').Properties[]} properties
 * @returns the variantNames of the active variant e.g `['schwarz','256 GB']`
 */
const getActiveVariants = (properties) => properties
  .flatMap((p) => p.variants)
  .filter((a) => a.active)
  .map((a) => a.name);

/**
 * maps each bundleItem to needed values for component
 *
 * @param {import('./goliath-offer-query.product-alternatives').GoliathProductAlternative} bundleItem
 */
const mapToReducedBundleItems = (bundleItem) => {
  const properties = [
    ...bundleItem.properties.map(mapSelectorGroup),
    // ...bundleItem.properties.map(createAdditionalGroupForTesting),
  ];
  return {
    family: bundleItem.family,
    position: bundleItem.position,
    isConfigurable: isConfigurable(bundleItem.properties),
    activeVariants: getActiveVariants(properties),
    teaserImage: getThumbImage(bundleItem.properties),
    properties,
  };
};

/**
 * generate a name for merged bundleItems
 *
 * @param {string} name
 * @param {number} count
 */
const itemNameWithCount = (name, count) => (count > 1 ? `${count} x ${name}` : name);

/**
 * merges duplicate non-configurable bundleItems into a single item
 *
 * @param {import('./transform-offer-alternatives').reducedAlternatives[]} items
 */
const maybeMergeBundleItems = (items) => {
  const mergeableItems = items.filter((item) => !item.isConfigurable);
  const nonMergeableItems = items.filter((item) => item.isConfigurable);
  const groups = groupBy(mergeableItems, 'family.name');
  const groupsArray = Object.keys(groups).map((g) => groups[g]);
  const mergedItems = groupsArray.map((group) => ({
    ...group[0],
    isMerged: group.length > 1,
    family: {
      ...group[0].family,
      name: itemNameWithCount(group[0].family.name, group.length),
    },
  }));
  return [...mergedItems, ...nonMergeableItems];
};

/**
 * Takes a Goliath Offer and transforms the productAlternatives into alternativeBundleItems for the OfferConfigurator component
 *
 * @param {import('./transform-offer-alternatives').Params } params
 */
/* eslint-disable import/prefer-default-export */
export const transformOfferAlternatives = ({ productAlternatives: bundleItems }) => {
  const items = bundleItems.map(mapToReducedBundleItems);
  return maybeMergeBundleItems(items);
};
