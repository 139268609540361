import axios from 'axios';

const rebates = {
  rebates: null,
  rebateArticleNumbers: null,

  async attachRebateTo(offer) {
    return (await this.hasRebateFor(offer))
      ? { ...offer, rebate: await this.findRebateFor(offer) }
      : offer;
  },

  async attachRebatesTo(offers) {
    return Array.isArray(offers)
      ? Promise.all(offers.map(this.attachRebateTo.bind(this)))
      : {
        ...offers,
        entries: (await this.attachRebatesTo(offers.entries)),
      };
  },

  async getRebates() {
    if (this.rebates == null) {
      this.rebates = this.loadRebates();
    }

    return this.rebates;
  },

  async loadRebates() {
    try {
      return (await axios.get('/api/rebates')).data;
    } catch (e) {
      return [];
    }
  },

  async getRebateArticleNumbers() {
    if (this.rebateArticleNumbers == null) {
      this.rebateArticleNumbers = (await this.getRebates())
        .flatMap((entry) => entry.applicableProductNumbers);
    }
    return this.rebateArticleNumbers;
  },

  async hasRebateFor(offer) {
    return (await this.getRebateArticleNumbers())
      .includes(offer.product.articleNumber);
  },

  async findRebateFor(offer) {
    return (await this.getRebates()).find(
      (entry) => entry.applicableProductNumbers
        .includes(offer.product.articleNumber),
    );
  },
};

const Rebates = {
  get() {
    const priv = { ...rebates };
    return {
      getAll: priv.getRebates.bind(rebates),
      getArticleNumbers: priv.getRebateArticleNumbers.bind(rebates),
      attachRebateTo: priv.attachRebateTo.bind(rebates),
      attachRebatesTo: priv.attachRebatesTo.bind(rebates),
    };
  },
};

export default Rebates;
