export default {
  namespaced: true,
  state() {
    return {
      videos: [],
    };
  },
  getters: {
    getVideoByKey: (state) => (key) => state.videos.find((v) => v.key === key),
  },
  mutations: {
    ADD_VIDEO(state, video) {
      const persisted = state.videos.find((v) => v.key === video.key);
      if (persisted) {
        persisted.url = video.url;
      } else {
        state.videos.push(video);
      }
    },
  },
};
