<template>
  <div class="select-motif-recommendation-page">
    <ProductDesignTagSelector
      v-model="selectedProductDesignTagSlug"
      class="select-motif-recommendation-page__selector"
      :active="hasEntries"
      :selectable-product-design-tags="selectableProductDesignTags"
      @change="onProductDesignTagChange"
    />
    <InfiniteShelf
      class="select-motif-recommendation-page__shelf"
      :offer-query="fetchOffersForMotif"
      :offer-query-params="offerQueryParams"
      :number-of-boards="1"
      tile-size="l"
      @updated-page="updatePage"
      @fetched-offers="fetchedOffers"
    />
    <LoadingSpinnerInline
      :is-loading="isLoading"
      class="select-motif-recommendation-page__loading_indicator"
    />
  </div>
</template>

<script>
import DeinDesignOffers from '@/mixins/dein-design-offers';
import ProductDesignTagSelector from '@/components/product-design-tag-selector';
import InfiniteShelf from '@/components/infinite-shelf';
import fetchOffersForMotif from '@/lib/goliath/dein-design-offers-for-motif';
import { get, isEmpty } from 'lodash';
import LoadingSpinnerInline from '@/components/loading-spinner-inline';

export default {
  name: 'SelectMotifRecommendationPage',
  components: {
    LoadingSpinnerInline,
    ProductDesignTagSelector,
    InfiniteShelf,
  },
  mixins: [
    DeinDesignOffers(fetchOffersForMotif),
  ],
  data() {
    return {
      selectedProductDesignTagSlug: '',
      selectableProductDesignTags: [],
      initialPage: 1,
      isLoading: true,
    };
  },
  computed: {
    hasEntries() {
      return !isEmpty(this.selectableProductDesignTags);
    },
    offerQueryParams() {
      let filter = {
        productDesignTagSlugs: [this.selectedProductDesignTagSlug],
      };
      if (!this.selectedProductDesignTagSlug) filter = {};
      return this.getOfferQueryParams({
        filter,
        pagination: {
          page: this.initialPage,
          size: 30,
        },
        grouped: false,
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.initialPage = this.$route.query.page || 1;
  },
  methods: {
    fetchOffersForMotif,
    onProductDesignTagChange() {
      this.initialPage = 1;
      this.isLoading = true;
    },
    updatePage(page) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
    fetchedOffers(offers) {
      this.isLoading = false;
      if (this.selectableProductDesignTags.length === 0) {
        this.selectableProductDesignTags = get(offers, 'aggregations.productDesignTags', []);
      }
    },
  },
};
</script>

<style lang="scss">
.select-motif-recommendation-page {
  position: relative;
  &__shelf {
    margin: 140px 30px 0;
  }
  &__loading_indicator {
    height: 965px;
    z-index: 2;
    background: rgba(255,255,255,0.5);
  }
}
</style>
