import Vue from 'vue';
import AsyncComputed from 'vue-async-computed';
import PortalVue from 'portal-vue';
import * as Sentry from '@sentry/vue';

import App from './app';
import router from './router';
import getStore from './store';

const SentryConfig = require('./server/config/sentry');

Vue.use(AsyncComputed);
Vue.use(PortalVue);

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  ...SentryConfig.options,
  enabled: !SentryConfig.disabled,
  dsn: SentryConfig.fakeDsn,
  tunnel: `${SentryConfig.localPath}/envelope/`,
});

new Vue({
  router,
  store: getStore(),
  render: (h) => h(App),
}).$mount('#app');
