<template>
  <div class="variant-selector">
    <div
      v-if="isColorSelector && !hideTitle"
      class="variant-selector__name"
    >
      {{ activeVariantName }}
    </div>

    <VariantSelectorColor
      v-if="isColorSelector"
      :variants="variants"
      :horizontal="horizontal"
      v-on="$listeners"
    />
    <VariantSelectorBasic
      v-else
      :variants="variants"
      :horizontal="horizontal"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import VariantSelectorBasic from './variant-selector-basic';
import VariantSelectorColor from './variant-selector-color';

export default {
  name: 'VariantSelector',
  components: { VariantSelectorColor, VariantSelectorBasic },
  props: {
    type: { type: String, default: () => 'TEXT' },
    variants: { type: Array, default: () => [] },
    hideTitle: { type: Boolean },
    horizontal: { type: Boolean },
  },
  computed: {
    isColorSelector() {
      return this.type === 'COLOR';
    },
    activeVariantName() {
      return this.variants.find((v) => v.active).name;
    },
  },
};
</script>

<style lang="scss" scoped>
.variant-selector {
  &__name {
    font-size: 25px;
    letter-spacing: 0.5px;
    line-height: 30px;
    margin-bottom: 18px;
  }
}
</style>
