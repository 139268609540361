<template>
  <ul class="search-suggestions">
    <li
      v-for="suggestion in suggestions"
      :key="suggestion.slug"
      class="search-suggestions__item"
    >
      <router-link
        :to="suggestion.slug"
        class="search-suggestions__link"
        v-html="suggestion.html"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SearchSuggestions',
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.search-suggestions {
  height: 340px;
  list-style-type: none;
  margin: 0 0 60px;
  overflow: hidden;

  &__item {
    @include font-size(25px, 45px);
    font-weight: 400;
    margin: 0 0 12px;

    b {
      font-weight: 700;
    }
  }

  &__link,
  &__link:hover,
  &__link:focus,
  &__link:active,
  &__link:visited {
    color: #000;
  }
}
</style>
