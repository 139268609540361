<template>
  <div
    class="single-item-configurator"
  >
    <VariantSelector
      :type="type"
      :variants="variants"
      @selectVariant="selectVariant"
    />
  </div>
</template>

<script>
import VariantSelector from './offer-configurator/variant-selector';

export default {
  name: 'SingleItemConfigurator',
  components: { VariantSelector },
  props: {
    variants: { type: Array, default: () => [] },
    type: { type: String, default: 'TEXT' },
  },
  methods: {
    selectVariant({ variant }) {
      // catch to avoid NavigationDuplicated error
      this.$router.replace({ path: variant.path }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/shelf.scss";

.single-item-configurator {
  margin-top: 20px;
  margin-right: 40px;
  padding-left: 50px;
  height: $shelf-content-height;
  min-width: 285px;

  :deep(.variant-selector-color .variant-selector-basic) {
    grid-template-rows: repeat(3, 54px);
  }
}
</style>
