<template>
  <div class="video-element">
    <video
      ref="video"
      class="video-element__video"
      muted
      :loop="loop"
    >
      <source
        :src="src"
        :type="type"
      >
    </video>
    <router-link
      v-if="path"
      ref="link"
      :to="path"
      class="video-element__link"
    />
  </div>
</template>

<script>
import mimeTypes from '@/constants/mime-types.json';

export default {
  name: 'VideoElement',
  props: {
    src: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'video/webm',
      validator: (value) => mimeTypes.VALID_VIDEO_TYPES.includes(value),
    },
    path: {
      type: String,
      default: null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isActive(activated) {
      if (activated) {
        this.start();
      } else {
        this.stop();
      }
    },
  },
  mounted() {
    if (this.isActive) {
      this.start();
    }
    this.$refs.video.addEventListener('ended', this.videoEnded);
  },
  methods: {
    start() {
      this.$refs.video.play();
    },
    stop() {
      this.pause();
      this.rewind();
    },
    pause() {
      this.$refs.video.pause();
    },
    rewind() {
      this.$refs.video.currentTime = 0;
    },
    videoEnded() {
      this.$emit('video-ended');
    },
  },
};
</script>

<style lang="scss">
.video-element {
  position: relative;

  &__video {
    width: 100%;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
