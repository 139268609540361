<template>
  <ProductContentDetails
    :product="product"
    :render-item-if="hasCompatibility"
    class="offer-compatibility"
  >
    <template
      slot-scope="props"
    >
      <div
        class="offer-compatibility__categories"
        :class="{'offer-compatibility__categories--bundle': product.bundle}"
      >
        <div
          v-if="hasFamilyCompatibility(props.product)"
          class="offer-compatibility__category"
        >
          <p
            class="offer-compatibility__category-name"
          >
            Zentralen:
          </p>
          <BulletPointList
            :class="{ 'offer-compatibility__list--bundle' : product.bundle }"
            :is-bundle="product.bundle"
            :list="filterCompatibleFamilyModels(props.product)"
            class="offer-compatibility__list"
            itemprop="description"
          />
        </div>
        <div
          v-if="hasAppCompatibility(props.product)"
          class="offer-compatibility__category"
        >
          <p
            class="offer-compatibility__category-name"
          >
            Apps:
          </p>
          <BulletPointList
            :class="{ 'offer-compatibility__list--bundle' : product.bundle }"
            :is-bundle="product.bundle"
            :list="filterCompatibleApps(props.product)"
            class="offer-compatibility__list"
            itemprop="description"
          />
        </div>
      </div>
    </template>
  </ProductContentDetails>
</template>

<script>
import BulletPointList from '@/components/bullet-point-list';
import ProductContentDetails from '@/components/product-detail/product-content-details';

export default {
  name: 'OfferCompatibility',
  components: {
    BulletPointList,
    ProductContentDetails,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      availableAppCompatibilityTags: {
        mzabasic: { label: 'MagentaZuhause App Basic' },
        mzapro: { label: 'MagentaZuhause App Pro' },
        mshfree: { label: 'Magenta SmartHome' },
        mshpro: { label: 'Magenta SmartHome Pro' },
      },
    };
  },
  methods: {
    hasCompatibility(product) {
      return this.hasFamilyCompatibility(product) || this.hasAppCompatibility(product);
    },
    hasFamilyCompatibility(product) {
      return product.family.compatibleFamilies.length > 0;
    },
    hasAppCompatibility(product) {
      return product.tags.length > 0;
    },
    filterCompatibleFamilyModels(product) {
      const data = product.family.compatibleFamilies.map((f) => ({ text: f.model }));
      return data;
    },
    filterCompatibleApps(product) {
      const data = [];
      Object.keys(this.availableAppCompatibilityTags).forEach((tag) => {
        if (this.lowerCaseIncludes(product.tags, tag)) {
          data.push({
            text: this.availableAppCompatibilityTags[tag].label,
          });
        }
      });
      return data;
    },
    lowerCaseIncludes(haystack, needle) {
      return haystack.some((e) => e.toLowerCase() === needle);
    },

  },
};
</script>

<style lang="scss">
@import "@/assets/styles/shelf.scss";

.offer-compatibility {
  &__categories {
    display: flex;
    height: 350px;

    &--bundle {
      height: 280px;

      .offer-compatibility__category-name {
          margin-left: 0;
          margin-top: 0;
        }
    }
  }

  &__category-name {
    margin-top: 20px;
    margin-left: 50px;
    white-space: nowrap;
    font-size: 25px;
    letter-spacing: 0.5px;
  }

  &__compatibility {
    white-space: nowrap;
    font-size: 25px;
    letter-spacing: 0.5px;
  }

  &__list {
    &--bundle {
      height: $shelf-content-height - paragraph-height(2);
    }
  }
}
</style>
