<template>
  <ModalPortal
    ref="modal"
    @close="$emit('close')"
  >
    <pdf
      :page="page"
      :src="path"
      @num-pages="setPages"
      @link-clicked.prevent="linkClicked"
    />
    <div class="pdf-viewer__controls">
      <LinkButton
        :disabled="!hasPrevPage"
        tag="button"
        @click="prevPage"
      >
        Zurück
      </LinkButton>
      <LinkButton
        :disabled="!hasNextPage"
        tag="button"
        @click="nextPage"
      >
        Weiter
      </LinkButton>
    </div>
  </ModalPortal>
</template>

<script>
import pdf from 'vue-pdf';

import { LinkButton } from '@i22-td-smarthome/component-library';

import ModalPortal from '@/components/modal-portal';

export default {
  name: 'PdfViewer',
  components: {
    LinkButton,
    ModalPortal,
    pdf,
  },
  props: {
    path: { type: String, default: '' },
  },
  data() {
    return {
      pdfPath: this.path,
      page: 1,
      numOfPages: this.page,
    };
  },
  computed: {
    hasPrevPage() {
      return this.page > 1;
    },
    hasNextPage() {
      return this.page < this.numOfPages;
    },
  },
  watch: {
    path(newVal) {
      this.pdfPath = newVal;
      this.render();
    },
  },
  async mounted() {
    this.render();
  },
  methods: {
    render() {
      this.numOfPages = 1;
      this.page = 1;
      if (this.path === '') {
        this.$refs.modal.close();
        return;
      }
      this.$refs.modal.open();
    },
    setPages(numPages) {
      if (!numPages) return;
      this.numOfPages = numPages;
    },
    linkClicked() {
    },
    prevPage() {
      if (!this.hasPrevPage) return;
      this.page = Math.max(1, this.page - 1);
    },
    nextPage() {
      if (!this.hasNextPage) return;
      this.page = Math.min(this.numOfPages, this.page + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-viewer__controls {
  display: flex;
  justify-content: space-between;
}
</style>
