<template>
  <div
    ref="compatibleBrandSelector"
    class="compatible-brand-selector"
  >
    <SelectableItemGroup
      class="compatible-brand-selector__item-group"
      :list="selectableBrandsFiltered"
      :active="isVisible"
      :value="selectedSlugs"
      @input="selectBrand"
    >
      <template #default="scope">
        <GoliathImage
          v-if="scope.entry.logo"
          class="compatible-brand-selector__image"
          :path="scope.entry.logo.path"
          :alt="scope.entry.label"
        />
        <span class="compatible-brand-selector__brand-name">
          {{ scope.entry.label }}
        </span>
      </template>
    </SelectableItemGroup>
    <LoadingSpinnerInline
      class="compatible-brand-selector__loading_indicator"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import GoliathImage from '@/components/goliath-image';
import SelectableItemGroup from '@/components/selectable-item-group';
import LoadingSpinnerInline from '@/components/loading-spinner-inline';

export default {
  name: 'CompatibleBrandSelector',
  components: {
    SelectableItemGroup,
    GoliathImage,
    LoadingSpinnerInline,
  },
  props: {
    selectableBrands: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedBrandSlug: this.value,
    };
  },
  computed: {
    selectableBrandsFiltered() {
      return this.selectableBrands.map((entry) => ({
        label: entry.brand.name,
        value: entry.brand.slug,
        logo: entry.brand.logo,
      }));
    },
    selectedSlugs() {
      return [this.value];
    },
    isVisible() {
      return this.selectableBrandsFiltered.length > 0;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedBrandSlug = value;
      },
    },
  },
  methods: {
    selectBrand(slugs) {
      this.$emit('input', slugs[0]);
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss">
.compatible-brand-selector {
  position: relative;

  .selectable-item-group__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 200px;
    width: 200px;
  }
  &__selectable-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 200px - 12px * 2;
    width: 200px - 12px * 4;
  }

  &__image {
    flex-grow: 0;
    height: calc(100% - 48px);
    filter: contrast(0%) brightness(120%);
    object-fit: contain;
  }

  &__brand {
    height: 60px;
    display: flex;
    margin-bottom: 24px;

    &--all {
      &::before {
        display: block;
        content: '';
        background-image: linear-gradient(to bottom, #F3F3F3 50%, #F8E71C 50%);
        width: 50%;
        height: 100%;
      }
      &::after {
        display: block;
        content: '';
        background-image: linear-gradient(to bottom, #50A1FF 50%, #E20074 50%);
        width: 50%;
        height: 100%;
      }
    }
  }

  &__brand-name {
    display: block;
    text-transform: none;
  }

  &__loading_indicator {
    height: 200px;
  }
}
 </style>
