<template>
  <button
    class="common-button"
    type="button"
    :disabled="disabled"
    :class="containerClass"
    @click="$emit('click')"
  >
    <div class="common-button__content">
      <slot />
    </div>
  </button>
</template>

<script>
const VALID_VARIANTS = ['secondary'];

export default {
  name: 'CommonButton',
  props: {
    variant: {
      type: String,
      default: null,
      validator(value) {
        return VALID_VARIANTS.includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    containerClass() {
      const classes = [];
      if (this.variant) classes.push(`common-button--${this.variant}`);
      if (this.disabled) classes.push('common-button--disabled');
      return classes;
    },
  },
};
</script>

<style lang="scss">
.common-button {
  // undo button styles
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  padding: 0;
  outline: inherit;

  text-transform: uppercase;
  display: inline-block;
  padding: 14px 48px;
  color: #fff;
  font-size: 30px;
  line-height: 35px;
  text-align: justify;
  border-radius: 6px;
  background-color: #e20074;

  &__content{
    // needed to center content, because the font leaves a padding on top
    padding-bottom: 5px;
  }

  &--secondary {
    background: #fff;
    border: 2px solid #000000;
    color: #000;
  }

  &--disabled {
    color: #b8b8b8;
    background-color: #ededed;
    border: 1px solid #b8b8b8;
  }
}
</style>
