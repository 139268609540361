const { spawnSync } = require('child_process');
const Mode = require('../mode');

function detectReleaseFromGit() {
  try {
    const gitRevParse = spawnSync('git', ['rev-parse', 'HEAD']);
    const [release] = gitRevParse.stdout.toString().split('\n');
    return release;
  } catch (e) {
    return undefined;
  }
}

function detectRelease() {
  return (
    process.env.VUE_APP_GIT_COMMIT_HASH
    || detectReleaseFromGit()
    || 'fake0hashb2c7d9e6a31f7e4d8e0f6a3c4b1d5f2e8f9a6c0'
  );
}

const config = {
  disabled: Mode.isDevelopment || Mode.isTest,
  host: 'sentry.dev.i22.de',
  projectId: '103',
  publicKey: 'a26dd26eb5f64b7e9b903e91b30eb7ee',
  // Account related Auth Token. Renew when vincent.bosche's account is suspended in sentry. https://sentry.dev.i22.de/settings/account/api/auth-tokens/.
  authToken: 'b427b8d05b374dc28614e8685d1ef476d187dd521ef741cea92d293365b81329',
  localPath: '/api/sentry', // used for the FE-Proxy
  fakeDsn: 'https://publicKey@some-invalid-host.i22hosting.de/0123456789',
  options: {
    release: detectRelease(),
    autoBreadcrumbs: true,
    captureUnhandledRejections: true,
    environment: process.env.NODE_ENV,
  },
};

config.remotePath = `/api/${config.projectId}`;
config.baseUrl = `https://${config.publicKey}@${config.host}`;
config.dsn = new URL(config.projectId, config.baseUrl).toString();

module.exports = config;
