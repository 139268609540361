// When the context is a Browser, use the client sentry, in node use the node version.

const getSentry = async () => {
  if (process.browser) {
    // eslint-disable-next-line no-return-await
    return await import('@sentry/vue');
  }
  // eslint-disable-next-line no-return-await
  return await import('@sentry/node');
};

/**
 * @typedef ScopeContext
 * @type {object}
 * @property {object} user
 * @property {'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'} level
 * @property {Record<string, unknown>} extra
 * @property {Record<string, Record<string, unknown>>} contexts
 * @property {Record<string, Primitive>} tags
 * @property {string[]} fingerprint
 * @property {object} requestSession
 */
/**
 * @typedef {import("@sentry/types").Scope} Scope
 * @typedef { Scope | Partial<ScopeContext> | ((scope: Scope) => Scope) } CaptureContext
 */

/**
 * @typedef Sentry
 * @type {object}
 * @property {function(Error | any, CaptureContext)} captureException
 * @property {function(string | any, CaptureContext)} captureMessage
 */

/**
 * @param {Error | any} error
 * @param {CaptureContext} captureContext
 */
const captureException = (error, captureContext) => {
  /** @property {Sentry} sentry */
  getSentry().then((sentry) => sentry.captureException(error, captureContext));
};

/**
 * @param {Error} error
 * @param {CaptureContext} captureContext
 */
const captureMessage = (error, captureContext) => {
  /** @property {Sentry} sentry */
  getSentry().then((sentry) => sentry.captureMessage(error, captureContext));
};

export {
  captureException,
  captureMessage,
  getSentry,
};
