<template>
  <div class="error-page">
    <Header
      :show-breadcrumb="false"
      :show-cart="false"
    >
      <template #back>
        <BackButton
          class="error-page__back-button"
          path="/start"
        />
      </template>
    </Header>
    <div class="error-page__content">
      <Typography
        variant="heading-large"
        class="error-page__heading"
      >
        Da ist etwas
        <div
          class="error-page__hidden-info"
          @click="hiddenInfoClicked"
        >
          <div v-show="showHiddenInfo">
            VP = {{ currentVp }}
          </div>
        </div>
        <br>
        schief gelaufen
      </Typography>
      <Typography
        variant="subheading"
        class="error-page__subheading"
      >
        Kleinen Moment bitte, unser virtuelles Regal ist gleich wieder für Sie da.
        Bei Fragen wenden Sie sich bitte an einen Verkäufer.
      </Typography>
    </div>
    <Footer
      :has-meta-links="false"
    >
      <template #back>
        <BackButton
          class="error-page__back-button"
          path="/start"
        />
      </template>
    </Footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/components/header';
import Footer from '@/components/footer';
import Typography from '@/components/typography';
import BackButton from '@/components/back-button';

export default {
  name: 'ErrorPage',
  components: {
    BackButton,
    Header,
    Footer,
    Typography,
  },
  data() {
    return {
      clickCounter: 0,
    };
  },
  computed: {
    ...mapState({
      vp: (state) => state.session.vp,
    }),
    showHiddenInfo() {
      return this.clickCounter > 10;
    },
    currentVp() {
      return this.vp || 'unset';
    },
  },
  mounted() {
    this.$store.commit('loader/CLEAR_ALL_MESSAGES');
  },
  methods: {
    hiddenInfoClicked() {
      this.clickCounter += 1;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.error-page {
  background-image: url("../assets/images/error-background.png");
  background-position: 50% top;
  display: block;
  position: relative;
  width: 1080px;
  height: 100%;
  margin: 0 auto;

  &__content {
    padding: 80px 0 0;
  }

  &__heading {
    margin-left: 26px;
    padding-right: 180px;
    grid-column: 1/-1;
  }

  &__subheading {
    font-size: 30px;
    margin-top: 12px;
    margin-left: 26px;
    padding-right: 180px;
  }
  .error-page__hidden-info {
    display: inline-block;
    width: 200px;
    height: 100px;
    background-color: #fff;
    overflow: hidden;
    font-size: 20px;
    color: #888;
  }

  &__back-button {
    height: 120px;
    padding-top: 30px;
  }
}
</style>
