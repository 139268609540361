<template>
  <transition
    name="animation"
  >
    <div
      v-show="isOpened"
      class="filter-modal"
      :class="containerClass"
    >
      <div class="filter-modal__header">
        <CommonButton
          class="filter-modal__close-button"
          @click="close"
        >
          <div class="filter-modal__close-button-arrow" />
        </CommonButton>
      </div>
      <div class="filter-modal__content">
        <Typography
          variant="heading"
          class="filter-modal__heading"
        >
          Filtermöglichkeiten
        </Typography>
        <Typography
          ref="filterModalDescription"
          variant="subheading"
          class="filter-modal__description"
        >
          <span v-if="categoryName">
            Filtern Sie jetzt alle Artikel der Kategorie
            <strong v-html="categoryName" />
            und gelangen Sie noch schneller zu Ihrem Wunschartikel.
          </span>
          <span v-else>
            Filter Sie jetzt Ihr Suchergebnis
          </span>
        </Typography>
        <FilterAccordion
          v-if="variables && aggregations"
          ref="filterAccordion"
          :filter-set-slug="filterSetSlug"
          :aggregations="aggregations"
          :variants-count="variantsCount"
          :variables="variables"
          :is-opened="isOpened"
          @submit="onSubmit"
          @change="onChange"
          @reset="onReset"
        />
      </div>
      <div class="filter-modal__footer">
        <CommonButton
          class="filter-modal__close-button filter-modal__close-button--footer"
          @click="close"
        >
          <div class="filter-modal__close-button-arrow" />
        </CommonButton>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
import { parseFilterParams } from '@/lib/filter-params';
import mergeFilterVariables from '@/lib/filter-params/merge-variables';
import fetchOffersFilterData from '@/lib/goliath/offers-filter-data';
import FilterAccordion from '@/components/filter-accordion';
import Typography from '@/components/typography';
import CommonButton from '@/components/common-button';
import { MESSAGES } from '@/store/loader';

// ONLY Hardware Products (NOT MIXED)
const DISPLAYED_OFFER_TYPES = ['TANGIBLE'];
const MAX_PRICE = 2000;

export default {
  name: 'FilterModal',
  components: {
    FilterAccordion,
    Typography,
    CommonButton,
  },
  props: {
    categoryName: {
      type: String,
      default: null,
    },
    pathPrefix: {
      type: String,
      default: null,
    },
    filterSetSlug: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpened: false,
      aggregations: null,
      variantsCount: 0,
      rawVariables: null,
      maxPrice: MAX_PRICE,
    };
  },
  computed: {
    containerClass() {
      return { 'filter-modal--open': this.isOpened };
    },
    variables() {
      return mergeFilterVariables(
        this.rawVariables,
        {
          grouped: true,
          filter: {
            listed: true,
            types: DISPLAYED_OFFER_TYPES,
            productAvailable: true,
          },
          search: this.getSearchTerm(),
          pathPrefix: this.pathPrefix,
        },
      );
    },
  },
  watch: {
    isOpened(value) {
      if (!value) return;
      if (this.aggregations && Object.keys(this.aggregations).length > 0) return;
      this.setVariablesFromRoute();
      this.getFilterData();
    },
  },
  mounted() {
    this.setVariablesFromRoute();
  },
  methods: {
    ...mapActions('loader', ['removeMessage', 'showMessage']),
    getSearchTerm() {
      if (!this.$route.query.term) return undefined;
      return `${this.$route.query.term}**`;
    },
    sanitizeFilterData(filterData) {
      const sanitizedFilterData = filterData;
      const isPresetPriceRange = sanitizedFilterData.onetimePrice.min === 0
        && sanitizedFilterData.onetimePrice.max === this.maxPrice;
      // for price
      if (isPresetPriceRange) {
        sanitizedFilterData.onetimePrice = {
          min: undefined,
          max: undefined,
        };
      }

      return sanitizedFilterData;
    },
    setVariablesFromRoute() {
      this.rawVariables = parseFilterParams(this.$route);
    },
    async getFilterData() {
      this.showMessage(MESSAGES.LOADING_FILTERS);
      try {
        const requestVariables = JSON.parse(JSON.stringify(this.variables));
        // Aggregations can't have a pagination.
        if (requestVariables.pagination) {
          delete requestVariables.pagination;
        }
        const { aggregations, pagination } = await fetchOffersFilterData(requestVariables);
        this.aggregations = aggregations;
        // no-pagination (load next set of ${pageSize} on scroll) concept

        this.variantsCount = pagination.totalCount;
      } catch (e) {
        // empty
      } finally {
        this.removeMessage(MESSAGES.LOADING_FILTERS);
      }
    },
    setVariablesWithPagination(variables) {
      this.rawVariables = mergeFilterVariables(
        {
          pagination: {
            page: 1,
          },
        },
        variables,
      );
    },
    open() {
      this.isOpened = true;
      this.$emit('open');
    },
    close() {
      this.isOpened = false;
      this.$emit('close');
    },
    onSubmit(data) {
      this.setVariablesWithPagination(data);
      this.$emit('filter-update', this.variables);
      this.close();
    },
    onChange(data) {
      const sanitizedFilterData = this.sanitizeFilterData(data.filter);
      this.setVariablesWithPagination({
        ...data,
        filter: sanitizedFilterData,
      });
      this.getFilterData();
    },
    onReset(data) {
      this.rawVariables.filter = {
        ...data.filter,
        productCategoryPaths: this.variables.filter.productCategoryPaths,
        productAvailable: this.variables.filter.productAvailable,
      };
      this.getFilterData();
    },
  },
};
</script>

<style lang="scss">
.animation-enter-active,
.animation-leave-active {
  transition: all .5s ease-in-out;
}

.animation-enter,
.animation-leave-to {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.filter-modal {
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .5s ease-in-out;

  &__header,
  &__footer {
    display: flex;
    align-items: center;
    height: 120px;
  }

  &__heading {
    padding: 0 24px;
    text-align: left;
    margin-bottom: 12px;
  }

  &__description {
    padding: 0 24px;
    font-size: 30px;
    line-height: 40px;
    text-align: left;
    margin-bottom: 24px;
  }

  &__content {
    min-height: 500px;
  }

  .filter-modal__close-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    transform-origin: center center;

    // undo button styles
    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
    padding: 0 24px;
    outline: inherit;

    &--footer {
      padding-bottom: 30px;
    }

    &-arrow {
      position: relative;
      transition: all 0.2s ease-in-out;
      width: 50px;
      height: 2px;
      background-color: #262626;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &::before {
        position: absolute;
        left: -0px;
        content: "";
        display: block;
        transition: all 0.2s ease-in-out;
        transform-origin: center left;
        transform: rotate(-45deg);
        width: 20px;
        height: 2px;
        background-color: #262626;
      }

      &::after {
        position: absolute;
        left: -0px;
        content: "";
        display: block;
        transition: all 0.2s ease-in-out;
        transform-origin: center left;
        transform: rotate(45deg);
        width: 20px;
        height: 2px;
        background-color: #262626;
      }
    }
  }
}
</style>
