<template>
  <div class="heatable-jacket-offers">
    <Shelf
      :boards="2"
      is-extra-big
      :items="orderedAndFilteredOffers"
      :disable-draggable="disableDraggable"
    />
  </div>
</template>

<script>
import fetchOffers from '@/lib/goliath/offers-heatable-jacket';
import { generateOffersOnShelfFromGoliath } from '@/lib/shelves-helper';
import Shelf from '@/components/shelf';

export default {
  name: 'HeatableJacketOffers',
  components: {
    Shelf,
  },
  props: {
    disableDraggable: {
      type: Boolean,
      default: false,
    },
    showCollectionMen: {
      type: Boolean,
      default: false,
    },
    showCollectionWomen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      offers: null,
    };
  },
  computed: {
    aggregatedOfferData() {
      if (!this.offers) return [];
      return generateOffersOnShelfFromGoliath(this.offers);
    },
    offersMen() {
      return this.aggregatedOfferData.filter((o) => o.label.includes('Men'));
    },
    offersWomen() {
      return this.aggregatedOfferData.filter((o) => o.label.includes('Women'));
    },
    orderedAndFilteredOffers() {
      if (this.showCollectionMen) {
        return this.sortJacketsBeforeVests(this.offersMen);
      }
      if (this.showCollectionWomen) {
        return this.sortJacketsBeforeVests(this.offersWomen);
      }

      return [...this.sortJacketsBeforeVests(this.offersMen), ...this.sortJacketsBeforeVests(this.offersWomen)];
    },
  },
  mounted() {
    this.getOffers();
  },
  methods: {
    sortJacketsBeforeVests(offers) {
      return offers.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA.includes('jacket') && labelB.includes('jacket')) return 0;
        return labelA.includes('jacket') ? -1 : 1;
      });
    },
    async getOffers() {
      this.offers = await fetchOffers({
        grouped: true,
        filter: {
          listed: true,
          brandSlugs: ['alphatauri'],
          colorCodes: ['#00A1DE'], // Show only dark variants in overview.
        },
      });
    },
  },
};
</script>
