import _ from 'lodash';
import regexpEscape from 'escape-string-regexp';
import config from './config';

export default function Parser(route) {
  const helpers = {
    parseInt(value) {
      if (!value) return undefined;
      const num = _.parseInt(value);
      if (_.isNaN(num)) return undefined;
      return num;
    },
    parseNum(value) {
      if (!value) return undefined;
      const num = Number(value);
      if (_.isNaN(num)) return undefined;
      return num;
    },
  };

  const patterns = {
    filter: new RegExp(`^${regexpEscape(config.prefixes.filter)}(.+)$`),
    page: new RegExp(`^${regexpEscape(config.prefixes.page)}(\\d+)$`),
    compatibility: {
      brandSlugs: new RegExp(`^${regexpEscape(config.filter.prefixes.compatibleBrandSlugs)}(.+)$`),
      productFamilySlugs: new RegExp(`^${regexpEscape(config.filter.prefixes.compatibleProductFamilySlugs)}(.+)$`),
    },
  };

  this.route = route || {};
  this.params = this.route.params || {};
  this.query = this.route.query || {};
  this.pathPrefix = this.route.pathPrefix || {};

  if (this.params.config) {
    this.pathSegments = this.params.config.split(config.separators.path);
  } else {
    this.pathSegments = [];
  }

  this.getVars = function getVars() {
    return {
      search: this.getSearchTerm(),
      filter: this.getFilterVars(),
      sort: this.getSortVars(),
      pagination: this.getPaginationVars(),
    };
  };

  // Search

  this.getSearchTerm = function getSearchTerm() {
    const term = _.trim(this.query.search || '');
    if (!term) return undefined;
    return term;
  };

  // Filters

  this.getFilterVars = function getFilterVars() {
    return {
      brandSlugs: this.getBrandSlugs(),
      colorSlugs: this.getColorSlugs(),
      compatibleBrandSlugs: this.getCompatibleBrandSlugs(),
      compatibleProductFamilySlugs: this.getProductFamilySlugs(),
      discounted: this.getDiscountedFilter(),
      onetimePrice: this.getOnetimePriceFilter(),
      productCategoryPaths: this.getProductCategoryPaths(),
      productTagSlugs: this.getProductTagSlugs(),
    };
  };

  this.getProductTagSlugs = function getProductTagSlugs() {
    return this.getFilterValues('productTagSlugs');
  };

  this.getBrandSlugs = function getBrandSlugs() {
    return this.getFilterValues('brandSlugs');
  };

  this.getColorSlugs = function getColorSlugs() {
    return this.getFilterValues('colorSlugs');
  };

  this.getCompatibleBrandSlugs = function getCompatibleBrandSlugs() {
    const compatibilitiesWithPrefix = this.getFilterValue('compatibleBrandSlugs');
    if (!compatibilitiesWithPrefix) return [];
    const match = compatibilitiesWithPrefix.match(patterns.compatibility.brandSlugs);
    if (!match || !match[1]) return [];
    const compatibilities = match[1];
    return compatibilities.split(config.separators.item).sort();
  };

  this.getProductFamilySlugs = function getProductFamilySlugs() {
    const compatibilitiesWithPrefix = this.getFilterValue('compatibleProductFamilySlugs');
    if (!compatibilitiesWithPrefix) return [];
    const match = compatibilitiesWithPrefix.match(patterns.compatibility.productFamilySlugs);
    if (!match || !match[1]) return [];
    const compatibilities = match[1];
    return compatibilities.split(config.separators.item).sort();
  };

  this.getDiscountedFilter = function getDiscountedFilter() {
    switch (this.query.discounted) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return undefined;
    }
  };

  this.getOnetimePriceFilter = function getOnetimePriceFilter() {
    const min = helpers.parseNum(this.query.minPrice);
    const max = helpers.parseNum(this.query.maxPrice);
    const filter = {};
    if (min) filter.min = min;
    if (max) filter.max = max;
    return filter;
  };

  this.getProductCategoryPaths = function getProductCategoryPaths() {
    const pathSegments = this.getCategoryPathSegments();
    if (!pathSegments) return [];

    let paths;

    const categoryPathSegments = pathSegments.map((segment) => segment.split(config.separators.item).sort());

    const parentPathSegments = [];

    for (let i = 0; i < categoryPathSegments.length; i += 1) {
      const isLeafLevel = i === (categoryPathSegments.length - 1);
      const items = categoryPathSegments[i];

      if (isLeafLevel) {
        // When reaching the leaf level, we can put the entire category path
        // together.
        paths = items.map((leafPathSegment) => parentPathSegments
          .concat([leafPathSegment])
          .join(config.separators.path));
      } else {
        if (items.length > 1) {
          // I don't know whether it is completely valid to throw here. Another
          // variant would be to just silently select the first item without
          // throwing. But be aware that not throwing may lead to multiple list
          // pages displaying the exactly same content which is not desirable
          // for SEO.
          throw new Error('Unable to filter by multiple categories on non-leaf level');
        }

        parentPathSegments.push(items[0]);
      }
    }

    return paths;
  };

  // Pagination

  this.getPaginationVars = function getPaginationVars() {
    return {
      page: this.getPage(),
      size: this.getPageSize(),
    };
  };

  this.getPage = function getPage() {
    const segment = this.getPagePathSegment();
    return helpers.parseInt(segment) || 1;
  };

  this.getPageSize = function getPageSize() {
    return helpers.parseInt(this.query.pageSize) || config.defaultPageSize;
  };

  // Sort

  this.getSortVars = function getSortVars() {
    const { sort } = this.query;
    if (!sort) return undefined;
    return config.sortMap[sort];
  };

  // Helpers

  this.getCategoryPathSegments = function getCategoryPathSegments() {
    const categorySegments = [];

    for (let i = 0; i < this.pathSegments.length; i += 1) {
      const segment = this.pathSegments[i];

      if (segment.match(patterns.filter) || segment.match(patterns.page)) {
        break;
      }

      categorySegments.push(segment);
    }

    return categorySegments;
  };

  this.getFilterValue = function getFilterValue(key) {
    const index = config.filter.positions.indexOf(key);

    if (index < 0) {
      throw new Error(`Unknown field: ${key}`);
    }

    const filterSegment = this.getFilterPathSegment();
    if (!filterSegment) return undefined;
    const value = filterSegment.split(config.separators.filter)[index];
    if (!value) return undefined;

    let placeholder = config.filter.placeholders[key];
    const prefix = config.filter.prefixes[key];
    if (prefix) {
      placeholder = `${prefix}${placeholder}`;
    }

    if (value === placeholder) return undefined;
    return value;
  };

  this.getFilterValues = function getFilterValues(key) {
    const value = this.getFilterValue(key);
    if (!value) return [];
    return value.split(config.separators.item).sort();
  };

  this.getFilterPathSegment = function getFilterPathSegment() {
    return this.getPathSegmentWithPattern(patterns.filter);
  };

  this.getPagePathSegment = function getPagePathSegment() {
    return this.getPathSegmentWithPattern(patterns.page);
  };

  this.getPathSegmentWithPattern = function getPathSegmentWithPattern(pattern) {
    let matchingSegment;

    for (let i = 0; i < this.pathSegments.length; i += 1) {
      const segment = this.pathSegments[i];
      const match = segment.match(pattern);

      if (match && match[1]) {
        ([, matchingSegment] = match);
        break;
      }
    }

    return matchingSegment;
  };
}
