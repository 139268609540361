import { GoliathClient } from './goliath-client';
import deinDesignOffersForMotifType from './queries/dein-design-offers-for-motif-type.graphql';

export default async function fetchOffersForMotifType(params = {}) {
  const response = await GoliathClient.query({
    query: deinDesignOffersForMotifType,
    variables: { ...params },
  });

  return response.data.offers;
}
