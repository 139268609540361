/**
 * Downtime Object for specific affected system
 * @typedef {Object} DowntimeObject
 * @property {Date} startDate - downtime start Date
 * @property {Date} endDate - downtime end Date
 * @property {String} maintenanceHeading - heading text for maintenance
 * @property {String} maintenanceDescription - description text for maintenance
 * @property {String} description - system description
 * @property {[String]} systems - list of all affected systems
 */

/**
 * returns true if `downtime` is currently active
 *
 * @export
 * @param {DowntimeObject} downtime Single Downtime Object from the Backend
 */

function isDowntimeActive(downtime) {
  const convertedStartDate = new Date(downtime.startDate);
  const convertedEndDate = new Date(downtime.endDate);
  const timestamp = new Date(downtime.startDate).getTime() / 1000;
  const isDate = timestamp
    && timestamp > 0
    && !Number.isNaN(downtime.startDate);
  if (!isDate) return undefined;

  const dateNow = new Date(Date.now());

  return convertedStartDate < dateNow
        && convertedEndDate > dateNow;
}

/**
 * Finds active downtimes by system
 *
 * @export
 * @param {[DowntimeObject]} downtimes - all downtimes reported by the backend
 * @param {String} system - system to search downtimes in
 * @returns {DowntimeObject | undefined} - DowntownObject if `system` has an active downtime
 */
export function getActiveDowntimeBySystem(downtimes, system) {
  return downtimes.find(
    (dt) => dt.systems.find((s) => s === system)
        && isDowntimeActive(dt),
  );
}
