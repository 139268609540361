<template>
  <div class="price-range-selector">
    <Typography
      class="price-range-selector__price-range-text"
      variant="title-bold"
    >
      Von <strong>{{ minPriceWithCurrency }}</strong>
      Bis <strong>{{ maxPriceWithCurrency }}</strong>
    </Typography>
    <RangeSelector
      ref="rangeSelector"
      :value="internalPixValue"
      class="price-range-selector__range-selector"
      :active="active"
      @input="onRangeInput"
      @becomes-active="initRangeSelector"
    />
    <div class="price-range-selector__value-labels">
      <span class="price-range-selector__value-min">
        0 €
      </span>
      <span class="price-range-selector__value-max">
        {{ initialMax }} €
      </span>
    </div>
    <CheckboxWithLabel
      v-model="internalDiscounted"
      class="price-range-selector__checkbox-with-label"
      label="Nur Angebote"
      @input="onCheckboxInput"
    />
  </div>
</template>

<script>
import { defaultTo, isEqual } from 'lodash';
import RangeSelector from '@/components/range-selector';
import Typography from '@/components/typography';
import CheckboxWithLabel from '@/components/checkbox-with-label';

export default {
  name: 'PriceRangeSelector',
  components: {
    RangeSelector,
    Typography,
    CheckboxWithLabel,
  },
  props: {
    discounted: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    initialMax: {
      type: Number,
      default: 2000,
    },
  },
  data() {
    return {
      pixelMax: null,
      internalValue: {
        min: defaultTo(this.value.min, 0),
        max: defaultTo(this.value.max, this.initialMax),
      },
      internalDiscounted: this.discounted,
    };
  },
  computed: {
    minPriceWithCurrency() {
      return `${this.internalValue.min} €`;
    },
    maxPriceWithCurrency() {
      return `${this.internalValue.max} €`;
    },
    internalPixValue() {
      return {
        min: this.valueToPix(this.internalValue.min),
        max: this.valueToPix(this.internalValue.max),
      };
    },
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        // defaultTo -> https://lodash.com/docs/4.17.15#defaultTo
        // newValue.max || this.initialMax not possible because 0 is falsy
        this.internalValue.min = defaultTo(newValue.min, 0);
        this.internalValue.max = defaultTo(newValue.max, this.initialMax);
        // newValue not equals oldValue;
        // otherwise Filter will be reseted and set
        // if for example color is selected first
        // with price range selector closed
        const valueHasChanged = !isEqual(newValue, oldValue);
        const isSetToUndefined = newValue.min === undefined
          && newValue.max === undefined;
        const inDefaultPosition = oldValue.min === 0
          && oldValue.max === this.initialMax;
        if (valueHasChanged && isSetToUndefined && !inDefaultPosition) {
          this.$refs.rangeSelector.resetAnimateSlider();
          this.internalDiscounted = undefined;
        }
      },
      deep: true,
    },
  },
  methods: {
    initRangeSelector(pixelMax) {
      this.pixelMax = pixelMax;
    },
    valueToPix(value) {
      if (!this.pixelMax) return undefined;
      return Math.round(value * (this.pixelMax / this.initialMax));
    },
    pixToValue(value) {
      if (!this.pixelMax) return undefined;
      return Math.round(value / (this.pixelMax / this.initialMax));
    },
    emitPriceData(value = this.internalValue) {
      const data = {
        onetimePrice: value,
        discounted: this.internalDiscounted,
      };
      this.$emit('input', data);
    },
    onRangeInput(value) {
      // wenn pixelMax noch nicht gesetzt
      // hole vom pixelDragRange
      // (Breite der rangeSelectorBar - Sliderbreite * 2)
      this.emitPriceData({
        min: this.pixToValue(value.min) || 0,
        max: this.pixToValue(value.max) || 0,
      });
    },
    onCheckboxInput(value) {
      // filter also got undiscounted filter.
      // Therefore default value is undefined
      this.internalDiscounted = value || undefined;
      this.emitPriceData();
    },
  },
};
</script>

<style lang="scss">
.price-range-selector {
  padding: 0 30px;

  &__value-labels {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 30px;
    line-height: 35px;
    color: #868686;
    margin-top: 36px;
  }

  &__price-range-text {
    text-transform: uppercase;
    display: inline-block;
    text-align: left;
    width: 100%;
    margin-bottom: 48px;
  }

  &__checkbox-with-label {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
