<template>
  <div
    v-show="selectableStylesFiltered"
    ref="productStyleSelector"
    class="product-style-selector"
  >
    <SelectableItemGroup
      class="product-style-selector__item-group"
      :list="selectableStylesFiltered"
      :active="isVisible"
      :value="selectedStyles"
      @input="selectStyle"
    >
      <template #default="scope">
        <div class="product-style-selector__image-container">
          <GoliathImage
            v-if="scope.entry.image"
            class="product-style-selector__image"
            :path="scope.entry.image.url"
            :alt="scope.entry.label"
          />
        </div>
        <div class="product-style-selector__text-content">
          <span class="product-style-selector__style-name">
            {{ scope.entry.label }}
          </span>
          <ul class="product-style-selector__usps">
            <li
              v-for="(usp, uspIndex) in scope.entry.usps"
              :key="`usp-product-style-${uspIndex}`"
              class="product-style-selector__usp"
            >
              {{ usp }}
            </li>
          </ul>
          <price-range
            v-if="scope.entry.onetimePriceRange && scope.entry.onetimePriceRange.min"
            :amount="scope.entry.onetimePriceRange.min.amount"
            :equal="scope.entry.onetimePriceRange.equal"
            class="product-style-selector__price-range"
          />
        </div>
      </template>
    </SelectableItemGroup>
    <LoadingSpinnerInline
      class="product-style-selector__loading_indicator"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import GoliathImage from '@/components/goliath-image';
import PriceRange from '@/components/price-range';
import SelectableItemGroup from '@/components/selectable-item-group';
import LoadingSpinnerInline from '@/components/loading-spinner-inline';

export default {
  name: 'ProductStyleSelector',
  components: {
    SelectableItemGroup,
    GoliathImage,
    PriceRange,
    LoadingSpinnerInline,
  },
  props: {
    selectableProductStyles: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedProductStyleSlug: this.value,
    };
  },
  computed: {
    selectableStylesFiltered() {
      return this.selectableProductStyles.map((entry) => ({
        label: entry.style.name,
        value: entry.style.slug,
        image: entry.style.image,
        usps: entry.style.usps,
        onetimePriceRange: entry.onetimePriceRange,
      }));
    },
    isVisible() {
      return this.selectableStylesFiltered.length > 0;
    },
    selectedStyles() {
      return [this.value];
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedProductStyleSlug = value;
      },
    },
  },
  methods: {
    selectStyle(slugs) {
      this.$emit('input', slugs[0]);
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";
.product-style-selector {
  position: relative;

  .selectable-item-group__item {
    margin-bottom: 36px;
    height: 290px;
    width: 550px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    &--selected .product-style-selector__usps::after {
      background: linear-gradient(180deg,hsla(0,0%,100%,0),#FBFBFB);
    }
  }

  &__text-content {
    padding-left: 6px;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    max-width: 100%;
  }
  &__image-container {
    flex-shrink: 0;
    height: 240px;
    width: 240px;
  }

  &__style-name {
    flex: 0 0 auto;
    color: #E20074;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.5px;
    line-height: 36px;
    margin-bottom: 12px;
    display: block;
    font-weight: 400;
    word-break: break-word;
  }

  &__usps {
    flex: 1 1 100%;
    color: #000000;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 36px;
    list-style-type: "–";
    padding-left: 12px;
    position: relative;
    overflow: hidden;
    margin-bottom: 18px;

    & > li {
      padding-left: 6px;
      text-transform: none;
      font-size: 24px;
      line-height: 25px;
      margin-bottom: 7px;
    }

    &::after {
      content: " ";
      position: absolute;
      pointer-events: none;
      height: 33.333%;
      left: 0;
      right: 0;
      bottom: 0;
      transition: height 1s ease-in-out;
      background: linear-gradient(180deg,hsla(0,0%,100%,0),#fff);
    }
  }

  &__price-range {
    flex: 0 0 auto;
    margin-bottom: 12px;
  }

  &__item-group {
    display: flex;
    height: 660px;
    flex-flow: column wrap;
  }

  &__loading_indicator {
    min-height: 660px;
  }

}
 </style>
