<template>
  <div
    v-show="selectableProductFamilies"
    ref="compatibleProductFamilySelector"
    class="compatible-product-family-selector"
  >
    <Typography
      v-show="hasPhones"
      variant="flow-text-title"
      class="compatible-product-family-selector__heading"
    >
      Smartphone Modell
    </Typography>
    <SelectableItemGroup
      class="compatible-brand-selector__item-group"
      :list="selectableProductFamiliesPhones"
      :active="hasPhones"
      :value="selectedFamilySlugs"
      @input="selectProductFamily"
    />

    <Typography
      v-show="hasTablets"
      variant="flow-text-title"
      class="compatible-product-family-selector__heading compatible-product-family-selector__heading--tablet"
    >
      Tablet Modell
    </Typography>
    <SelectableItemGroup
      class="compatible-brand-selector__item-group"
      :list="selectableProductFamiliesTablets"
      :active="hasTablets"
      :value="selectedFamilySlugs"
      @input="selectProductFamily"
    />
    <LoadingSpinnerInline
      class="compatible-product-family-selector__loading_indicator"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import Typography from '@/components/typography';
import SelectableItemGroup from '@/components/selectable-item-group';
import LoadingSpinnerInline from '@/components/loading-spinner-inline';

const TABLET_CATEGORYNAME = 'T_TSTABLETS';

export default {
  name: 'CompatibleProductFamilySelector',
  components: {
    SelectableItemGroup,
    Typography,
    LoadingSpinnerInline,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    selectableProductFamilies: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedProductFamilySlug: this.value,
    };
  },
  computed: {
    selectableProductFamiliesFiltered() {
      return this.selectableProductFamilies.map((compatibleProductFamily) => ({
        value: compatibleProductFamily.productFamily.slug,
        label: compatibleProductFamily.productFamily.model,
        isTablet: this.isTablet(compatibleProductFamily.productFamily),
      }));
    },
    selectableProductFamiliesPhones() {
      return this.selectableProductFamiliesFiltered
        .filter((productFamily) => !productFamily.isTablet);
    },
    selectableProductFamiliesTablets() {
      return this.selectableProductFamiliesFiltered
        .filter((productFamily) => productFamily.isTablet);
    },
    hasPhones() {
      return !isEmpty(this.selectableProductFamiliesPhones);
    },
    hasTablets() {
      return !isEmpty(this.selectableProductFamiliesTablets);
    },
    selectedFamilySlugs() {
      return [this.value];
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selectedProductFamilySlug = value;
      },
    },
  },
  methods: {
    selectProductFamily(slugs) {
      this.$emit('input', slugs[0]);
      this.$emit('change');
    },
    isTablet(productFamily) {
      return productFamily.rootCategories
        .some((c) => c.categoryNumber === TABLET_CATEGORYNAME);
    },
    isSelected(slug) {
      return this.selectedProductFamilySlug === slug;
    },
  },
};
</script>

<style lang="scss">
.compatible-product-family-selector {
  position: relative;

  &__heading {
    font-size: 30px;
    line-height: 40px;
    padding: 36px 30px;
    &--tablet {
      padding-top: 60px;
    }
  }

  &__item-group {
    display: flex;
    padding: 0 30px;
  }

  &__loading_indicator {
    min-height: 235px;
  }
}
 </style>
