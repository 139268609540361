<template>
  <div
    ref="shelf"
    class="shelf"
  >
    <div
      v-for="boardsIndex in boards"
      :key="`shelf__row-${boardsIndex}`"
      ref="shelfRow"
      class="shelf__row"
    >
      <div
        ref="shelfItems"
        :class="containerClass"
        class="shelf__item-group"
      >
        <component
          :is="node.component"
          v-for="(node, index) in reorderedItems[boardsIndex - 1]"
          :key="`shelf-item-${index}`"
          ref="shelfItem"
          v-bind="node"
          :class="shelfItemClass"
          :is-big="isBig"
          :is-extra-big="isExtraBig"
          class="shelf__item"
        />
      </div>
      <ShelfBoard />
    </div>
  </div>
</template>

<script>
import { slice } from 'lodash';
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';
import ClayOnShelf from '@/components/clay-on-shelf';
import OfferOnShelf from '@/components/offer-on-shelf';
import UnknownOnShelf from '@/components/unknown-on-shelf';
import ShelfBoard from '@/components/shelf-board';
// eslint-disable-next-line
import InertiaPlugin from '@/assets/js/InertiaPlugin';

gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

const DIRECTION_IN_COLUMNS = 'inColumns';
const DIRECTION_IN_ROWS = 'inRows';
const ALLOWED_DIRECTIONS = [DIRECTION_IN_COLUMNS, DIRECTION_IN_ROWS];
const WINDOW_WIDTH = 1080;
const REACH_END_OFFSET = WINDOW_WIDTH * 4;
const MINIMUM_DRAG_MOVEMENT = 10;

export default {
  name: 'Shelf',
  components: {
    ClayOnShelf,
    OfferOnShelf,
    UnknownOnShelf,
    ShelfBoard,
  },
  props: {
    isBig: {
      type: Boolean,
      default: false,
    },
    isExtraBig: {
      type: Boolean,
      default: false,
    },
    boards: {
      type: Number,
      default: 3,
    },
    direction: {
      type: String,
      default: 'inColumns',
      validator: (value) => ALLOWED_DIRECTIONS.includes(value),
    },
    items: {
      type: Array,
      required: true,
    },
    animate: {
      type: Boolean,
      default: false,
    },
    disableDraggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      draggableInstance: null,
      boundsReached: false,
      minimumDragMovement: MINIMUM_DRAG_MOVEMENT,
    };
  },
  computed: {
    shelfItemClass() {
      return {
        'shelf__item--big': this.isBig,
        'shelf__item--extra-big': this.isExtraBig,
      };
    },
    containerClass() {
      return {
        'shelf__item-group--center': this.items.length <= 3,
        'shelf__item-group--big': this.isBig,
        'shelf__item-group--extra-big': this.isExtraBig,
      };
    },
    directionInColumns() {
      return this.direction === DIRECTION_IN_COLUMNS;
    },
    reorderedItems() {
      return this.directionInColumns
        ? this.reorderItemsInColumns()
        : this.reorderItemsInRows();
    },
  },
  watch: {
    items: {
      handler() {
        this.$nextTick(() => {
          if (this.draggableInstance && this.draggableInstance.length) {
            this.setBounds();
          }
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.initShelf();
  },
  beforeDestroy() {
    if (this.draggableInstance && this.draggableInstance.length) {
      this.draggableInstance[0].kill();
    }
  },
  methods: {
    reorderItemsInColumns() {
      const rows = [];
      for (let i = 0; i < this.boards; i += 1) {
        rows.push([]);
      }
      this.items.forEach((item, index) => {
        rows[index % this.boards].push(item);
      });
      return rows;
    },
    reorderItemsInRows() {
      const { length } = this.items;
      let items = [];

      // ordering smaller then this.boards items top to bottom
      // (in one column; in viewport)
      for (let i = 0; i < this.boards; i += 1) {
        if (this.items[i]) {
          items.push([this.items[i]]);
        } else {
          items.push([]);
        }
      }
      if (length <= this.boards) return items;

      // ordering more then this.boards items left to right
      // (on x rows / boards)
      items = [];
      const steps = Math.ceil(length / this.boards);
      for (let i = 0; i < this.boards; i += 1) {
        const itemChunk = slice(this.items, i * steps, (i + 1) * steps);
        items.push(itemChunk);
      }
      return items;
    },
    setBounds() {
      this.draggableInstance[0].applyBounds({
        minX: 0,
        maxX: (this.$refs.shelf.scrollWidth * -1) + WINDOW_WIDTH,
      });
      this.boundsReached = false;
    },
    onDragPositionUpdated() {
      if (this.boundsReached) return;
      const instance = this.draggableInstance[0];
      const distanceToXBound = instance.x - instance.minX;
      if (distanceToXBound - REACH_END_OFFSET <= 0) {
        this.boundsReached = true;
        this.$emit('end-reached');
      }
    },
    getDraggableParams() {
      return {
        type: 'x',
        edgeResistance: 0.7,
        bounds: {
          target: '.shelf',
        },
        inertia: true,
        minimumMovement: this.minimumDragMovement,
        zIndexBoost: false,
        onDrag: this.onDragPositionUpdated,
        onThrowUpdate: this.onDragPositionUpdated,
      };
    },
    initShelf() {
      if (this.disableDraggable) return;
      this.draggableInstance = Draggable
        .create('.shelf', this.getDraggableParams());
    },
  },
};
</script>

<style lang="scss">
.shelf {
  width: min-content;
  min-width: 100%;

  &__row {
    display: flex;
    flex-direction: column;
    margin: 0 0 -115px;
    padding: 0 30px;
  }

  &__item-group {
    display: flex;
    height: 350px;
    justify-content: flex-start;
    padding: 0 50px;
    position: relative;
    top: 20px;

    &--center {
      justify-content: center;
    }

    &--big {
      height: 460px;
    }

    &--extra-big {
      height: 550px;
    }
  }

  &__item {
    margin: 0 55px;

    &--big {
      height: 390px;
    }

    &--extra-big {
      height: 480px;
    }
  }
}
</style>
