<template>
  <div class="collection-women">
    <Header />
    <div class="collection-women__container">
      <Typography
        variant="heading"
        class="collection-women__heading"
      >
        Heatable Capsule Collection
      </Typography>
      <Typography
        variant="pre-heading"
      >
        Women
      </Typography>
      <HeatableJacketOffers
        class="collection-women__offers"
        show-collection-women
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Header from '@/components/header';
import Footer from '@/components/footer';
import Typography from '@/components/typography';
import HeatableJacketOffers from '@/components/heatable-jacket/heatable-jacket-offers';

export default {
  name: 'CollectionWomen',
  components: {
    Footer,
    Header,
    HeatableJacketOffers,
    Typography,
  },
  mounted() {
    this.updateBreadcrumb([
      { path: '/start', label: 'Home' },
      { path: '/heatable-jacket', label: 'Heatable<br>Capsule' },
      { path: '/heatable-jacket/collection-women', label: 'Damen' },
    ]);
  },
  methods: {
    ...mapActions({
      updateBreadcrumb: 'breadcrumb/updateEntries',
    }),
  },
};
</script>

<style lang="scss">
.collection-women {

  &__container {
    padding: 0 30px;
  }

  &__heading {
    margin-bottom: 20px;
  }

  &__offers {
    padding: 60px 0;
  }
}
</style>
