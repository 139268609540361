<template>
  <component
    :is="element"
    :class="classes"
  >
    {{ humanReadablePrice }}
  </component>
</template>

<script>
import LOCALES from '@/constants/locales.json';

export default {
  name: 'Price',
  props: {
    amount: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    isSlashed: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    element() {
      if (this.isSlashed) return 's';
      return 'span';
    },
    classes() {
      const classes = ['price'];
      if (this.isSlashed) classes.push('price--slashed');
      if (this.variant) classes.push(`price--${this.variant}`);
      return classes;
    },
    humanReadablePrice() {
      const i10nCurrency = new Intl.NumberFormat(LOCALES.de, {
        style: 'currency',
        currency: this.currency,
      });
      return i10nCurrency.format(this.amount / 100);
    },
  },
};
</script>

<style lang="scss">
.price {
  color: #e20074;
  display: inline-block;

  &--unstyled{
    color: inherit;
  }

  &--slashed,
  &--sale-slashed {
    color: #7c7c7c;
    position: relative;
    text-decoration: none;

    &::after {
      content: " ";
      display: block;
      height: 1em;
      left: 0;
      position: absolute;
      top: calc(50% - 0.05em);
      transform: rotate(-10deg);
      width: 100%;
    }
  }

  &--slashed {
    &::after {
      border-top: 1px solid currentColor;
    }
  }

  &--sale-slashed {
    &::after {
      border-top: 0.06em solid currentColor;
    }
  }
}
</style>
