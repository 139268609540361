<template>
  <div class="screensaver-offer-tile">
    <div class="screensaver-offer-tile__label-container">
      <Typography
        ref="screensaverOfferTileLabelTop"
        class="screensaver-offer-tile__label screensaver-offer-tile__label--top"
        variant="hint"
      >
        {{ topLabel }}
      </Typography>
    </div>

    <GoliathImage
      :alt="label"
      :path="imageSrc"
      class="screensaver-offer-tile__image"
    />
    <Badge
      v-if="hasEnergyLabel"
      auto-width
      :default-font-size="30"
      :position-default="{ top: 50, right: 70 }"
    >
      <span v-html="badgeText" />
    </Badge>

    <div
      v-if="!aktion"
      class="screensaver-offer-tile__bottom"
    >
      <Typography
        ref="screensaverOfferTileLabel"
        class="screensaver-offer-tile__label"
        variant="hint"
      >
        {{ label }}
      </Typography>

      <span
        v-if="!hasEnergyLabel"
        class="screensaver-offer-tile__price"
      >
        <Price
          v-if="wasPrice"
          class="screensaver-offer-tile__price--was"
          v-bind="wasPrice"
          variant="sale-slashed"
        />
        <Price
          class="screensaver-offer-tile__price--default"
          v-bind="defaultPrice"
        />
      </span>
    </div>
    <router-link
      :to="path"
      class="screensaver-category-tile__link"
    />
  </div>
</template>

<script>
import GoliathImage from '@/components/goliath-image';
import Typography from '@/components/typography';
import Price from '@/components/price';
import { Badge } from '@i22-td-smarthome/component-library';

export default {
  name: 'ScreensaverOfferTile',
  components: {
    GoliathImage,
    Typography,
    Price,
    Badge,
  },
  props: {
    highlight: {
      type: Boolean,
      default: false,
    },
    aktion: {
      type: Boolean,
      default: false,
    },
    wasPrice: {
      type: Object,
      default: null,
    },
    defaultPrice: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    hasEnergyLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    topLabel() {
      if (this.highlight) return 'Highlight';
      if (this.aktion) return 'Aktion';
      return 'Angebot';
    },
    badgeText() {
      if (this.wasPrice) {
        const percentage = 1 - this.defaultPrice.amount / this.wasPrice.amount;
        const percentageString = `${(percentage * 100).toFixed()} %`;
        return `Jetzt <br><span class="badge--bold badge--size-125">${percentageString}</span> <br>sparen`;
      }
      return 'Jetzt <br>entdecken';
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/style.scss';

.screensaver-offer-tile {
  position: relative;

  a,
  a:visited {
    text-decoration: none;
    color: transparent;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__image {
    position: absolute;
    top: 90px;
    display: block;
    max-height: 300px;
  }

  &__label {
    font-weight: 400;
    color: #ffffff;
    font-size: 31px;
    font-weight: 900;
    letter-spacing: 0.31px;
    line-height: 36px;
    max-width: 230px;

    &--top {
      font-weight: 700;
      text-transform: uppercase;
    }

    &-container {
      position: absolute;
      top: 5px;
      left: 0px;
      background-color: #e20074;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 16px 6px 16px;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 24px;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0 22px;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__price {
    color: #858788;
    font-size: 31px;
    letter-spacing: 0.31px;
    line-height: 36px;

    &--was {
      color: #858788;
    }

    &--default {
      font-weight: 700;
    }
  }
}
</style>
