<template>
  <ProductContentDetails
    :product="product"
    :render-item-if="hasDeliveredItems"
  >
    <BulletPointList
      slot-scope="props"
      :is-bundle="product.bundle"
      :list="filterDeliveredItemNames(props.product)"
      itemprop="description"
    />
  </ProductContentDetails>
</template>

<script>
import ProductContentDetails from '@/components/product-detail/product-content-details';
import BulletPointList from '@/components/bullet-point-list';

export default {
  name: 'OfferDelivery',
  components: {
    BulletPointList,
    ProductContentDetails,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hasDeliveredItems(product) {
      return product.deliveredItems.length > 0;
    },
    filterDeliveredItemNames(product) {
      return product.deliveredItems.map((i) => ({ text: i }));
    },
  },
};
</script>

<style lang="scss">

</style>
