<template>
  <div class="category-hierarchy">
    <div
      v-for="category in categoryHierarchy"
      :key="category.name"
      class="category-hierarchy__category"
    >
      <RouterLink
        :to="category.link"
        class="category-hierarchy__category-name"
      >
        <Typography
          variant="title-bold"
          v-html="category.name"
        />
      </RouterLink>
      <div class="category-hierarchy__items">
        <RouterLink
          v-for="child in category.children"
          :key="child.name"
          :to="child.link"
          class="category-hierarchy__item"
          v-html="child.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Typography from '@/components/typography';

export default {
  name: 'CategoryHierarchy',
  components: {
    Typography,
  },
  computed: {
    categoryHierarchy() {
      return this.$store.state.shelves.categoryHierarchy;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.category-hierarchy {
  max-height: 1200px;
  overflow: hidden;

  &__category {
    margin: 0 0 60px;
  }

  &__category-name {
    display: block;
    margin: 0 0 18px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 -30px;
  }

  &__item {
    @include font-size(25px, 30px);
    font-weight: 400;
    flex: 0 0 25%;
    margin: 0 0 30px;
    padding: 0 24px 0 0;
  }

  &__item,
  &__item:hover,
  &__item:focus,
  &__item:active,
  &__item:visited {
    color: #000;
  }
}
</style>
