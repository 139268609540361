<template>
  <div
    v-if="ready"
    id="app"
    class="app"
    @mousedown="handleTouch"
    @touchstart="handleTouch"
  >
    <router-view
      class="app__page"
      :class="{ 'app__page--blurred': showModal }"
    />

    <PleaseWaitModal />

    <Maintenance v-if="isMaintenanceActive" />

    <TimeoutModal
      v-if="!isScreenSaverPage"
      :variant="smallModalVariant"
      :full-cycle-time="fullCycleTime"
      :time-left="internalTimeLeft"
      :show-modal-at="internalShowModalAt"
      @toggles-active="onToggleActive"
      @time-ran-out="resetShelf"
    />

    <Modal
      ref="cartErrorModal"
      @clickaway="resetCartErrorStatus"
      @close="resetCartErrorStatus"
    >
      {{ errorMessage }}
    </Modal>
    <VideoPreloader
      v-if="preloadActive"
      :preloadable-videos="preloadableVideos"
    />
    <StatusToolbarWrapper v-if="showStatusToolbar" />

    <PortalTarget name="global-modal" />
  </div>
</template>

<script>
import ms from 'ms';
import Vue from 'vue';
import ShopApi from '@/lib/shop-api';
import { captureException, getSentry } from '@/lib/sentry';
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import { GoliathClient } from '@/lib/goliath/goliath-client';
import actions from '@/store/actions';
import TimeoutModal from '@/components/timeout-modal';
import PleaseWaitModal from '@/components/please-wait-modal';
import Modal from '@/components/modal';
import PRELOADABLE_VIDEOS from '@/constants/preloadable-videos.json';
import VideoPreloader from '@/components/video-preloader';
import StatusToolbarWrapper from '@/components/status-toolbar-wrapper';
import { PortalTarget } from 'portal-vue';
import { getActivationScreenByVp } from '@/lib/get-activation-screen-by-vp';
import Maintenance from './views/maintenance';

const PAGES_WITHOUT_TIMEOUT_MODAL = ['/screensaver', '/error'];

const mode = require('@/server/mode');

const THREE_MINUTES_IN_MILLISECONDS = 3 * 60 * 1000; // updateInterval

export default {
  name: 'App',
  components: {
    StatusToolbarWrapper,
    PleaseWaitModal,
    TimeoutModal,
    Modal,
    VideoPreloader,
    Maintenance,
    PortalTarget,
  },
  data() {
    return {
      internalTimeLeft: undefined,
      internalShowModalAt: ms('30s'),
      ready: false,
      disableScreensaver: false,
      showModal: false,
      initialStoreDataFetched: false,
      preloadActive: true,
      showStatusToolbar: !mode.isProduction,
    };
  },
  computed: {
    ...mapGetters({
      maintenanceDowntime: 'downtimes/maintenanceDowntime',
    }),
    ...mapState({
      cancelOverlaySwitchActive: (state) => state.cancelOverlaySwitch.active,
      fullCycleTime: (state) => state.timeout.fullCycleTime,
      timeLeft: (state) => state.timeout.timeLeft,
      lastTouchedAt: (state) => state.timeout.lastTouchedAt,
      cartErrorStatus: (state) => state.cart.errorStatus,
      vp: (state) => state.session.vp,
      lastUpdated: (state) => state.downtimes.lastUpdated,
    }),
    preloadableVideos() {
      return {
        ...PRELOADABLE_VIDEOS,
        activationScreen:
          getActivationScreenByVp(this.getVP()) || PRELOADABLE_VIDEOS.activationScreen,
      };
    },
    isMaintenanceActive() {
      return !this.isScreenSaverPage && this.maintenanceDowntime;
    },
    downtimesExpireTimestamp() {
      return this.lastUpdated + THREE_MINUTES_IN_MILLISECONDS;
    },
    smallModalVariant() {
      if (!this.cancelOverlaySwitchActive) return null;
      return 'small';
    },
    errorMessage() {
      if (!this.cartErrorStatus) return '';
      return this.cartErrorStatus.description;
    },
    isScreenSaverPage() {
      return PAGES_WITHOUT_TIMEOUT_MODAL.includes(this.$route.path);
    },
  },
  watch: {
    timeLeft: {
      handler(timeLeft) {
        this.internalTimeLeft = timeLeft;
      },
      immediate: true,
    },
    cartErrorStatus(error) {
      if (error) {
        this.$refs.cartErrorModal.open();
      }
    },
  },
  async mounted() {
    await this.handleVPLogin();
    this.detectDeeplinkVariant();
    this.getInitialStoreData();
  },
  methods: {
    ...mapActions('session', ['setVP']),
    ...mapActions('downtimes', ['fetchDowntimes']),
    ...mapMutations({
      setSwitchToOverlayActiveFalse: 'cancelOverlaySwitch/SET_SWITCH_INACTIVE',
      setTimeLeft: 'timeout/SET_TIME_LEFT',
      setLastTouchedAt: 'timeout/SET_LAST_TOUCHED_AT',
      setDeeplink: 'settings/SET_DEEPLINK',
    }),
    onToggleActive(value) {
      this.showModal = value;
    },
    async getInitialStoreData() {
      try {
        await Promise.all([
          this.$store.dispatch(actions.INITIALIZE_PROMOTIONS),
          this.$store.dispatch(actions.INITIALIZE_SHELVES),
          this.$store.dispatch(actions.FETCH_CART),
        ]);
      } catch (error) {
        console.error('Initial Data fetching failed');
      }
    },
    detectDeeplinkVariant() {
      const isDeeplinkSettingInStore = this.$store.state.settings.deeplink;
      const isDeeplinkSettingInRoute = this.$route.query.deeplink;
      const isDeeplink = isDeeplinkSettingInStore || isDeeplinkSettingInRoute;
      this.setDeeplink(isDeeplink);
    },
    handleTouch() {
      this.resetTimes();
      // computed with Date.now() will be cached and won't refresh internally!!
      const nowTimeStamp = Date.now();
      const downtimesExpired = this.downtimesExpireTimestamp < nowTimeStamp;
      if (downtimesExpired) {
        this.fetchDowntimes();
      }
    },
    configureScreensaver({ disableScreensaver }) {
      this.disableScreensaver = disableScreensaver;
    },
    routeBlocksScreensaverStart() {
      return (
        this.$route.path === '/screensaver'
        || this.$route.path.startsWith('/error')
      );
    },
    resetTimes() {
      this.setSwitchToOverlayActiveFalse();
      this.setTimeLeft(this.fullCycleTime);
      this.setLastTouchedAt(Date.now());
    },
    resetShelf() {
      GoliathClient.resetStore();
      this.resetTimes();
      this.$router.push('/screensaver');
    },
    resetCartErrorStatus() {
      this.$store.commit('cart/SET_CART_ERRORSTATUS', null);
    },
    getVP() {
      if (this.$route.query.vp) return this.$route.query.vp; // Prio 1: from url query param
      if (window.localStorage.getItem('vp')) return window.localStorage.getItem('vp'); // Prio 2: from local storage
      if (Vue.config.vp) return Vue.config.vp; // Prio 3: from vue config (used for tests)
      return null;
    },
    async handleVPLogin() {
      const vp = this.getVP();
      await this.setVP(vp);

      // early exit for test enviroments
      if (Vue.config.vp) {
        this.ready = true;
        return;
      }

      if (vp) {
        try {
          if (await ShopApi.login(vp)) {
            window.localStorage.setItem('vp', vp);
            getSentry().then((s) => {
              s.getCurrentHub().getScope().setUser({ vp });
            });
          }
        } catch (error) {
          console.error(error.response);
          this.$router.push('error');
        }
      } else {
        captureException(new Error('VP not set!'));
        console.error('VP not set!');
      }
      this.ready = true;
    },
  },
};
</script>

<style lang="scss">
@import './assets/styles/global.scss';

.app {
  height: 1920px;
  width: 1080px;
  user-select: none;
  overflow: hidden;
  position: relative;

  &__page {
    &--blurred {
      filter: blur(3px);
    }
  }
}
</style>
