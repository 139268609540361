import { isEmpty } from 'lodash';
import { GoliathClient } from './goliath-client';
import query from './queries/offers-accessories-and-recommendations.graphql';

export default async function fetchOffers(params = {}) {
  if (isEmpty(params.articleNumbers)) return [];
  const response = await GoliathClient.query({
    query,
    variables: { ...params },
  });

  return response.data?.offers || [];
}
