<template>
  <div class="error-page">
    Error 404
  </div>
</template>

<script>
export default {
  name: 'Error404Page',
};
</script>
