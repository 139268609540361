<template>
  <Screensaver
    v-if="offers"
    :offers="offers"
    path="/start"
  >
    Zur Telekom Shop-Welt
  </Screensaver>
</template>

<script>
import Screensaver from '@/components/screensaver';
import actions from '@/store/actions';

export default {
  name: 'ScreensaverPage',
  components: {
    Screensaver,
  },
  computed: {
    offers() {
      return this.$store.state.promotions.promotions;
    },
  },
  mounted() {
    this.$store.dispatch(actions.RESET_CART);
  },
};
</script>
