import { GoliathClient } from './goliath-client';
import offersFilterData from './queries/offers-filter-data.graphql';

export default async function fetchOffersFilterData(params = {}) {
  const response = await GoliathClient.query({
    query: offersFilterData,
    variables: { ...params },
  });

  return response.data.offers;
}
