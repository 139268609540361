import { GoliathClient } from './goliath-client';
import offersByArticleNumbers from './queries/offers-by-article-number.graphql';

export default async function fetchOffers(params = {}) {
  const response = await GoliathClient.query({
    query: offersByArticleNumbers,
    variables: { ...params },
  });

  return response.data.offers;
}
