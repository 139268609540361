<template>
  <div class="select-device-page">
    <div
      class="select-device-page__filter"
      :class="selectDevicePageFilterClass"
    >
      <Typography
        variant="flow-text-title"
        class="select-device-page__heading"
      >
        Finde dein Gerät
      </Typography>
      <CompatibleBrandSelector
        v-model="selectedCompatibleBrandSlug"
        :is-loading="isLoadingBrands"
        class="select-device-page__selector"
        :selectable-brands="selectableCompatibleBrands"
        @change="onBrandChange"
      />
      <CompatibleProductFamilySelector
        v-model="selectedCompatibleProductFamilySlug"
        :is-loading="isLoadingProductFamilies"
        class="select-device-page__selector"
        :active="brandSelected"
        :selectable-product-families="selectableCompatibleProductFamilies"
      />
    </div>
    <div class="select-device-page__footer">
      <CommonButton
        :disabled="!modelSelected"
        class="select-device-page__button"
        @click.native="submit"
      >
        Weiter zu den Hüllen
      </CommonButton>
    </div>
  </div>
</template>

<script>
import DeinDesignOffers from '@/mixins/dein-design-offers';
import { get } from 'lodash';
import CommonButton from '@/components/common-button';
import Typography from '@/components/typography';
import CompatibleBrandSelector from '@/components/compatible-brand-selector';
import CompatibleProductFamilySelector from '@/components/compatible-product-family-selector';
import { fetchDeinDesignBrands, fetchDeinDesignProductFamilies } from '@/lib/goliath/dein-design-brands-and-families';

export default {
  name: 'SelectDevicePage',
  components: {
    CommonButton,
    Typography,
    CompatibleBrandSelector,
    CompatibleProductFamilySelector,
  },
  mixins: [
    // origin async this.getOffers()
    DeinDesignOffers(),
  ],
  data() {
    return {
      offers: {},
      selectedCompatibleBrandSlug: '',
      selectedCompatibleProductFamilySlug: '',
      selectableCompatibleBrands: [],
      selectableCompatibleProductFamilies: [],
      isLoadingBrands: false,
      isLoadingProductFamilies: false,
    };
  },
  computed: {
    modelSelected() {
      return !!this.selectedCompatibleProductFamilySlug;
    },
    brandSelected() {
      return !!this.selectedCompatibleBrandSlug;
    },
    selectDevicePageFilterClass() {
      return {
        'select-device-page__filter--brand-selected': this.brandSelected,
      };
    },
  },
  async mounted() {
    this.isLoadingBrands = true;
    try {
      this.offers = await this.getOffers({}, fetchDeinDesignBrands);
      this.selectableCompatibleBrands = get(this.offers, 'aggregations.compatibleBrands');
    } catch (e) {
      console.error({ e });
    }
    this.isLoadingBrands = false;
  },
  methods: {
    async onBrandChange() {
      this.selectableCompatibleProductFamilies = [];
      this.selectedCompatibleProductFamilySlug = '';
      this.isLoadingProductFamilies = true;
      const params = this.selectedCompatibleBrandSlug
        ? { filter: { compatibleBrandSlugs: [this.selectedCompatibleBrandSlug], productAvailable: true } }
        : {};
      const offers = await this.getOffers(params, fetchDeinDesignProductFamilies);
      this.selectableCompatibleProductFamilies = get(
        offers,
        'aggregations.compatibleProductFamilies',
        [],
      );
      this.isLoadingProductFamilies = false;
    },
    submit() {
      const queryParams = this.getQueryParams();
      const selectedDevice = this.selectableCompatibleProductFamilies.find(
        (e) => e.productFamily.slug === this.selectedCompatibleProductFamilySlug,
      ).productFamily;
      this.$emit('selected-device', selectedDevice);
      this.$router.push({
        name: 'dein-design-select-case-page',
        query: {
          ...queryParams,
          compatibleBrandSlug: this.selectedCompatibleBrandSlug,
          compatibleProductFamilySlug: this.selectedCompatibleProductFamilySlug,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.select-device-page {

  &__footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__filter {
    position: relative;
    transform: translate3d(0, 0, 0);
    transition: height 1s;
    overflow: hidden;
    height: 432px;
    background-color: #FBFBFB;
    margin-bottom: 60px;
    &::before {
      content: '';
      height: 20px;
      display: block;
      background: linear-gradient(180deg, #E5E5E5 0%, #F9F9F9 50.92%, #FBFBFB 73.92%, #FBFBFB 100%);
    }

    &::after {
      position: absolute;
      bottom: 0;
      content: '';
      height: 20px;
      width: 100%;
      display: block;
      background: linear-gradient(180deg, #FBFBFB 0%, #F9F9F9 50.92%, #F7F4F4 73.26%, #F3F3F3 100%);
    }

    &--brand-selected {
      height: 942px;
    }
  }
  &__heading {
    white-space: nowrap;
    margin-right: 48px;
    font-size: 40px;
    line-height: 50px;
    padding: 48px 30px;
  }

  &__selector {
    margin-bottom: 72px;
  }
}
</style>
