<template>
  <div class="select-case-page">
    <div
      class="select-case-page__filter select-case-page__filter--product-tag"
      :class="selectCasePageFilterClass"
    >
      <Typography
        variant="flow-text-title"
        class="select-case-page__heading"
      >
        Finde die passende Hülle
      </Typography>
      <ProductTagSelector
        v-model="selectedProductTagSlug"
        class="select-case-page__selector"
        :active="productTagSelectorActive"
        :selectable-product-tags="selectableProductTags"
        @change="onProductTagChange"
      />
    </div>
    <div
      class="select-case-page__filter select-case-page__filter--product-style"
      :class="selectCasePageFilterClass"
    >
      <ProductStyleSelector
        v-model="selectedProductStyleSlug"
        class="select-case-page__selector"
        :active="productStyleSelectorActive"
        :class="productStyleSelectorClass"
        :selectable-product-styles="selectableProductStyles"
        :is-loading="isLoadingStyles"
      />
    </div>
    <div class="select-case-page__footer">
      <CommonButton
        :disabled="disabled"
        class="select-case-page__button"
        @click.native="submit"
      >
        Jetzt gestalten
      </CommonButton>
    </div>
  </div>
</template>

<script>
import DeinDesignOffers from '@/mixins/dein-design-offers';
import { get } from 'lodash';
import CommonButton from '@/components/common-button';
import Typography from '@/components/typography';
import ProductTagSelector from '@/components/product-tag-selector';
import ProductStyleSelector from '@/components/product-style-selector';
import fetchOffersForCases from '@/lib/goliath/dein-design-offers-for-case';

export default {
  name: 'SelectCasePage',
  components: {
    CommonButton,
    Typography,
    ProductTagSelector,
    ProductStyleSelector,
  },
  mixins: [
    // origin async this.getOffers()
    DeinDesignOffers(fetchOffersForCases),
  ],
  data() {
    return {
      productTagSelectorActive: false,
      productStyleSelectorActive: false,
      offers: {},
      selectableProductTags: [],
      selectableProductStyles: [],
      selectedProductTagSlug: '',
      selectedProductStyleSlug: '',
      isLoadingStyles: false,
    };
  },
  computed: {
    disabled() {
      return !this.selectedProductStyleSlug;
    },
    productTagSelected() {
      return !!this.selectedProductTagSlug;
    },
    selectCasePageFilterClass() {
      return {
        'select-case-page__filter--product-tag-selected': this.productTagSelected,
      };
    },
    productStyleSelectorClass() {
      return {
        'select-case-page__selector--active': this.productTagSelected,
      };
    },
  },
  async mounted() {
    this.isLoadingStyles = true;
    try {
      this.offers = await this.getOffers();
      this.selectableProductTags = get(this.offers, 'aggregations.productTags', []);
      this.selectableProductStyles = get(this.offers, 'aggregations.productStyles', []);
      this.productTagSelectorActive = true;
      this.productStyleSelectorActive = true;
    } catch (e) {
      console.error({ e });
    }
    this.isLoadingStyles = false;
  },
  methods: {
    async onProductTagChange() {
      this.isLoadingStyles = true;
      const params = this.selectedProductTagSlug
        ? { filter: { productTagSlugs: [this.selectedProductTagSlug] } }
        : {};
      const offers = await this.getOffers(params);
      this.selectableProductStyles = get(
        offers,
        'aggregations.productStyles',
        [],
      );
      this.selectedProductStyleSlug = '';
      this.isLoadingStyles = false;
    },
    submit() {
      const queryParams = this.getQueryParams();
      const selectedCase = this.selectableProductStyles.find(
        (e) => e.style.slug === this.selectedProductStyleSlug,
      ).style;
      this.$emit('selected-case', selectedCase);
      this.$router.push({
        name: 'dein-design-select-motif-type-page',
        query: {
          ...queryParams,
          productTagSlug: this.selectedProductTagSlug,
          productStyleSlug: this.selectedProductStyleSlug,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.select-case-page {
  &__footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__filter--product-tag {
    position: relative;
    transform: translate3d(0, 0, 0);
    transition: height 1s;
    overflow: hidden;
    height: 280px;
    background-color: #FBFBFB;
    margin-bottom: 60px;
    &::before {
      content: '';
      height: 20px;
      display: block;
      background: linear-gradient(180deg, #E5E5E5 0%, #F9F9F9 50.92%, #FBFBFB 73.92%, #FBFBFB 100%);
    }

    &::after {
      position: absolute;
      bottom: 0;
      content: '';
      height: 20px;
      width: 100%;
      display: block;
      background: linear-gradient(180deg, #FBFBFB 0%, #F9F9F9 50.92%, #F7F4F4 73.26%, #F3F3F3 100%);
    }
  }

  &__filter--product-style {
    // height: 680px;
    // transform: translate3d(0, 0, 0);
    // transition: height 1s;
    // overflow: hidden;
    // &.select-case-page__filter--product-tag-selected {
    //   height: 680px;
    // }
  }
  &__heading {
    white-space: nowrap;
    margin-right: 48px;
    font-size: 40px;
    line-height: 50px;
    padding: 48px 30px;
  }

  &__selector {
    margin-bottom: 72px;
  }
}
</style>
