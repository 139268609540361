import {
  filter, fromPairs, groupBy, isEmpty,
} from 'lodash';
import ShopApi from '@/lib/shop-api';
import { fetchOfferBySlugs } from '@/lib/goliath/offer-by-slugs';

function getItemByArticleNumber(context, articleNumber) {
  return context.state.cart.items.find((cartItem) => cartItem.articleNumber === articleNumber);
}

function hasError(error, field, key) {
  if (!error.response) return false;

  if (error.response.data.errors) {
    const fieldErrors = error.response.data.errors[field] || [];
    return fieldErrors.some((item) => item.error === key);
  }
  return false;
}

function handleAddToCartError(error) {
  if (hasError(error, 'base', 'max_value')) {
    // If we have a contract in the cart, the user is prompted to decide
    // whether he wants to add the new one or keep the old one.
    return { description: 'Der Warenkorb übersteigt den zulässigen Wert von 2499 €. Größere Bestellungen müssen auf mehrere Vorgänge verteilt werden.', type: 'warning' };
  }

  if (hasError(error, 'quantity', 'less_than_or_equal_to')) {
    // WIP description anpassen
    return { description: 'Sie können maximal 10 gleiche Produkte in den Warenkorb legen.', type: 'warning' };
  }

  if (hasError(error, 'stock', 'greater_than')) {
    // If the stock returned by the Brodos live request is 0 the user gets
    // the out of stock message. The field name is quite weird imho.
    return { description: 'Dieser Artikel ist derzeit nicht auf Lager.', type: 'error' };
  }

  return { description: 'Hoppla! Da ist etwas schiefgelaufen. Bitte versuchen Sie es noch einmal.', type: 'error' };
}

const mapGoliathItemToCartSpecs = (item) => ({
  img: item.teaserImage || null,
  name: item.name,
  quantity: item.quantity,
  price: item.price,
  articleNumber: item.articleNumber,
  id: item.id,
  slug: item.slug,
});

const EMPTY_CART = { items: [], total: 0 };

export default {
  namespaced: true,
  state() {
    return {
      cart: EMPTY_CART,
      recommendations: [],
      errorStatus: null,
      bundlesByArticleNumber: {},
    };
  },
  getters: {
    totalCount(state) {
      if (!state.cart.items) return 0;
      return state.cart.items.reduce((acc, item) => acc + item.quantity, 0);
    },
    cartCode(state) {
      return state.cart.cartCode ? state.cart.cartCode : null;
    },
  },
  actions: {
    async addItem(context, payload) {
      let { quantity = 1 } = payload;
      const { articleNumber = null } = payload;
      const existingItem = getItemByArticleNumber(context, articleNumber);
      let response;
      try {
        if (existingItem) {
          quantity += existingItem.quantity;
          if (quantity <= 0) {
            response = await ShopApi.removeItemFromCart({ id: existingItem.id });
          } else {
            response = await ShopApi.updateItemInCart({
              id: existingItem.id, quantity,
            });
          }
        } else {
          response = await ShopApi.addItemToCart({
            articleNumber, quantity,
          });
        }
        await context.dispatch('fetch');
        return response;
      } catch (error) {
        const errorStatus = await handleAddToCartError(error);
        context.commit('SET_CART_ERRORSTATUS', errorStatus);
        return errorStatus;
      }
    },

    async fetch(context) {
      const { data } = await ShopApi.getCurrentCart();
      const cart = {
        items: data.order.items.map(mapGoliathItemToCartSpecs),
        total: data.order.amount,
        cartCode: data.order.cartCode,
      };
      context.commit('SET_CART', cart);

      const allBundleSlugs = data.order.items
        .filter((i) => i.bundleType === 'brodos')
        .map((i) => i.slug);
      await context.dispatch('queryBrodosBundleSlugs', allBundleSlugs);

      return cart;
    },

    async reset(context) {
      await ShopApi.deleteCart();
      context.commit('SET_CART', EMPTY_CART);
    },

    async transfer(context) {
      const { data = {} } = await ShopApi.transferCart();
      if (data.status === 'ok') {
        context.commit('SET_TRANSFERED');
      }
      return data;
    },

    async updateItem(context, { id, quantity }) {
      const { data } = await ShopApi.updateItemInCart({ id, quantity });
      await context.dispatch('fetchCart');
      return data;
    },

    async queryBrodosBundleSlugs(context, bundleSlugs) {
      const bundleUpdatesList = await Promise.all(
        bundleSlugs.map(async (bundleSlug) => {
          if (context.state.bundlesByArticleNumber[bundleSlug]) return [];

          const productBundle = await fetchOfferBySlugs(
            { productSlug: bundleSlug },
          );
          if (!productBundle.product) return [];

          const { articleNumber, bundledProducts } = productBundle.product;
          if (!bundledProducts) return [];

          const itemsGroupedByAN = groupBy(bundledProducts, 'articleNumber');
          const countedItems = Object.values(itemsGroupedByAN)
            .map((group) => ({ ...group[0], count: group.length }));

          return [articleNumber, countedItems];
        }),
      );

      // [[], ['some', 'entry'] => {'some': 'entry'}
      const bundleUpdates = fromPairs(
        filter(bundleUpdatesList, (l) => !isEmpty(l)),
      );

      if (isEmpty(bundleUpdates)) return;
      context.commit('ADD_PRODUCT_BUNDLES', bundleUpdates);
    },
  },
  mutations: {
    SET_CART(state, payload) {
      state.cart = payload;
    },
    SET_TRANSFERED(state) {
      state.cart.isTransfered = true;
    },
    SET_CART_ERRORSTATUS(state, payload) {
      state.errorStatus = payload;
    },
    ADD_PRODUCT_BUNDLES(state, productBundles) {
      Object.keys(productBundles).forEach((articleNumber) => {
        state.bundlesByArticleNumber[articleNumber] = productBundles[articleNumber];
      });
    },
  },
};
