import { attachmentUrl } from '@/lib/attachment-route';
import { hasProductWithEnergyEfficiency } from '@i22/rocket';

export const parseDateString = (date) => {
  const [day, month, year] = date.match(/(\d+)/g);
  return new Date(year, month - 1, day);
};

export const getFormattedPrice = (priceData) => {
  if (!priceData) return null;
  const { amount, currency, type } = priceData;
  return {
    amount,
    currency,
    isSlashed: type === 'WAS',
  };
};

export const filterPromotionByDate = (promotion) => {
  const startDate = parseDateString(promotion.startDate);
  const endDate = parseDateString(promotion.endDate);
  const dateToday = Date.now();
  endDate.setDate(endDate.getDate() + 1);
  return startDate < dateToday && endDate > dateToday;
};

export const getFormattedOfferData = (contentData, goliathData) => {
  const formattedOfferData = [];
  // offers should be sorted by given order in contentData
  contentData.forEach((contentEntry) => {
    const matchingGoliathEntry = goliathData
      .find((goliathEntry) => goliathEntry.product.articleNumber === contentEntry.articleNumber);
    if (!matchingGoliathEntry) return;
    const imageSrc = attachmentUrl(matchingGoliathEntry.teaserImage);
    const path = `/offer/${matchingGoliathEntry.product.slug}`;
    const defaultPrice = getFormattedPrice(matchingGoliathEntry.prices.find((p) => p.type === 'DEFAULT'
      && p.paymentFrequency === 'ONETIME'));
    const wasPrice = getFormattedPrice(matchingGoliathEntry.prices.find((p) => p.type === 'WAS'
      && p.paymentFrequency === 'ONETIME'));
    const highlight = !!contentEntry.highlight;

    formattedOfferData.push({
      articleNumber: contentEntry.articleNumber,
      productName: contentEntry.label,
      imageSrc,
      path,
      defaultPrice,
      wasPrice,
      highlight,
      hasEnergyLabel: hasProductWithEnergyEfficiency(matchingGoliathEntry),
    });
  });
  return formattedOfferData;
};
