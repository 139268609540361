<template>
  <div
    ref="selectableItemGroup"
    class="selectable-item-group"
  >
    <SelectableItem
      v-for="(entry, index) in list"
      :key="`selectable-item-${index}`"
      ref="selectableItem"
      :value="isSelected(entry.value)"
      class="selectable-item-group__item"
      @input="onInput($event, entry.value)"
    >
      <slot :entry="entry">
        {{ entry.label }}
      </slot>
    </SelectableItem>
  </div>
</template>

<script>
import DraggableMixin from '@/mixins/draggable-mixin';
import SelectableItem from '@/components/selectable-item';

export default {
  name: 'SelectableItemGroup',
  components: {
    SelectableItem,
  },
  mixins: [
    DraggableMixin('selectableItemGroup'),
  ],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    active: {
      handler(becomesActive) {
        if (!becomesActive || this.initialAnimationDone) return;
        this.initIntroSlide();
        this.initialAnimationDone = true;
      },
    },
    list: {
      handler(oldData, newData) {
        if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
          this.updateDraggableContent();
        }
      },
    },
  },
  methods: {
    isSelected(selectedValue) {
      return this.value.includes(selectedValue);
    },
    onInput(event, selectedValue) {
      let newValues = this.value;
      if (this.isSelected(selectedValue)) {
        newValues.splice(newValues.indexOf(selectedValue), 1);
      } else {
        if (!this.multiselect) {
          newValues = [];
        }
        newValues.push(selectedValue);
      }
      this.$emit('input', newValues);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.selectable-item-group {
  display: flex;
  padding: 0 30px;
  margin-bottom: 42px; // indicator + margin =  12px + 30px

  &__item {
    text-transform: uppercase;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
