import customVideos from '@/constants/custom-videos.json';
import dayjs from 'dayjs';
import { captureException } from '@/lib/sentry';

const isBetween = require('dayjs/plugin/isBetween');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

export const getActivationScreenByVp = (vp, customVideoList = customVideos.entries) => {
  const isMatchingVp = (video) => video.vps.includes(vp);
  const isCurrentlyActive = (video) => {
    if (video.validFrom && video.validUntil) {
      return dayjs().isBetween(
        dayjs(video.validFrom, 'YYYY-MM-DD'),
        dayjs(video.validUntil, 'YYYY-MM-DD'),
        'day',
        '[]',
      );
    }

    if (video.validFrom) {
      return dayjs().isSameOrAfter(
        dayjs(video.validFrom, 'YYYY-MM-DD'),
        'day',
      );
    }

    captureException(new Error('Error in \'src/lib/get-activation-screen-by-vp.js\'. video.validFrom is not defined'), { video });
    return false;
  };

  const videosWithDates = customVideoList
    .filter(isMatchingVp)
    .filter(isCurrentlyActive);

  return videosWithDates[0]?.videoSrc;
};
