import _ from 'lodash';
import config from './config';

export default function Serializer(vars) {
  const helpers = {
    rightTrimArray(items) {
      const lastPresentIndex = _.findLastIndex(items, (v) => !_.isUndefined(v));
      if (lastPresentIndex < 0) return [];
      return items.slice(0, lastPresentIndex + 1);
    },
    wrapArray(value) {
      if (_.isUndefined(value) || _.isNull(value)) return [];
      return _.castArray(value);
    },
  };

  this.vars = vars || {};
  this.filter = this.vars.filter || {};
  this.pagination = this.vars.pagination || {};

  this.getRoute = function getRoute() {
    return {
      path: this.getPath(),
      query: this.getQueryParams(),
    };
  };

  // Path Params

  this.getPath = function getPath() {
    const pathSegments = `/${this.getPathSegments()}`;
    if (this.vars.pathPrefix) {
      return `${this.vars.pathPrefix}${pathSegments}`;
    }
    return pathSegments;
  };

  this.getPathParams = function getPathParams() {
    return { config: this.getPathSegments() };
  };

  this.getPathSegments = function getPathSegments() {
    const segments = [
      ...this.getCategoryPathSegments(),
      this.getFilterPathSegment(),
      this.getPagePathSegment(),
    ];

    return _(segments)
      .compact()
      .value()
      .join(config.separators.path);
  };

  this.getCategoryPathSegments = function getCategoryPathSegments() {
    const paths = _.compact(this.filter.productCategoryPaths || []);
    if (!paths.length) return [];

    const pathSegments = paths.map((path) => path.split(config.separators.path));
    const segLength = pathSegments[0].length;

    if (!pathSegments.every((s) => s.length === segLength)) {
      throw new Error('Multiple categories must have a common parent in the product '
        + `category filter (got: ${paths})`);
    }

    const parentCategories = _(pathSegments)
      .map((s) => s.slice(0, segLength - 1))
      .uniqWith(_.isEqual)
      .value();

    if (parentCategories.length > 1) {
      throw new Error('Multiple categories must have a common parent in the product '
        + `category filter (got: ${paths})`);
    }

    const childCategorySlugs = _(pathSegments).map((s) => _.last(s)).sort().value();

    return [
      ...parentCategories[0],
      childCategorySlugs.join(config.separators.item),
    ];
  };

  this.getFilterPathSegment = function getFilterPathSegment() {
    let segments = helpers.rightTrimArray(config.filter.positions.map((name) => this.getFilterValue(name)));

    if (!segments.length) return undefined;

    segments = segments.map((value, index) => {
      const filterName = config.filter.positions[index];
      return value || config.filter.placeholders[filterName];
    });

    const str = segments.join(config.separators.filter);
    return `${config.prefixes.filter}${str}`;
  };

  this.getFilterValue = function getFilterValue(name) {
    const values = helpers.wrapArray(this.filter[name]);
    if (!values.length) return undefined;
    const prefix = config.filter.prefixes[name];
    const valueStr = values.sort().join(config.separators.item);
    if (prefix) return `${prefix}${valueStr}`;
    return valueStr;
  };

  this.getPagePathSegment = function getPagePathSegment() {
    if (!this.pagination || _.isEmpty(this.pagination)) return undefined;
    const { page } = this.pagination;
    if (!page || page === 1) return undefined;
    return `${config.prefixes.page}${page}`;
  };

  // Query Params

  this.getQueryParams = function getQueryParams() {
    return {
      discounted: this.getDiscountedFilter(),
      minPrice: this.getMinPrice(),
      maxPrice: this.getMaxPrice(),
      pageSize: this.getPageSize(),
      search: this.getSearchTerm(),
      sort: this.getSort(),
    };
  };

  this.getDiscountedFilter = function getDiscountedFilter() {
    switch (this.filter.discounted) {
      case true:
        return 'true';
      case false:
        return 'false';
      default:
        return undefined;
    }
  };

  this.getMinPrice = function getMinPriceFilter() {
    const price = this.filter.onetimePrice;
    if (!price || !price.min) return undefined;
    return price.min.toString();
  };

  this.getMaxPrice = function getMaxPriceFilter() {
    const price = this.filter.onetimePrice;
    if (!price || !price.max) return undefined;
    return price.max.toString();
  };

  this.getPageSize = function getPageSize() {
    const pageSize = this.pagination.size;
    if (!pageSize || pageSize === config.defaultPageSize) return undefined;
    return pageSize.toString();
  };

  this.getSearchTerm = function getSearchTerm() {
    return this.vars.search || undefined;
  };

  this.getSort = function getSort() {
    const defaultSort = config.sortMap[config.defaultSort];
    if (_.isEqual(this.vars.sort, defaultSort)) return undefined;

    return Object
      .keys(config.sortMap)
      .find((name) => _.isEqual(this.vars.sort, config.sortMap[name]));
  };
}
