<template>
  <div
    class="shelf-group"
  >
    <ul
      v-if="isListVisible"
      class="shelf-group__list"
    >
      <li
        v-for="(item, index) in items"
        :key="`shelf-group-list-item-${index}`"
        class="shelf-group__list-item"
      >
        <button
          :class="getActiveClass(index)"
          class="shelf-group__item-link"
          @click="slideTo(index)"
        >
          {{ item.title }}
        </button>
      </li>
    </ul>

    <div
      ref="shelfEntries"
      class="shelf-group__entries"
    >
      <ShelfEntry
        v-for="(item, index) in items"
        :key="`shelf-entry-${index}`"
        ref="shelfEntry"
        :title="item.title"
      >
        <slot :name="item.key" />
      </ShelfEntry>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { Draggable } from 'gsap/all';
import { lastIndexOf } from 'lodash';
import ShelfEntry from '@/components/shelf-entry';

gsap.registerPlugin(Draggable);

const MINIMUM_DRAG_MOVEMENT = 10;

export default {
  name: 'ShelfGroup',
  components: {
    ShelfEntry,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      timeLine: gsap.timeline(),
      draggable: null,
      currentXCoordinate: 0,
      activeClasses: [],
      shelfMarginLeft: 40,
      windowInnerWidth: 1080,
      minimumDragMovement: MINIMUM_DRAG_MOVEMENT,
    };
  },
  computed: {
    isListVisible() {
      return this.items.length > 1;
    },
    lastIndex() {
      return lastIndexOf(this.$refs.shelfEntry) - 1;
    },
  },
  mounted() {
    this.createDraggable();
    this.isInViewPort();
    this.timeLine.from('.shelf-group__entries', {
      x: '100%',
      opacity: 0,
      duration: 1,
    });
  },
  methods: {
    getActiveClass(index) {
      return this.activeClasses[index] ? 'shelf-group__item-link--active' : '';
    },
    isInViewPort() {
      if (!this.$refs.shelfEntry) return;
      // returns an array of boolean values for each element within the defined calculation
      this.activeClasses = this.$refs.shelfEntry.map((entry) => {
        const entryHtmlElement = entry.$el;
        if (!entryHtmlElement) return false; // entryHtmlElement should be set but there seem to be some cases where its undefined
        const { clientWidth } = entryHtmlElement;
        const boundingClientRectLeftWidth = entryHtmlElement.getBoundingClientRect().left;
        /*
        Each shelf entry could have one of these visual states:
          - fully in view port #fullyInViewPort
          - element is visible when certain element width is within the defined calculation (boundingClientRectLeftWidth < 900) #elementVisible
          - or it's left part is outside of view port, but the rest is partially visible #elementPartiallyVisible
        */
        const fullyInViewPort = clientWidth + boundingClientRectLeftWidth > window.innerWidth - this.shelfMarginLeft;

        // element is within the defined view port or at least 40% of it's
        // content is in view port
        const elementPartiallyVisible = (boundingClientRectLeftWidth > 0 && boundingClientRectLeftWidth < 900)
          || (clientWidth + boundingClientRectLeftWidth) > clientWidth * 0.4;

        const leftPartIsOutsideOfViewPort = boundingClientRectLeftWidth < 0;
        if (leftPartIsOutsideOfViewPort) {
          if (fullyInViewPort) return true;
          return elementPartiallyVisible;
        }

        return boundingClientRectLeftWidth < 900;
      });
    },
    slideTo(index) {
      const slideToElement = this.$refs.shelfEntry[index].$el;
      if (!slideToElement) return;
      // animating dynamic ShelfEntries have several edge cases
      const boundingClientRect = slideToElement.getBoundingClientRect();
      const widthLeftOffset = boundingClientRect.left;
      const shelfGroupWidth = this.windowInnerWidth - this.shelfMarginLeft;
      const shelfEntriesScrollWidth = this.$refs.shelfEntries.scrollWidth;

      // only animate if the entire shelfEntries width is > 1040
      if (shelfEntriesScrollWidth > shelfGroupWidth) {
        // if the entry is not the last entry
        if (index !== this.lastIndex) {
          /*
            FIXME: At the moment one edge case is still missing
            when the width of the current element plus the next elements width in total
            are smaller than the shelfGroupWidth, this case needs to behave differently aswell
            because of our bouding #setBounds()
          */
          this.currentXCoordinate = this.currentXCoordinate + widthLeftOffset - this.shelfMarginLeft;
        } else {
          // this is the last shelfEntry
          const lastEntryWidth = boundingClientRect.width;
          /*
           if the last entry width is greater than 1040
           scroll animation should behave the same as the default animation
           else stop sliding when it's whole width is in view port
         */
          if (lastEntryWidth > shelfGroupWidth) {
            this.currentXCoordinate = this.currentXCoordinate + widthLeftOffset - this.shelfMarginLeft;
          } else {
            const widthRightOffset = boundingClientRect.right;
            this.currentXCoordinate = this.currentXCoordinate + widthRightOffset - shelfGroupWidth;
          }
        }
      }

      // slide Animation of the shelfEntires
      const { shelfEntries } = this.$refs;
      this.timeLine.to(shelfEntries, {
        x: -this.currentXCoordinate,
        duration: 0.4,
        onComplete: this.isInViewPort,
      });
    },
    setBounds() {
      this.draggable[0].applyBounds({
        minX: 1080 - this.$refs.shelfEntries.scrollWidth - this.shelfMarginLeft,
        maxX: 0,
      });
    },
    createDraggable() {
      this.draggable = Draggable.create('.shelf-group__entries', {
        type: 'x',
        onPress: this.setBounds,
        onThrowUpdate: () => {
          if (!this.$refs.shelfEntries) return;
          this.currentXCoordinate = -this.$refs.shelfEntries.getBoundingClientRect().left;
          this.isInViewPort();
        },
        edgeResistance: 0.7,
        inertia: true,
        zIndexBoost: false,
        minimumMovement: this.minimumDragMovement,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.shelf-group {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__list {
    margin-left: 25px;
    letter-spacing: 0.2px;
    font-size: 20px;
    line-height: 23px;
    list-style-type: none;
    display: flex;
  }

  &__list-item {
    position: relative;
    margin-left: 65px;
  }

  &__item-link {
    font-weight: 400;
    border: none;
    background: none;
    font-size: inherit;
    color: #4B4B4B;
    letter-spacing: .44px;

    &:focus {
      outline: 0;
    }

    &::after {
      position: absolute;
      left: -50px;
      top: 12px;
      content: "";
      display: block;
      width: 40px;
      height: 1px;
      background-color: #4B4B4B;
    }

    &--active {
      color: #000000;
      font-weight: 700;
      letter-spacing: .05px;

      &::after {
        height: 3px;
        background-color: #000;
      }
    }
  }

  &__entries {
    display: flex;
    margin-top: 55px;
    width: 1080px;
  }
}
</style>
