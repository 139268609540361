<template>
  <div class="footer">
    <div class="footer__back">
      <slot name="back">
        <BackButton class="footer__back-button" />
      </slot>
    </div>
    <div
      v-if="hasMetaLinks"
      class="footer__meta"
    >
      <RouterLink
        class="footer__item"
        to="/help"
        :class="{'footer__item--active': isRouteActive('/help')}"
      >
        <span
          class="footer__item-wrapper"
          :class="{'footer__item-wrapper--active': isRouteActive('/help')}"
        >
          <HelpIcon class="footer__icon" />
          Hilfebereich
        </span>
      </RouterLink>
      <RouterLink
        class="footer__item"
        to="/search"
        :class="{'footer__item--active': isRouteActive('/search')}"
      >
        <span
          class="footer__item-wrapper"
          :class="{'footer__item-wrapper--active': isRouteActive('/search')}"
        >
          <SearchIcon class="footer__icon footer__icon--search" />
          Suche
        </span>
      </RouterLink>
      <div
        class="footer__item"
        @click.stop="setCancelOverlayAndPath"
      >
        <span class="footer__item-wrapper">
          <CancelIcon class="footer__icon footer__icon--cancel" />
          Abbrechen
        </span>
      </div>
      <NavigationCartButton
        variant="footer"
        :is-active="isRouteActive('/cart')"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BackButton from '@/components/back-button';
import NavigationCartButton from '@/components/navigation-cart-button';
import HelpIcon from '@/assets/images/help-icon.svg';
import SearchIcon from '@/assets/images/search-icon.svg';
import CancelIcon from '@/assets/images/cancel-icon.svg';

export default {
  components: {
    NavigationCartButton,
    BackButton,
    HelpIcon,
    SearchIcon,
    CancelIcon,
  },
  props: {
    hasMetaLinks: {
      type: Boolean,
      default: true,
    },
    cancelPath: {
      type: String,
      default: '/start',
    },
  },
  methods: {
    ...mapMutations({
      setSwitchToOverlayActiveTrue: 'cancelOverlaySwitch/SET_SWITCH_ACTIVE',
    }),
    setCancelOverlayAndPath() {
      this.setSwitchToOverlayActiveTrue();
      if (this.$route.path !== '/start') {
        this.$router.push({ path: this.cancelPath });
      }
    },
    isRouteActive(path) {
      return this.$route.path.startsWith(path);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.footer {
  background-color: #fff;
  bottom: 0px;
  display: flex;
  height: 220px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;

  &__back {
    width: 230px;
  }

  &__back-button {
    height: 120px;
    padding-bottom: 30px;
  }

  &__meta {
    align-items: flex-start;
    display: flex;
    padding: 0 50px;
  }

  &__item {
    @include font-size(24px, 27px);
    color: #000;
    font-weight: 400;
    margin: 0 20px;
    padding: 37px 0;
    width: 220px;

    &:focus,
    &:hover,
    &:visited {
      color: #000;
    }

    &:active,
    &--active,
    &--active:focus,
    &--active:hover,
    &--active:visited {
      color: #e20074;
      fill: currentColor;
      font-weight: 700;
      padding-top: 36px;
    }
  }

  &__item-wrapper {
    align-items: center;
    border-top: 2px solid currentColor;
    display: flex;
    padding-top: 10px;

    &--active {
      border-width: 4px;
      padding-top: 9px;
    }
  }

  &__icon {
    height: 29px;
    margin: 0 12px 0 0;
    width: 29px;

    &--search {
      left: 3px;
      position: relative;
      top: 3px;
    }

    &--cancel {
      left: 3px;
      position: relative;
      top: 5px;
    }
  }
}
</style>
