<template>
  <div class="router-tab">
    <router-link
      v-for="(tab, key) in tabs"
      :key="key"
      :ref="tab.path.name"
      :to="tab.path"
      class="router-tab__link"
    >
      <component
        :is="tab.icon"
        v-if="tab.icon"
        class="router-tab__icon"
      />
      {{ tab.title }}

      <div class="router-tab__bar" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'RouterTab',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

$transition: background-color .250s cubic-bezier(0.4, 0, 0.2, 1);

.router-tab {
  display: flex;
  position: relative;
  background-color: #f6f6f6;
  margin-bottom: 84px;

  &__link {
    position: relative;
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
    padding-top: 71px;
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    text-transform: uppercase;
    color: color(grey, 10000);
    transition: $transition;

    &:visited,
    &:hover,
    &:focus {
      color: color(grey, 10000);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 20px;
      background: linear-gradient(to bottom, #E6E6E6, rgba(255,255,255,0));
    }

    &:not(.router-link-exact-active) {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20px;
        background: linear-gradient(to top, #E6E6E6, rgba(255,255,255,0) 100%);
      }
    }

    &.router-link-exact-active {
      background-color: #FFF;

      .router-tab__bar {
        background-color: $color-primary;
      }
    }
  }

  &__icon {
    position: relative;
    width: 35px;
    top: 3px;
    right: 5px;
  }

  &__bar {
    position: absolute;
    top: 50px;
    pointer-events: none;
    background-color: transparent;
    height: 5px;
    left: 29px;
    right: 29px;
    transition: $transition;
  }
}
</style>
