export default {
  namespaced: true,
  state() {
    return {
      vp: null,
    };
  },
  actions: {
    setVP(context, vp) {
      context.commit('SET_VP', vp);
    },
  },
  mutations: {
    SET_VP(state, vp) {
      state.vp = vp;
    },
  },
};
