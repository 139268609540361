<template>
  <div
    class="checkbox-with-label"
    @click="toggleCheckbox"
  >
    <Checkbox
      v-model="internalValue"
      class="checkbox-with-label__checkbox"
    />
    <Typography
      class="checkbox-with-label__label"
      variant="title-bold"
    >
      {{ label }}
    </Typography>
  </div>
</template>

<script>
import Checkbox from '@/components/checkbox';
import Typography from '@/components/typography';

export default {
  name: 'CheckboxWithLabel',
  components: {
    Checkbox,
    Typography,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.internalValue = value;
      },
    },
  },
  methods: {
    toggleCheckbox() {
      this.internalValue = !this.internalValue;
      this.$emit('input', this.internalValue);
    },
  },
};
</script>

<style lang="scss">
  .checkbox-with-label {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 48px;

    &__label {
      text-transform: uppercase;
      display: inline-block;
      text-align: left;
    }
  }
</style>
