<template>
  <div class="select-page">
    <Header />
    <div
      class="select-page__logo"
    >
      <DeinDesignLogo
        width="451"
        height="75"
      />
    </div>
    <Typography
      variant="title"
      class="select-page__subheading"
    >
      In nur 3 Schritten zur selbstgestalteten Handyhülle
    </Typography>
    <ProgressBar
      :current-step="currentStep"
    />
    <router-view
      @selected-device="setSelectedDevice"
      @selected-case="setSelectedCase"
    />
    <Footer />
  </div>
</template>

<script>
import ProgressBar from '@/components/progress-bar';
import Header from '@/components/header';
import Typography from '@/components/typography';
import DeinDesignLogo from '@/assets/images/deindesign-logo.svg';
import Footer from '@/components/footer';
import DeinDesignBreadcrumb from '@/mixins/dein-design-breadcrumb';
import { mapMutations } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'SelectDefaultPage',
  components: {
    Footer,
    Typography,
    Header,
    ProgressBar,
    DeinDesignLogo,
  },
  mixins: [DeinDesignBreadcrumb],
  data() {
    return {
      currentStep: 0,
    };
  },
  computed: {
    selectedDeviceSlug() {
      return get(this.$route, 'query.productFamilySlug');
    },
    selectedCaseSlug() {
      return get(this.$route, 'query.productStyleSlug');
    },
  },
  watch: {
    $route() {
      this.detectCurrentRoute();
    },
  },
  mounted() {
    this.detectCurrentRoute();
  },
  methods: {
    ...mapMutations({
      addSlug: 'progressBar/ADD_SLUG',
    }),
    detectCurrentRoute() {
      const routingNames = this.$route.matched.map((r) => r.name);
      if (routingNames.includes('dein-design-select-device-page')) {
        this.currentStep = 0;
        return;
      }
      if (routingNames.includes('dein-design-select-case-page')) {
        this.currentStep = 1;
        return;
      }
      if (
        routingNames.includes('dein-design-select-motif-type-page')
        || routingNames.includes('dein-design-select-motif-default-page')
      ) {
        this.currentStep = 2;
      }
    },
    setSelectedDevice(data) {
      if (data) {
        this.addSlug({ slug: data.slug, name: data.name });
      }
    },
    setSelectedCase(data) {
      if (data) {
        this.addSlug({ slug: data.slug, name: data.name });
      }
    },
  },
};
</script>

<style lang="scss">
.select-page {
  &__logo {
    margin: 60px 30px 0;
  }
  &__subheading {
    display: block;
    padding: 0 30px 30px;
  }
}
</style>
