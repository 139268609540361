const ACTIONS = {
  INITIALIZE_PROMOTIONS: 'promotions/loadFromBackend',
  INITIALIZE_CATEGORIES: 'categories/loadFromBackend',
  ADD_TO_CART: 'cart/addItem',
  FETCH_CART: 'cart/fetch',
  TRANSFER_CART: 'cart/transfer',
  RESET_CART: 'cart/reset',
  REMOVE_FROM_CART: 'cart/removeItem',
  INITIALIZE_SHELVES: 'shelves/loadFromBackend',
  UPDATE_BREADCRUMB: 'breadcrumb/updateEntries',
  SET_VP: 'session/setVP',
};

const missingAttributeHandler = {
  get: (target, name) => {
    if (name in target) return target[name];

    throw new Error(`Missing store action ${name}`);
  },
};

const actionsProxy = new Proxy(ACTIONS, missingAttributeHandler);

export default actionsProxy;
