<template>
  <button
    class="back-button"
    type="button"
    @click="goBack"
  >
    <span :class="iconClass" />
  </button>
</template>

<script>
const VALID_VARIANTS = [
  'arrow',
  'cancel',
];

export default {
  name: 'BackButton',
  props: {
    variant: {
      type: String,
      default: 'arrow',
      validator: (value) => VALID_VARIANTS.includes(value),
    },
    path: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconClass() {
      return `back-button__${this.variant}`;
    },
  },
  methods: {
    goBack() {
      if (this.path !== '') {
        this.$router.push(this.path);
        return;
      }
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.back-button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: #262626;
  cursor: pointer;
  display: flex;
  font: inherit;
  height: 90px;
  justify-content: center;
  outline: inherit;
  padding: 0 30px 0;
  position: relative;

  &__arrow {
    position: relative;
    transition: all 0.2s ease-in-out;
    width: 50px;
    height: 2px;
    background-color: currentColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &::before {
      position: absolute;
      left: -0px;
      content: "";
      display: block;
      transition: all 0.2s ease-in-out;
      transform-origin: center left;
      transform: rotate(-45deg);
      width: 20px;
      height: 2px;
      background-color: currentColor;
    }

    &::after {
      position: absolute;
      left: -0px;
      content: "";
      display: block;
      transition: all 0.2s ease-in-out;
      transform-origin: center left;
      transform: rotate(45deg);
      width: 20px;
      height: 2px;
      background-color: currentColor;
    }
  }

  &__cancel {
    position: relative;
    display: block;
    width: 50px;
    height: 43px;

    &::before {
      content: " ";
      display: block;
      width: 56px;
      height: 2px;
      background-color: currentColor;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      left: 5px;
      transform-origin: top left;
    }

    &::after {
      content: " ";
      display: block;
      width: 56px;
      height: 2px;
      background-color: currentColor;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      right: 5px;
      transform-origin: top right;
    }
  }
}
</style>
