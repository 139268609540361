<template>
  <ProductContentDetails
    :product="product"
    :render-item-if="hasFeatures"
    class="offer-technical-details"
  >
    <template
      slot-scope="props"
    >
      <BulletPointList
        :is-bundle="product.bundle"
        :list="filterFeatureItems(props.product)"
        class="offer-technical-details__list"
        itemprop="description"
      >
        <template slot-scope="entry">
          {{ entry.item.name }}<br>
          {{ entry.item.value }}
        </template>
      </BulletPointList>
    </template>
  </ProductContentDetails>
</template>

<script>
import ProductContentDetails from '@/components/product-detail/product-content-details';
import BulletPointList from '@/components/bullet-point-list';

export default {
  name: 'OfferTechnicalDetails',
  components: {
    BulletPointList,
    ProductContentDetails,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hasFeatures(product) {
      return product.features.length > 0;
    },
    filterFeatureItems(product) {
      return product.features.map((f) => ({
        text: `${f.name} ${f.value}`,
        name: f.name,
        value: f.value,
      }));
    },
  },
};
</script>

<style lang="scss">

</style>
