import { GoliathClient } from './goliath-client';
import list from './queries/list-heatable-jacket.graphql';

export default async function fetchOffers(params = {}) {
  const response = await GoliathClient.query({
    query: list,
    variables: { ...params },
  });

  return response.data.offers;
}
