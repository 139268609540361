/* takes a search term es given by the user as query parameter
 * and sanitizes it:
 * - removes unwanted characters
 * - removes multiple consecutive spaces
 * - removes leading and trailing spaces
 */
function sanitizeTerm(rawTerm) {
  return rawTerm
    .toLocaleUpperCase('de')
    .replace(/[^-.,/A-Z0-9ÄÖÜ ]/g, '')
    .replace(/  */, ' ')
    .trim();
}

/* takes a term, sanitizes it and
 * converts it to a goliath prefix search by
 * adding asterisks to each word
 */
function goliathifySearchTerm(term) {
  return sanitizeTerm(term)
    .split(/  */)
    .map((t) => `${t}*`)
    .join(' ')
    .trim();
}

export default {
  sanitizeTerm,
  goliathifySearchTerm,
};
