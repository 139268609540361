<template>
  <div class="select-motif-category-page">
    <SelectableItemGroup
      v-model="selectedCategorySlugs"
      class="select-motif-category-page__selectable-item-group"
      :list="formattedSelectableCategories"
      :active="formattedSelectableCategories.length > 0"
      @input="selectCategory"
    />
    <SelectableItemGroup
      v-model="selectedArtistSlugs"
      class="select-motif-category-page__selectable-item-group"
      :list="formattedSelectableArtists"
      :active="formattedSelectableArtists.length > 0"
      @input="selectArtist"
    />
    <InfiniteShelf
      class="select-motif-category-page__shelf"
      :offer-query="fetchOffersForCategory"
      :offer-query-params="offerQueryParams"
      :number-of-boards="1"
      tile-size="l"
      @updated-page="updatePage"
      @fetched-offers="fetchedOffers"
    />
    <LoadingSpinnerInline
      :is-loading="isLoading"
      class="select-motif-category-page__loading_indicator"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import DeinDesignOffers from '@/mixins/dein-design-offers';
import fetchOffersForCategory from '@/lib/goliath/dein-design-offers-for-category';
import SelectableItemGroup from '@/components/selectable-item-group';
import InfiniteShelf from '@/components/infinite-shelf';
import LoadingSpinnerInline from '@/components/loading-spinner-inline';

export default {
  name: 'SelectMotifCategoryPage',
  components: {
    LoadingSpinnerInline,
    InfiniteShelf,
    SelectableItemGroup,
  },
  mixins: [
    DeinDesignOffers(fetchOffersForCategory),
  ],
  data() {
    return {
      selectedCategorySlugs: [],
      selectedArtistSlugs: [],
      selectableCategories: [],
      selectableArtists: [],
      initialPage: 1,
      isLoading: true,
    };
  },
  computed: {
    formattedSelectableCategories() {
      return this.selectableCategories.map((e) => ({
        label: e.category.name,
        value: e.category.slug,
      }));
    },
    formattedSelectableArtists() {
      if (this.selectedCategorySlugs.length === 0) {
        return [];
      }
      return this.selectableArtists.map((e) => ({
        label: e.artist.name,
        value: e.artist.slug,
      }));
    },
    offerQueryParams() {
      const filter = {};
      if (this.selectedCategorySlugs.length > 0) {
        filter.productDesignCategorySlugs = this.selectedCategorySlugs;
      }
      if (this.selectedArtistSlugs.length > 0) {
        filter.productDesignArtistSlugs = this.selectedArtistSlugs;
      }
      return this.getOfferQueryParams({
        filter,
        pagination: {
          page: this.initialPage,
          size: 30,
        },
        grouped: false,
      });
    },
  },
  mounted() {
    this.initialPage = this.$route.query.page || 1;
  },
  methods: {
    fetchOffersForCategory,
    selectCategory(slugs) {
      this.isLoading = true;
      this.selectedCategorySlugs = slugs;
      this.selectedArtistSlugs = [];
    },
    selectArtist(slugs) {
      this.isLoading = true;
      this.selectedArtistSlugs = slugs;
    },
    fetchedOffers(offers) {
      this.isLoading = false;
      if (this.selectableCategories.length === 0) {
        this.selectableCategories = get(offers, 'aggregations.productDesignCategories', []);
      }
      this.selectableArtists = get(offers, 'aggregations.productDesignArtists', []);
    },
    updatePage(page) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.select-motif-category-page {
  position: relative;
  &__selectable-item-group {
    height: 60px;
  }

  &__shelf {
    margin: 208px 30px 0;
  }

  &__loading_indicator {
    height: 965px;
    z-index: 2;
    background: rgba(255,255,255,0.5);
  }
}

</style>
