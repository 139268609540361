// common module for server & client to setup mode object

/**
 * Mode Object (returned by modeSetup)
 * @typedef {Object} ModeObject
 * @property {boolean} isStaging
 * @property {boolean} isTesting
 * @property {boolean} isPreview
 * @property {boolean} isPitchpreview
 * @property {boolean} isChristmasCalendar
 * @property {boolean} isModular
 * @property {boolean} isDevtest
 * @property {boolean} isProduction
 * @property {boolean} isTest
 * @property {boolean} isHosting
 * @property {boolean} isDevelopment
 * @property {string} name
 */

/**
 * @param {String} toModeName
 * @return {ModeObject}
 */

function modeSetup(toModeName, origMode = {}) {
  const mode = origMode;
  const modeName = toModeName;

  /**
   * @param {string} name
   */
  function modeIs(name) {
    return modeName === name;
  }

  mode.isStaging = modeIs('staging'); // Hosting
  mode.isTesting = /^testing/.test(modeName); // Hosting
  mode.isPreview = /^preview/.test(modeName); // Hosting
  mode.isPitchpreview = modeIs('pitchpreview'); // Hosting
  mode.isChristmasCalendar = modeIs('christmascalendar'); // Hosting
  mode.isModular = modeIs('modular'); // Hosting
  mode.isDevtest = modeIs('devtest'); // Hosting
  mode.isProduction = modeIs('production'); // Hosting
  mode.isTest = modeIs('test'); // Hosting

  mode.isHosting = mode.isStaging || mode.isTesting
    || mode.isPreview || mode.isPitchpreview
    || mode.isDevtest || mode.isProduction
    || mode.isModular || mode.isChristmasCalendar;

  mode.isDevelopment = !(mode.isTest || mode.isHosting);

  mode.name = mode.isDevelopment ? 'development' : modeName;

  // @ts-ignore
  return mode;
}

module.exports = modeSetup;
