import { GoliathClient } from './goliath-client';
import Suggestions from './queries/suggestions.graphql';

export default async function fetchSuggestions(params = {}) {
  const response = await GoliathClient.query({
    query: Suggestions,
    variables: {
      search: params.search,
    },
  });

  return response;
}
