export default {
  namespaced: true,
  state() {
    return {
      active: false,
    };
  },
  mutations: {
    SET_SWITCH_ACTIVE(state) {
      state.active = true;
    },
    SET_SWITCH_INACTIVE(state) {
      state.active = false;
    },
  },
};
