<template>
  <div class="select-motif-search-page">
    <MotifSearchInput
      class="select-motif-search-page__input"
      placeholder="Design suchen"
      :search-term="term"
      @change="setSearchTerm"
      @focus="openKeyboard"
    />

    <SelectableItemGroup
      v-if="isSuggestionVisible"
      class="select-motif-search-page__selectable-item-group"
      :list="searchSuggestions"
      :active="isSuggestionVisible"
      @input="selectSuggestions"
    />

    <VueTouchKeyboard
      v-if="keyboardIsVisible"
      class="select-motif-search-page__keyboard"
      :input="searchInput"
      :layout="simplifiedLayout"
      :accept="startSearch"
      :visible="searchElementsVisible"
    />

    <InfiniteShelf
      v-if="isSearchResultVisible"
      class="select-motif-search-page__shelf"
      :offer-query="fetchOffersForCategory"
      :offer-query-params="offerQueryParams"
      :number-of-boards="1"
      tile-size="l"
      @updatedPage="updatePage"
      @fetched-offers="fetchedOffers"
    />
    <LoadingSpinnerInline
      :is-loading="isLoading"
      class="select-motif-search-page__loading_indicator"
    />
  </div>
</template>

<script>
import fetchOffersForCategory from '@/lib/goliath/dein-design-offers-for-category';
import fetchMotifSuggestions from '@/lib/goliath/dein-design-motif-suggestions';
import VueTouchKeyboardBundle from 'vue-touch-keyboard';
import MotifSearchInput from '@/components/motif-search-input';
import SelectableItemGroup from '@/components/selectable-item-group';
import DeinDesignOffers from '@/mixins/dein-design-offers';
import simplifiedLayout from '@/assets/js/vue-touch-keyboard.simplified.json';
import InfiniteShelf from '@/components/infinite-shelf';
import LoadingSpinnerInline from '@/components/loading-spinner-inline';

export default {
  name: 'SelectMotifSearchPage',
  components: {
    LoadingSpinnerInline,
    InfiniteShelf,
    SelectableItemGroup,
    MotifSearchInput,
    VueTouchKeyboard: VueTouchKeyboardBundle.component,
  },
  mixins: [
    DeinDesignOffers(fetchOffersForCategory),
  ],
  data() {
    return {
      simplifiedLayout,
      searchSuggestions: [],
      offerQueryParams: null,
      term: '',
      searchElementsVisible: false,
      searchResultVisible: false,
      searchInput: undefined,
      initialPage: undefined,
      isLoading: false,
    };
  },
  computed: {
    keyboardIsVisible() {
      return this.searchElementsVisible && this.searchInput;
    },
    isSuggestionVisible() {
      return !this.searchResultVisible;
    },
    isSearchResultVisible() {
      return this.offerQueryParams && this.searchResultVisible;
    },
  },
  methods: {
    fetchOffersForCategory,
    async startSearch() {
      this.isLoading = true;
      this.closeKeyboard();
      this.requestOffer();
      this.searchResultVisible = true;
    },
    selectSuggestions(slugs) {
      if (slugs.length === 0) {
        return;
      }
      this.term = slugs[0].toString();
      this.startSearch();
    },
    setSearchTerm(term) {
      this.term = term;
      this.requestSuggestions();
    },
    closeKeyboard() {
      this.searchElementsVisible = false;
    },
    openKeyboard(event) {
      if (event) {
        this.searchInput = event.target;
      }
      this.searchElementsVisible = true;
      this.searchResultVisible = false;
    },
    async requestSuggestions() {
      if (this.term === '') {
        this.searchSuggestions = [];
        return;
      }
      const suggestions = await fetchMotifSuggestions({
        filter: {
          ...this.getFilterParams(),
          productCategoryPaths: ['deindesign'],
          listed: true,
          types: [
            'TANGIBLE',
          ],
        },
        search: this.term,
      });

      this.searchSuggestions = suggestions.completedSearchTerms.productDesignTags.map((suggestion) => ({
        label: suggestion,
        value: suggestion,
      }));
    },
    requestOffer() {
      if (this.term === '') {
        return;
      }

      this.offerQueryParams = this.getOfferQueryParams({
        search: this.term,
        pagination: { page: this.initialPage || 1, size: 30 },
      });
    },
    updatePage(page) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
    mounted() {
      this.initialPage = this.$route.query.page || 1;
    },
    fetchedOffers() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.select-motif-search-page {
  position: relative;
  &__input {
    width: 621px;
    margin: 0 30px;
  }

  &__selectable-item-group {
    height: 60px;
    margin-top: 55px;
  }

  &__keyboard {
    margin-top: 200px;
  }

  &__shelf {
    margin: 294px 30px 0;
  }

  &__loading_indicator {
    height: 965px;
    z-index: 2;
    background: rgba(255,255,255,0.5);
  }
}
</style>
