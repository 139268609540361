const GOLIATH_PATH_PREFIX_REGEXP = RegExp('^/public');
const CDN_DEIN_DESIGN = 'https://cdn.deindesign.com/';
const PROXY_DEIN_DESIGN = '/product-images/dein-design/';
const mode = process.env.NODE_ENV;

function replacePath(path, remote = false) {
  if (!path) return '';
  if (path.startsWith(CDN_DEIN_DESIGN) && mode !== 'development') {
    return path.replace(CDN_DEIN_DESIGN, PROXY_DEIN_DESIGN).replace(/\/\//, '/');
  }

  return remote ? path : path.replace(GOLIATH_PATH_PREFIX_REGEXP, '/product-images');
}

function url(attachment) {
  if (!attachment) return undefined;
  if (attachment.remote) return replacePath(attachment.url, true);
  return replacePath(attachment.path);
}

/**
 * @param {*} attachment LocalAttachment or RemoteAttachment from Goliath
 * @returns {string}
 */
function thumbImageUrl(attachment) {
  if (!attachment) return undefined;
  if (attachment.remote) {
    return replacePath(attachment.url, true);
  }
  return replacePath(attachment.thumbImagePath);
}

export { url as attachmentUrl, thumbImageUrl as attachmentThumbUrl, replacePath };

export default { url, thumbImageUrl, replacePath };
