<template>
  <img
    :src="url"
    :alt="alt"
  >
</template>

<script>
import { replacePath } from '@/lib/attachment-route';

export default {
  name: 'GoliathImage',
  props: {
    path: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  computed: {
    url() {
      return replacePath(this.path);
    },
  },
};
</script>
