<template>
  <div class="bundle-item">
    <div class="shelf-content">
      <img
        :alt="item.teaserImage.name"
        :src="item.teaserImage.thumbImageUrl"
        class="shelf-content__image"
      >
      <div class="shelf-content__details">
        <span class="shelf-content__details-title">{{ item.family.name }}</span>
        <span class="shelf-content__details-subtitle">
          {{ getSubtitle(item) }}
        </span>
        <div class="shelf-content__details-properties">
          <div
            v-for="property in item.properties"
            :key="`${property.type}_${Math.random()}`"
            class="shelf-content__details-property"
          >
            <span class="property-label">{{ property.displayName }}</span>
            <VariantSelector
              class="property-selectors"
              :type="property.type"
              :variants="property.variants"
              hide-title
              horizontal
              @selectVariant="selectVariant"
            />
          </div>
        </div>
      </div>
    </div>
    <ShelfBoard />
  </div>
</template>

<script>
import ShelfBoard from '@/components/shelf-board';
import VariantSelector from '@/components/product-detail/offer-configurator/variant-selector';

export default {
  name: 'BundleItem',
  components: {
    ShelfBoard,
    VariantSelector,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getSubtitle(item) {
      return item.activeVariants.filter((variant) => !!variant)[0] || '';
    },
    selectVariant({ variant }) {
      // catch to avoid NavigationDuplicated error
      this.$router.replace({ path: `${variant.path}/configure` }).catch(() => {
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_font-sizes.scss";

.shelf-content {
  display: flex;
  padding-left: 80px;
  padding-right: 230px; // last element should be still on shelf
  padding-bottom: 48px;
  height: 76%;
  //outline: 1px solid blue;
  &__image {
    width: 150px;
    height: 150px;
  }

  &__details {
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    margin-left: 48px;

    &-title {
      font-weight: bold;
      display: block;
    }

    &-subtitle {
      display: block;
    }

    &-property {
      display: flex;
      align-items: center;

      &:not(:first-of-type) {
        margin-left: 128px;
      }
    }

    &-properties {
      margin-top: 13px;
      display: flex;
      align-items: flex-start;
    }
  }

  &__button-wrapper {
    display: flex;
    align-items: flex-end;
  }

  .property-label {
    font-weight: 700;
    @include font-size(24px, 40px);
    text-align: justify;
    text-transform: uppercase;
    color: #000000;
    background: white;
    border: none;
    height: 46px;
    padding: 0;
    margin: 0;
  }

  .property-selectors {
    margin-left: 48px;
  }
}

.bundle-item {
  margin-top: 12px;
  min-height: 268px;
  min-width: 1000px;
}
</style>
