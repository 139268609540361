/* eslint-disable no-unused-vars */
const SavedFeatures = require('@i22/status-toolbar/dist/saved-features');
const currentMode = require('@/server/mode');

const onlyDevelopment = currentMode.isDevelopment;
const onlyDevelopmentAndPreview = !(
  currentMode.isProduction || currentMode.isStaging
);

const features = {
  vsPaypal: {
    enabled: true,
  },
  newBundles: { // https://jira.i22.de/browse/VR-294
    enabled: true,
  },
};

module.exports = SavedFeatures.getFeatures(features, currentMode.isProduction);
