import { GoliathClient } from './goliath-client';
import deinDesignMotifSuggestions from './queries/dein-design-motif-sugesstions.graphql';

export default async function fetchMotifSuggestions(params = {}) {
  const response = await GoliathClient.query({
    query: deinDesignMotifSuggestions,
    variables: { ...params },
  });

  return response.data.offers;
}
