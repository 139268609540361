<template>
  <div class="progress-bar">
    <div
      v-for="(step, key) in steps"
      :key="key"
      class="progress-bar__step-container"
    >
      <ProgressBarStep
        :ref="`step-${key}`"
        :name="step.name"
        :icon="step.icon"
        :current="currentStep"
        :step="key"
        @switch-step="switchStep(key, step.path)"
      />
    </div>
    <Modal
      ref="stepChangeModal"
      class="progress-bar__modal"
    >
      <p>Bitte beachten Sie, dass Ihre aktuelle Auswahl zurückgesetzt wird, wenn Sie die Konfiguration von vorne starten.</p>

      <div class="modal-controls progress-bar__controls">
        <div class="modal-controls__button-cancel">
          <CommonButton
            variant="secondary"
            @click="$refs.stepChangeModal.close()"
          >
            Hier bleiben
          </CommonButton>
        </div>
        <div class="modal-controls__button-continue">
          <router-link :to="modalStepPath">
            <CommonButton
              @click="$refs.stepChangeModal.close()"
            >
              Neu anfangen
            </CommonButton>
          </router-link>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import IconDevice from '@/assets/images/progress-step-device.svg';
import IconCase from '@/assets/images/progress-step-case.svg';
import IconMotif from '@/assets/images/progress-step-motif.svg';
import ProgressBarStep from '@/components/progress-bar-step';
import Modal from '@/components/modal';
import CommonButton from '@/components/common-button';
import { mapState } from 'vuex';
import DeinDesignQueryParams from '@/mixins/dein-design-query-params';

export default {
  name: 'ProgressBar',
  components: {
    CommonButton,
    Modal,
    ProgressBarStep,
  },
  mixins: [
    DeinDesignQueryParams,
  ],
  props: {
    selectedMotifSlug: {
      type: String,
      default: null,
    },
    currentStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      modalStepPath: null,
    };
  },
  computed: {
    ...mapState({
      savedSlugs: (state) => state.progressBar.slugs,
    }),
    deviceName() {
      const slug = this.savedSlugs.find((s) => s.slug === this.compatibleProductFamilySlug);
      return slug ? slug.name : null;
    },
    caseName() {
      const slug = this.savedSlugs.find((s) => s.slug === this.productStyleSlug);
      return slug ? slug.name : null;
    },
    motifName() {
      const slug = this.savedSlugs.find((s) => s.slug === this.selectedMotifSlug);
      return slug ? slug.name : null;
    },
    steps() {
      const queryParams = this.getQueryParams();
      return [
        {
          name: this.deviceName || 'Gerät auswählen',
          icon: IconDevice,
          path: {
            name: 'dein-design-select-device-page',
          },
        },
        {
          name: this.caseName || 'Hülle auswählen',
          icon: IconCase,
          path: {
            name: 'dein-design-select-case-page',
            query: {
              ...queryParams,
              productTagSlug: null,
              productStyleSlug: null,
            },
          },
        },
        {
          name: this.motifName || 'Motiv auswählen',
          icon: IconMotif,
          path: {
            name: 'dein-design-select-motif-type-page',
            query: {
              ...queryParams,
            },
          },
        },
      ];
    },
  },
  methods: {
    switchStep(stepIndex, stepPath) {
      if (!stepPath || stepIndex > this.currentStep) {
        return;
      }
      if (stepIndex === this.currentStep) {
        this.$router.push(stepPath);
        return;
      }
      this.modalStepPath = stepPath;
      this.$refs.stepChangeModal.open();
    },
  },
};
</script>

<style lang="scss">
.progress-bar {
  display: flex;
  width: 100%;
  margin: 19px 0;

  &__step-container {
    flex: 1 1 100%;
    position: relative;
  }

  &__controls {
    margin-top: 40px;
  }

  &__modal {
    z-index: 2;
  }
}
.modal-controls {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__button-cancel {
    grid-column: 1;
  }
  &__button-continue {
    grid-column: 2;
    text-align: right;
  }
}
</style>
